import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'calculateDurationDays',
    pure: true,
})
export class CalculateDurationDaysPipe implements PipeTransform {

    public transform(dateTimeFromStr: string, dateTimeToStr: string): number {
        const dateTimeFrom = new Date(dateTimeFromStr);
        const dateTimeTo = new Date(dateTimeToStr);

        return Math.ceil((dateTimeTo.getTime() - dateTimeFrom.getTime()) / (1000 * 60 * 60 * 24));
    }

}
