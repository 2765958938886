import { Component, EventEmitter, Input, NgZone, OnChanges, Output } from '@angular/core'

import { FilterOption, VehiclePackage, VehicleType } from '@app-graphql'
import { FilterService } from '@app-services'

@Component({
    selector: 'app-car-vehicle-package-selection',
    templateUrl: './car-vehicle-package-selection.component.html',
    styleUrls: ['./car-vehicle-package-selection.component.scss'],
})
export class CarVehiclePackageSelectionComponent implements OnChanges {

    @Input()
    public vehiclePackages: Partial<VehiclePackage & { checked?: boolean }>[]

    @Input()
    public vehicleTypeFilterOptions: Partial<FilterOption>[]

    @Output()
    public valueChanged = new EventEmitter<Partial<FilterOption>[]>()

    private selectedFilterOptions: Partial<FilterOption>[] = []
    private allButtonsEnabledState: Map<string, boolean> = new Map()
    private filterOptionMapping: { [key: string]: Partial<FilterOption> } = {}

    constructor(
        private readonly filterService: FilterService,
        private readonly zone: NgZone,
    ) {
    }

    public async ngOnChanges(): Promise<void> {
        if (! this.vehicleTypeFilterOptions) {
            return
        }

        if (! this.vehiclePackages?.length) {
            this.createDefaultVehiclePackages()
        } else {
            this.vehiclePackages = this.vehiclePackages.map((v) => ({ ...{}, ...v, checked: false }))

            // Get filter option mapping
            const uniqueVehicleTypes = this.vehiclePackages
                .reduce((acc, vehiclePackage) => [...acc, ...vehiclePackage.vehicleTypes], [])
                .filter((v, i, a) => a.findIndex((t) => (t.id === v.id)) === i)
            const filterOptions = await this.filterService.getFilterOptionsByValues(uniqueVehicleTypes.map((t) => t.id))

            this.filterOptionMapping = Object.fromEntries(
                filterOptions.map((option: Partial<FilterOption>) => [option.value, option]),
            )
        }

        // Only pre select a button if it's the only one, keep this code for in the future
        //if (this.vehiclePackages.length === 1) {
        //    this.vehiclePackages[0].checked = true;
        //    this.toggleVehiclePackage(this.vehiclePackages[0], true);
        //}

        // Set all vehicle packages to checked on page load
        this.vehiclePackages.forEach((vehiclePackage) => {
            vehiclePackage.checked = true
            this.toggleVehiclePackage(vehiclePackage, true)
        })

        // Get `vehiclePackage` parameter from query string
        const params = new URLSearchParams(window.location.search)
        const defaultVehiclePackage = params.get('vehiclePackage')
        if (defaultVehiclePackage) {
            const vehiclePackage = this.vehiclePackages.find((v) => v.name === defaultVehiclePackage)
            if (vehiclePackage) {
                vehiclePackage.checked = true
                this.toggleVehiclePackage(vehiclePackage, true)
            }
        }

    }

    public getVehicleTypeFilterOptionByVehicleType(vehicleType: VehicleType): Partial<FilterOption> {
        return this.filterOptionMapping[vehicleType.id] || null
    }

    private createDefaultVehiclePackages(): void {
        this.vehiclePackages = [
            {
                id: 'car',
                name: 'Auto',
                image: '/assets/icons/vehicle-packages/vehicle-package-car.svg',
                vehicleTypes: [],
                checked: false,
            },
            {
                id: 'bus',
                name: 'Bestelwagen',
                image: '/assets/icons/vehicle-packages/vehicle-package-bus.svg',
                vehicleTypes: [],
                checked: false,
            },
            {
                id: 'van',
                name: 'Personenbus',
                image: '/assets/icons/vehicle-packages/vehicle-package-van.svg',
                vehicleTypes: [],
                checked: false,
            },
        ]

        this.filterOptionMapping = {}

        this.vehicleTypeFilterOptions?.forEach((type: FilterOption) => {
            this.filterOptionMapping[type.label] = type

            if (type.label.toLowerCase().includes('bestelwagen')) {
                this.vehiclePackages[1].vehicleTypes.push({
                    id: type.label,
                    name: type.label,
                } as VehicleType)
            } else if (type.label.toLowerCase().includes('personenbus')) {
                this.vehiclePackages[2].vehicleTypes.push({
                    id: type.label,
                    name: type.label,
                } as VehicleType)
            } else {
                this.vehiclePackages[0].vehicleTypes.push({
                    id: type.label,
                    name: type.label,
                } as VehicleType)
            }
        })
    }

    public toggleVehiclePackage(vehiclePackage: Partial<VehiclePackage>, enabled: boolean): void {
        const filterOptions = vehiclePackage.vehicleTypes
            .map((type: VehicleType) => this.getVehicleTypeFilterOptionByVehicleType(type))
            .filter((option: Partial<FilterOption>) => !! option)

        // Update the enabled state of the current button
        this.allButtonsEnabledState.set(vehiclePackage.id, enabled)

        if (enabled) {
            this.selectedFilterOptions = [
                ...this.selectedFilterOptions,
                ...filterOptions.filter((option) => {
                    const occurrences = this.selectedFilterOptions.filter((opt) => opt.id === option.id).length
                    return occurrences < 2
                }),
            ]
        } else {
            const filterOptionsIds = filterOptions.map((option) => option.id)
            this.selectedFilterOptions = this.selectedFilterOptions.filter((option: Partial<FilterOption>) => {
                if (! filterOptionsIds.includes(option.id)) {
                    return true
                }
                const occurrences = this.selectedFilterOptions.filter((opt) => opt.id === option.id).length
                return occurrences > 1
            })
        }

        // Check if all buttons are disabled
        const anyButtonEnabled = Array.from(this.allButtonsEnabledState.values()).some(isEnabled => isEnabled)
        if (! anyButtonEnabled) {
            this.selectedFilterOptions = []
        }

        this.valueChanged.emit(this.selectedFilterOptions)
    }

    public updateVehiclePackageStateByFilterOptions(filterOptions: FilterOption[]): void {
        // Set the `checked` property of each `vehiclePackage` based on the `filterOptions` (one match is sufficient)
        this.zone.run(() => {
            this.vehiclePackages = this.vehiclePackages.map((v) => ({
                ...v,
                checked: v.vehicleTypes.some((type: VehicleType) =>
                    filterOptions.some((option: FilterOption) => option.label === type.name)),
            }))
        })
    }

}
