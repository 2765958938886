<p-dialog header="Gevonden wat u zocht?"
          [(visible)]="showPopup"
          [modal]="true"
          [closable]="true"
          [dismissableMask]="true"
          [blockScroll]="false"
          [breakpoints]="{'960px': '75vw', '640px': '95vw'}" [style]="{width: '40vw'}"
          (onHide)="closePopup()"
>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="p-5 pt-0">
        <p class="pt-0">We zijn benieuwd naar uw mening</p>
        <div *ngFor="let option of options" class="p-field-checkbox mb-3">
            <input type="checkbox" [id]="option" [value]="option" (change)="onCheckboxChange($event, option)">
            <label [for]="option">{{ option }}</label>
        </div>
        <p *ngIf="form.get('selectedOptions').invalid && form.get('selectedOptions').touched" class="p-error">
            U moet minstens één optie selecteren.
        </p>
        <div class="mt-3">
            <label for="otherReason">Andere reden:</label>
            <textarea pInputTextarea
                      id="otherReason"
                      formControlName="otherReason"
                      placeholder="Schrijf hier uw reden..."
            ></textarea>
            <p *ngIf="form.errors?.otherReasonRequired && form.get('otherReason').touched" class="p-error">
                U moet een reden opgeven als u "Anders, namelijk..." selecteert.
            </p>
        </div>
        <button pButton
                class="p-button p-button-rounded p-button--full-width mt-3"
                [label]="'Verstuur'"
                type="submit"
        >
        </button>
    </form>
</p-dialog>
