import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { CheckoutService, FilterService } from '@app-services';

@Injectable({
    providedIn: 'root',
})
export class CheckoutGuard implements CanActivate {

    constructor(
        private checkoutService: CheckoutService,
        private filterService: FilterService,
        private router: Router,
    ) {
    }

    canActivate(
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // A Guard that checks the required components to make finish the checkout flow,
        // and otherwise redirects to the filter page

        const rentalStateData = this.checkoutService.getStateRental().getValue();
        const filterStateDate = this.filterService.getFilter().getValue();
        if (
            filterStateDate.dateTimeTo
            && filterStateDate.dateTimeFrom
            && filterStateDate.deliveryMethod
            && rentalStateData.partnerVehicleId
        ) {
            return true;
        }
        console.log('You are missing required fields');
        // alert('You are missing required fields, navigating back to filter');
        this.router.navigate(['/filter']);
        return false;

    }

}
