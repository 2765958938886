import { Component, OnInit } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

import { PaginatorInfo, PartnerVehicle } from '@app-graphql'
import { ListingService } from '@app-services'

@Component({
    selector: 'app-car-listing',
    templateUrl: './car-listing.component.html',
    styleUrls: ['./car-listing.component.scss'],
})
export class CarListingComponent implements OnInit {

    public listing$: BehaviorSubject<PartnerVehicle[]> = this.listingService.state.partnerVehicles
    public listingIsLoading$ = this.listingService.state.partnerVehiclesLoading
    public paginator$: BehaviorSubject<PaginatorInfo> = this.listingService.state.partnerVehiclesPagination

    constructor(
        private listingService: ListingService,
    ) {
    }

    public async ngOnInit(): Promise<void> {
        await this.listingService.getFilteredListing()
    }

    public async onPageChange(event: any): Promise<void> {
        this.listingService.state.paginatorInfo.page = event.page + 1
        await this.listingService.getFilteredListing(
            this.listingService.state.paginatorInfo.first,
            this.listingService.state.paginatorInfo.page,
        )

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

}
