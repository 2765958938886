import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'

import { AuthService, UserService } from '@app-services'
import { User } from '@app-graphql'

@Component({
    selector: 'app-checkout-customer-auth-card',
    templateUrl: './checkout-customer-auth-card.component.html',
    styleUrls: ['./checkout-customer-auth-card.component.scss'],
})
export class CheckoutCustomerAuthCardComponent implements OnDestroy, OnInit {

    public isAuthenticated: boolean | null = null
    public user: Partial<User> | null = null

    private user$: Subscription | null = null

    constructor(
        private readonly authService: AuthService,
        private readonly userService: UserService,
    ) {
        this.userService.user$.subscribe((user) => this.user = user)
    }

    public ngOnDestroy(): void {
        this.user$?.unsubscribe()
    }

    public async ngOnInit(): Promise<void> {
        this.isAuthenticated = await this.authService.isAuthenticated()
        if (this.isAuthenticated) {
            await this.userService.getUser('network-only')
        }
    }

    public async logout(): Promise<void> {
        await this.authService.logout()
        this.isAuthenticated = false
    }

}
