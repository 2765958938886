import { Injectable, Inject, isDevMode } from '@angular/core';

import { WINDOW } from '@app-services/window/window.provider';

@Injectable()
export class WindowService {

    constructor(
        @Inject(WINDOW) private window: Window,
    ) {
        //
    }

    public getHostname(): string {
        if (
            isDevMode()
            || this.window.location.hostname.includes('pwstaging.tech')
            || this.window.location.hostname === 'localhost'
        ) {
            let serviceProvider = new URLSearchParams(location.search).get('serviceProvider');

            if (serviceProvider) {
                sessionStorage.setItem('serviceProvider', serviceProvider);
            } else {
                serviceProvider = sessionStorage.getItem('serviceProvider');
            }
            return serviceProvider ?? 'reisbalans';
        }

        return this.window.location.hostname.split('.')[0];
    }

}
