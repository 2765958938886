import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { environment } from '@app-environment';

@Injectable({
    providedIn: 'root',
})
export class ProductionGuard implements CanActivate {
    public canActivate(): boolean {
        return ! environment.production;
    }
}
