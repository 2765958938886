import { AfterViewInit, ChangeDetectorRef, Component, Input, NgZone } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { BusinessHour, PartnerLocation } from '@app-graphql'
import { BehaviorSubject } from 'rxjs'
import { ListingService } from '@app-services'

@Component({
    selector: 'app-car-detail-location',
    templateUrl: './car-detail-location.component.html',
    styleUrls: ['./car-detail-location.component.scss'],
})
export class CarDetailLocationComponent implements AfterViewInit {

    @Input()
    public partnerLocation: Partial<PartnerLocation>

    public map: google.maps.Map
    public markers: google.maps.Marker[] = []

    public businessHours = new BehaviorSubject<BusinessHour[]>([])
    public specialHours = new BehaviorSubject<BusinessHour[]>([])
    public dayOfTheWeek = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
    ]

    constructor(
        private readonly listingService: ListingService,
        private readonly translate: TranslateService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly ngZone: NgZone,
    ) {
    }

    public async ngAfterViewInit(): Promise<void> {
        this.initMap()

        this.listingService.getBusinessHours({ id: this.partnerLocation.id })
        this.listingService.getSpecialHours({ id: this.partnerLocation.id })

        this.ngZone.run(() => {
            this.listingService.state.businessHours.subscribe((hours) => {
                this.businessHours.next(hours)
                this.changeDetectorRef.detectChanges()
            })

            this.listingService.state.specialHours.subscribe((hours) => {
                this.specialHours.next(hours)
                this.changeDetectorRef.detectChanges()
            })
        })
    }

    public initMap(): void {
        this.markers.push(new google.maps.Marker({
            map: this.map || null,
            position: { lat: this.partnerLocation.location.lat, lng: this.partnerLocation.location.lng },
            title: '',
        }))
    }

    public mapReady(mapRef: google.maps.Map): void {
        this.map = mapRef

        this.markers.forEach((marker) => {
            marker.setMap(this.map)
        })
    }

    public getDayOfTheWeek(day: string | number): string {
        const dayNumber = Number(day)
        return this.translate.instant(`general.${this.dayOfTheWeek[dayNumber]}`)
    }
}
