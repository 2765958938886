import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {

    @Input()
    public options: google.maps.MapOptions = {};

    @Output()
    public mapReady = new Subject<google.maps.Map>();

    @ViewChild('map', { static: true, read: ElementRef })
    public mapElement: ElementRef<HTMLDivElement>;

    public map: google.maps.Map;

    public ngOnInit(): void {
        this.map = new google.maps.Map(this.mapElement.nativeElement, this.options);
        this.mapReady.next(this.map);
    }

}
