import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { Location, PartnerVehicle } from '@app-graphql'
import { CheckoutService } from '@app-services'

@Component({
    selector: 'app-car-info-card',
    templateUrl: './car-info-card.component.html',
    styleUrls: ['./car-info-card.component.scss'],
})
export class CarInfoCardComponent implements OnInit {

    @Input()
    public car: Partial<PartnerVehicle>

    @Input()
    public loading: Boolean

    public rentalCompanyLocation: Location

    constructor(
        private router: Router,
        private checkoutService: CheckoutService,
    ) {
    }

    public ngOnInit(): void {
        this.rentalCompanyLocation = this.car.partnerLocation?.location as Location
    }

    public async goToCheckout(carId: string, partnerLocationId: string): Promise<void> {
        this.checkoutService.setRentalValue({ name: 'partnerVehicleId', value: carId })
        this.checkoutService.setRentalValue({ name: 'partnerLocationId', value: partnerLocationId })

        await this.router.navigate(['/checkout/extras'])
    }

}
