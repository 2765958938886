import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {

    public selected = 'nl';

    constructor(
        public translateService: TranslateService,
        private config: PrimeNGConfig,
    ) {
        this.setInitialAppLanguage();
    }

    public setInitialAppLanguage(): void {
        this.translateService.addLangs(['en', 'nl']);
        // const lng = localStorage.getItem('language') || this.translateService.getBrowserLang();
        // this.setLanguage(lng);
        this.setLanguage('nl');
    }

    public setLanguage(lng: string): void {
        this.translateService.setDefaultLang(lng);
        this.translateService.use(lng);
        this.selected = lng;
        localStorage.setItem('language', lng);
    }

}
