<div>

    <svg *ngIf="showSpinner"
         class="loading"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink"
         style="margin: auto; background: none; display: block; shape-rendering: auto; animation-play-state: running; animation-delay: 0s;"
         width="100px"
         height="100px"
         viewBox="0 0 100 100"
         preserveAspectRatio="xMidYMid">
        <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#058ef9" stroke="none" style="animation-play-state: running; animation-delay: 0s;">
            <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51;360 50 51" style="animation-play-state: running; animation-delay: 0s;"></animateTransform>
        </path>
    </svg>

    <app-map class="car-map"
             style="height: 90vh"
             [options]="options"
             (mapReady)="mapReady($event)"
    />

</div>
