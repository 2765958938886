import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { ConfirmationService, MessageService } from 'primeng/api'
import { Subscription } from 'rxjs'

import { UserService } from '@app-services'
import { Rental } from '@app-graphql'

export enum RentalStatusEnum {
    PENDING = 'pending',
    ACCEPTED = 'accepted',
    CANCELLED = 'cancelled',
    DECLINED = 'declined',
    FINALIZED = 'finalized',
}

@Component({
    selector: 'app-order-history-card',
    templateUrl: './order-history-card.component.html',
    styleUrls: ['./order-history-card.component.scss'],
    providers: [ConfirmationService, MessageService],
})
export class OrderHistoryCardComponent implements OnInit, OnDestroy {

    public rentals: Partial<Rental>[] = []

    private rentals$: Subscription | null = null

    public readonly RentalStatusEnum = RentalStatusEnum;

    constructor(
        private readonly userService: UserService,
        private readonly confirmationService: ConfirmationService,
        private readonly messageService: MessageService,
        private readonly zone: NgZone,
    ) {
        this.userService.rentals$.subscribe((rentals) => {
            this.rentals = rentals;
        });
    }

    public ngOnDestroy(): void {
        this.rentals$?.unsubscribe()
    }

    public async ngOnInit(): Promise<void> {
        this.rentals = await this.userService.myRentals()
    }

    public async confirmCancelRental(rental: Partial<Rental>) {
        this.confirmationService.confirm({
            header: 'U staat op het punt uw voertuig te annuleren, weet u dit zeker?',
            message: 'Weet u zeker dat u deze reservering wilt annuleren? Dit kan niet ongedaan worden gemaakt.',

            accept: async () => {
                await this.userService.cancelMyRental(rental.id)

                this.messageService.add({
                    severity: 'success',
                    summary: 'Geannuleerd',
                    detail: 'De reservering is geannuleerd',
                })
            },
        })
    }
}
