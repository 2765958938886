import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-checkout-order-unprocessed',
    templateUrl: './checkout-order-unprocessed.component.html',
    styleUrls: ['./checkout-order-unprocessed.component.scss'],
})
export class CheckoutOrderUnprocessedComponent {

    constructor(
        private router: Router,
    ) {
    }

    public retryRentalProcess(): void {
        this.router.navigate(['checkout/extras']);
    }

}
