<div class="skew-background skew-3"></div>
<svg class="oval oval--1" width="70" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
    <path
            d="M30 0c16.569 0 30 13.431 30 30 0 16.569-13.431 30-30 30C13.431 60 0 46.569 0 30 0 13.431 13.431 0 30 0Zm0 10c-11.046 0-20 8.954-20 20s8.954 20 20 20 20-8.954 20-20-8.954-20-20-20Z"
            fill="var(--color-primary)" fill-rule="nonzero"/>
</svg>
<svg class="oval oval--2" width="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
    <path
            d="M30 0c16.569 0 30 13.431 30 30 0 16.569-13.431 30-30 30C13.431 60 0 46.569 0 30 0 13.431 13.431 0 30 0Zm0 10c-11.046 0-20 8.954-20 20s8.954 20 20 20 20-8.954 20-20-8.954-20-20-20Z"
            fill="var(--color-secondary)" fill-rule="nonzero"/>
</svg>
<svg class="oval oval--3" width="200" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
    <path
            d="M128 0c70.692 0 128 57.308 128 128 0 70.692-57.308 128-128 128C57.308 256 0 198.692 0 128 0 57.308 57.308 0 128 0Zm0 18.893C67.742 18.893 18.893 67.742 18.893 128S67.742 237.107 128 237.107 237.107 188.258 237.107 128 188.258 18.893 128 18.893Z"
            fill="var(--color-primary)" fill-rule="nonzero"/>
</svg>
