import { Injectable } from '@angular/core'

import {
    QuestionsQueryService,
    SubmitQuestionFormMutationService,
    SubmitQuestionFormMutationVariables,
} from '@app-graphql'

@Injectable({
    providedIn: 'root',
})
export class ContactFormService {

    constructor(
        private questionsQueryService: QuestionsQueryService,
        private submitQuestionFormMutationService: SubmitQuestionFormMutationService,
    ) {
    }

    public getContactFormQuestions(onContext: string) {
        return this.questionsQueryService.fetch({ onContext })
    }

    public sendContactFormQuestions(input: SubmitQuestionFormMutationVariables) {
        return this.submitQuestionFormMutationService.mutate(input)
    }

}
