import { Injectable } from '@angular/core'
import { ApolloQueryResult, FetchPolicy } from '@apollo/client/core'
import { BehaviorSubject, firstValueFrom, lastValueFrom, Subject } from 'rxjs'
import { map } from 'rxjs/operators'
import { clone } from 'ramda'

import {
    CancelMyRentalMutationService,
    MeQuery,
    MeQueryService,
    MyRentalsQuery,
    MyRentalsQueryService,
    Rental,
    UpdateMeInput,
    UpdateMeMutation,
    UpdateMeMutationService,
    User,
    VerifyEmailInput,
    VerifyEmailMutation,
    VerifyEmailMutationService,
} from '@app-graphql'
import { AuthService } from '@app-services/api/auth.service'

@Injectable({
    providedIn: 'root',
})
export class UserService {

    public user$ = new BehaviorSubject<Partial<User | null>>(null)
    public rentals$ = new Subject<Partial<Rental>[]>()

    public initialized = false

    private user: Partial<User> | null = null
    private rentals: Partial<Rental>[] | null = null

    constructor(
        private readonly authService: AuthService,
        private readonly cancelMyRentalMutationService: CancelMyRentalMutationService,
        private readonly meQueryService: MeQueryService,
        private readonly myRentalsQueryService: MyRentalsQueryService,
        private readonly updateMeMutationService: UpdateMeMutationService,
        private readonly verifyEmailMutationService: VerifyEmailMutationService,
    ) {
    }

    public async initialize(): Promise<void> {
        if (this.initialized) {
            return
        }

        await this.getUser()

        this.initialized = true
    }

    public async getUser(fetchPolicy?: FetchPolicy): Promise<Partial<User> | null> {

        // Only get the user if we're authenticated
        const isAuthenticated: boolean = await this.authService.isAuthenticated()
        if (! isAuthenticated) {
            return null
        }

        const user$ = this.meQueryService.fetch(undefined, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<MeQuery>) => {
                this.user = result.data.me as Partial<User> | null
                this.user$.next(this.user)

                return this.user
            }),
        )

        return lastValueFrom(user$)
    }

    public async updateUser(input: UpdateMeInput): Promise<UpdateMeMutation> {
        const response = await firstValueFrom(
            this.updateMeMutationService.mutate({ input }),
        )

        this.user = response.data.updateMe as Partial<User> | null
        this.user$.next(this.user)

        return response.data
    }

    public async verifyEmail(input: VerifyEmailInput): Promise<VerifyEmailMutation> {
        try {
            const response = await firstValueFrom(
                this.verifyEmailMutationService.mutate({ input }),
            );
            return response.data;
        } catch (e) {
            console.log('Error verifying email', e);
            // throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e));
        }
    }

    public async myRentals(clearCache = false): Promise<Partial<Rental>[]> | null {
        const fetchPolicy = clearCache ? 'network-only' : 'cache-first'
        const rentals$ = this.myRentalsQueryService.fetch({}, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<MyRentalsQuery>) => {
                this.rentals = result.data.myRentals as unknown as Partial<Rental>[]
                this.rentals$.next(this.rentals)

                return this.rentals
            }),
        )

        return firstValueFrom(rentals$)
    }

    public async cancelMyRental(id: string): Promise<void> {
        try {
            await firstValueFrom(
                this.cancelMyRentalMutationService.mutate(
                    { id },
                    {
                        update: (store) => {
                            // Get currently cached data
                            const data = clone(
                                store.readQuery<MyRentalsQuery>({ query: this.myRentalsQueryService.document }),
                            )

                            // Find the item in cache and update its rentalStatus
                            const rentalIndex = data?.myRentals?.findIndex((r) => r.id === id)
                            if (rentalIndex !== -1) {
                                data.myRentals[rentalIndex].rentalStatus = 'cancelled'
                                store.writeQuery({ query: this.myRentalsQueryService.document, data })

                                this.rentals$.next(data?.myRentals as Partial<Rental>[] || [])
                            }
                        },
                    },
                ),
            )
        } catch (e) {
            console.error('Error canceling rental', e)
            // throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }
}
