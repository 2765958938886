<div *ngIf="car">

    <div *ngIf="image"
         class="car-detail-header__car-delivery-image"
         [class.show-drone]="('name' | serviceProvider) === 'Reisbalans'"
    >
        <img [src]="image" alt="" />
    </div>

    <div class="flex flex-wrap">
        <div class="car-detail-header__car-attribute">
            <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                    <path d="M0 0h32v32H0z"/>
                    <g transform="translate(9 11)" fill="#1D1C2D">
                        <ellipse cx="3.824" cy="2.308" rx="2.294" ry="2.308"/>
                        <ellipse cx="9.559" cy="2.13" rx="2.065" ry="2.13"/>
                        <path
                            d="M7.265 8.462v.768H6.118V7.743c0-.448.106-.872.296-1.248.524.491.85 1.19.85 1.968Zm2.963-3.492A2.772 2.772 0 0 1 13 7.742v1.489H8.029v-.77a3.462 3.462 0 0 0-1.172-2.603A2.764 2.764 0 0 1 8.89 4.97h1.338Z"/>
                        <path
                            d="M3.08 5.385h1.487a3.08 3.08 0 0 1 3.08 3.08V10H0V8.465a3.08 3.08 0 0 1 3.08-3.08Z"/>
                    </g>
                </g>
            </svg>
            <p>
                {{ car.vehicleAttribute.numOfPersons }} {{ 'detailPage.passengers' | translate }}
            </p>
        </div>

        <div class="car-detail-header__car-attribute">
            <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                    <path d="M0 0h32v32H0z"/>
                    <path
                        d="M21.227 10c.98 0 1.773.806 1.773 1.8a1.8 1.8 0 0 1-1.181 1.697V17.2H17.09v1.302a1.797 1.797 0 0 1 1.177 1.55l.006.148c0 .994-.794 1.8-1.773 1.8-.98 0-1.773-.806-1.773-1.8a1.8 1.8 0 0 1 1.182-1.698V17.2h-3.546v1.302a1.797 1.797 0 0 1 1.177 1.55l.005.148c0 .994-.793 1.8-1.772 1.8-.98 0-1.773-.806-1.773-1.8a1.8 1.8 0 0 1 1.181-1.697v-5.006A1.8 1.8 0 0 1 10 11.8c0-.994.794-1.8 1.773-1.8.979 0 1.772.806 1.772 1.8a1.8 1.8 0 0 1-1.181 1.698L12.363 16h3.546v-2.502a1.797 1.797 0 0 1-1.176-1.55l-.006-.148c0-.994.794-1.8 1.773-1.8.98 0 1.773.806 1.773 1.8a1.8 1.8 0 0 1-1.182 1.698L17.09 16h3.546v-2.502a1.797 1.797 0 0 1-1.176-1.55l-.005-.148c0-.994.793-1.8 1.772-1.8Z"
                        fill="#1D1C2D"/>
                </g>
            </svg>
            <p>
                {{ car.vehicleTransmission.name }}
            </p>
        </div>

        <div class="car-detail-header__car-attribute">
            <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                    <path d="M0 0h32v32H0z"/>
                    <path
                        d="M19 22a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1v-9a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1.25h1.49a.75.75 0 0 1 .743.648l.007.102-.001 5.894h.798v-6.95l-1.673-1.674a.75.75 0 0 1-.073-.976l.073-.084a.75.75 0 0 1 .976-.073l.084.073 1.894 1.894a.75.75 0 0 1 .21.412l.01.118v8.01a.75.75 0 0 1-.648.744l-.102.006H20.49a.75.75 0 0 1-.744-.648l-.006-.102-.001-5.894H19V22Zm-3-10h-4a1 1 0 0 0-.993.883L11 13v2a1 1 0 0 0 .883.993L12 16h4a1 1 0 0 0 .993-.883L17 15v-2a1 1 0 0 0-1-1Z"
                        fill="#1D1C2D"/>
                </g>
            </svg>
            <p>
                {{ car.vehicleFuelType.name }}
            </p>
        </div>

        <div class="car-detail-header__car-attribute">
            <p>
                {{ car.additionalTaxPercentage }}% {{ 'detailPage.addition' | translate }}
            </p>
        </div>

        <ng-container *ngIf="car.vehicleAttribute.dimensions">
            <div class="car-detail-header__car-attribute">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                    <path d="M440-183v-274L200-596v274l240 139Zm80 0 240-139v-274L520-457v274Zm-40-343 237-137-237-137-237 137 237 137ZM160-252q-19-11-29.5-29T120-321v-318q0-22 10.5-40t29.5-29l280-161q19-11 40-11t40 11l280 161q19 11 29.5 29t10.5 40v318q0 22-10.5 40T800-252L520-91q-19 11-40 11t-40-11L160-252Zm320-228Z" />
                </svg>
                <p>
                    {{ car.vehicleAttribute.dimensions }}
                </p>
            </div>
        </ng-container>

        <div *ngIf="car.productRules?.length" class="grid w-full product-rule__container">
            <div class="col-12 md:col-6 xl:col-4" *ngFor="let productRule of car.productRules.slice(0, 3)">
                <div class="product-rule border-round-sm bg-primary font-bold">
                    <p-card class="product-rule__card card-no-content-padding">
                        <div class="flex flex-column">
                            <img [src]="productRule.icon" alt="" class="product-rule__icon mx-auto" />
                            <strong class="mx-auto mt-1 text-center">{{ productRule.title }}</strong>
                        </div>
                    </p-card>
                </div>
            </div>

            <div class="flex justify-content-center w-full">
                <p-button label="Productregels" class="mt-3" (click)="openProductRulesTab()"></p-button>
            </div>
        </div>

    </div>
</div>
