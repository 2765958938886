import { Injectable } from '@angular/core'
import { ExitPollInput, SubmitExitPollMutationService } from '@app-graphql'
import { firstValueFrom } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class ExitPollService {

    public constructor(
        private readonly submitExitPollMutationService: SubmitExitPollMutationService,
    ) {
    //
    }

    public async submitExitPoll(input: ExitPollInput): Promise<string> {
        const response = await firstValueFrom(
            this.submitExitPollMutationService.mutate(({ input })),
        )

        return response.data.submitExitPoll;
    }

}
