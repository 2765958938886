import { Pipe, PipeTransform } from '@angular/core'
import { AuthService } from '@app-services/api'

@Pipe({
    name: 'authenticated',
})
export class AuthenticatedPipe implements PipeTransform {

    constructor(
        private readonly authService: AuthService,
    ) {
    }

    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    public transform(_a: any): Promise<boolean> {
        return this.authService.isAuthenticated()
    }

}
