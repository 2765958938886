import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-input-error',
    templateUrl: './input-error.component.html',
    styleUrls: ['./input-error.component.css'],
})
export class InputErrorComponent {

    @Input()
    public error: any;

}
