<div class="home-header"
     [style.background-image]="'url(' + (page ? (page.header ?? '/assets/img/background.jpg') : '') + ')'"
>

    <div class="outer-container full-height"
         style="height: 100%;">

        <form [formGroup]="formGroup" (submit)="handleFilterSubmit()">
            <div class="grid full-height">

                <div class="col-12 lg:col-6">
                    <h2 class="home-header__title mb-3">
                        {{
                            ('name' | serviceProvider) === 'Centraal Beheer'
                                ? 'Voordelig uw Vakantieauto huren'
                                : ('homePage.title' | translate)
                        }}
                    </h2>

                    <!-- @TODO: Get from API once available -->
                    <ng-template #usps let-usps="usps">
                        <div *ngFor="let usp of usps" class="home-header__attribute mb-3">
                            <i class="pi pi-check"></i>
                            <div class="usp-block" [innerHTML]="usp"></div>
                        </div>
                    </ng-template>
                    <ng-container [ngTemplateOutlet]="usps"
                                  [ngTemplateOutletContext]="{
                                  usps: ('name' | serviceProvider) === 'Centraal Beheer'
                                      ? [
                                          'Vanuit Nederland op autovakantie, zonder verrassingen achteraf',
                                          'Bespaar gemiddeld <h4>€125</h4> door extra gratis vrije kilometers',
                                          'Extra bestuurders t.w.v. <h4>€60</h4> in de prijs inbegrepen',
                                      ]
                                      : [
                                          'Enorm aanbod',
                                          'Landelijke dekking',
                                          'Binnen 4 uur rijden',
                                      ],
                              }"
                    />



                    <label class="mb-2 text-white mt-5">
                        Selecteer uw gewenste huurauto
                    </label>
                    <app-car-vehicle-package-selection #vehiclePackageSelectionComponent
                                                       [vehicleTypeFilterOptions]="filterGroups?.Type"
                                                       [vehiclePackages]="'vehiclePackages' | serviceProvider"
                                                       [hidden]="isLoading"
                                                       (valueChanged)="updateVehicleTypeFilterOptions($event)"
                    />
                    <!-- Dummy form element used for native form validation for the vehicle packages -->
                    <input #selectedVehiclePackagesDummyElement
                           type="radio"
                           name="vehicle-packages-value"
                           class="dummy-radio"
                           tabindex="-1"
                           required
                           data-invalid-message="Selecteer ten minste één voertuigtype"
                           [checked]="!! formGroup.get('vehicleType').value?.length"
                    />

                    <ng-container *ngIf="! isLoading; else loadingContent">
                        <div #filters class="flex mt-3">
                            <div>
                                <label class="mb-2 text-white">
                                    {{ "filterPage.filter.startDate" | translate }}
                                </label>
                                <p-calendar formControlName="dateTimeFrom"
                                            class="home-header__inputs"
                                            dateFormat="D dd MM"
                                            [minDate]="minDateTimeFrom"
                                            [showIcon]="true"
                                            [showTime]="true"
                                            [appendTo]="filters"
                                            inputId="icon"
                                />
                            </div>
                            <div class="ml-4">
                                <label class="mb-2 text-white">
                                    {{ "filterPage.filter.endDate" | translate }}
                                </label>
                                <p-calendar formControlName="dateTimeTo"
                                            class="home-header__inputs"
                                            dateFormat="D dd MM"
                                            [minDate]="minDateTimeTo"
                                            [showIcon]="true"
                                            [showTime]="true"
                                            [appendTo]="filters"
                                            inputId="icon"
                                />
                            </div>
                        </div>

                        <div class="home-header__type-container mt-4"
                             [class.hidden]="'hasCustomVehicleTypeSelection' | serviceProvider"
                        >
                            <label class="mb-2 text-white">
                                {{ "filterPage.filter.typeHeading" | translate }}
                            </label>
                            <p-multiSelect [options]="filterGroups.Type"
                                           formControlName="vehicleType"
                                           (onChange)="updateVehicleTypeFromMultiSelect()"
                            />
                        </div>

                        <div class="home-header__search-container my-3">
                            <div class="p-inputgroup">
                                <span class="p-input-icon-left">
                                    <p-progressSpinner *ngIf="geoLocationIsLoading | async"
                                                       [style]="{ width: '30px', height: '30px' }"
                                    />
                                    <i class="pi pi-search"></i>
                                    <input formControlName="search"
                                           type="text"
                                           pInputText
                                           required
                                           placeholder="{{'callToAction.placeholder' | translate}}"
                                    />
                                </span>
                                <p-button type="button"
                                          (click)="getCurrentLocation()"
                                          [pTooltip]="'callToAction.currentLocation' | translate"
                                          tooltipPosition="top"
                                          styleClass="p-button p-button-small p-button-no-border-radius"
                                          class="p-inputgroup-addin"
                                >
                                    <i class="pi pi-map-marker"></i>
                                </p-button>
                            </div>
                            <p-button type="submit"
                                      styleClass="p-button p-button-small ml-2 p-button-no-border-radius"
                            >
                                {{'callToAction.searchBtn' | translate}}
                            </p-button>
                        </div>
                    </ng-container>

                    <ng-template #loadingContent>
                        <div class="flex flex-row align-content-center flex-wrap gap-3 mb-2">
                            <div class="p-skeleton p-mb-2 mb-1" style="width:120px; height:100px;">
                                <div></div>
                            </div>
                            <div class="p-skeleton p-mb-2 mb-1" style="width:120px; height:100px;">
                                <div></div>
                            </div>
                            <div class="p-skeleton p-mb-2 mb-1" style="width:120px; height:100px;">
                                <div></div>
                            </div>
                        </div>

                        <div class="flex flex-row align-content-center flex-wrap gap-3 mt-5 mb-2">
                            <div class="p-skeleton p-mb-2 mb-1" style="width:100%; height:48px;">
                                <div></div>
                            </div>
                        </div>

                        <div class="mt-5 mb-4">
                            <div class="p-skeleton p-mb-2 mb-1" style="width:90%; height:49px;">
                                <div></div>
                            </div>
                        </div>

                        <div class="mt-5 mb-2">
                            <div class="p-skeleton p-mb-2 mb-1" style="width:90%; height:49px;">
                                <div></div>
                            </div>
                        </div>
                    </ng-template>

                    <!--                    <p-button (click)="getCurrentLocation()"-->
                    <!--                              styleClass="p-button-small p-button-white p-button-no-border-radius"-->
                    <!--                              class="home-header__attribute cursor-pointer home-header__attribute&#45;&#45;small mt-3 mb-4"-->
                    <!--                    >-->
                    <!--                        <i class="pi pi-map-marker"></i>-->
                    <!--                        <p>{{"callToAction.currentLocation" | translate}}</p>-->
                    <!--                    </p-button>-->
                </div>

                <div *ngIf="('name' | serviceProvider) === 'Reisbalans'"
                     class="col-12 lg:col-6 home-header__car-container">
                    <img [src]="randomCarImage" alt="" />
                </div>

            </div>
            <div>
            </div>
        </form>
    </div>

    <div class="skew-background skew-home"></div>
</div>
