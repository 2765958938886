import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ContactFormService, WindowService } from '@app-services';

@Component({
    selector: 'app-customer-service-form',
    templateUrl: './customer-service-form.component.html',
    styleUrls: ['./customer-service-form.component.scss'],
})
export class CustomerServiceFormComponent implements OnInit {

    public $subjects = this.contactFormService.getContactFormQuestions(this.windowService.getHostname());

    public formState: FormGroup;

    public responseMessage = '';
    public submitting = false;

    constructor(
        private fb: FormBuilder,
        private contactFormService: ContactFormService,
        private windowService: WindowService,
    ) {
    }

    public ngOnInit(): void {
        this.contactFormService.getContactFormQuestions(this.windowService.getHostname());
        this.formState = this.fb.group({
            subject: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            message: ['', [Validators.required]],
        });
    }

    public submitForm(formIsValid: boolean): void {
        if (! formIsValid) {
            document.querySelector<HTMLInputElement>('.ng-invalid')?.reportValidity();
            return;
        }

        this.submitting = true;
        const input = {
            questionId: this.formState.controls.subject.value.id,
            email: this.formState.controls.email.value,
            content: this.formState.controls.message.value,
            onContext: this.windowService.getHostname(),
        };

        this.contactFormService.sendContactFormQuestions(
            input,
        ).subscribe((res) => {
            console.log(res);
            this.responseMessage = res.data?.submitQuestionForm || 'Kon niet versturen';
            this.submitting = false;
        });
    }

}
