import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { MutationResult } from 'apollo-angular'
import { firstValueFrom } from 'rxjs'

import {
    AuthPayload,
    ForgotPasswordMutation,
    ForgotPasswordMutationService,
    LoginInput,
    LoginMutation,
    LoginMutationService,
    LogoutMutation,
    LogoutMutationService,
    NewPasswordWithCodeInput,
    RefreshTokenMutation,
    RefreshTokenMutationService,
    RegisterInput,
    RegisterMutation,
    RegisterMutationService,
    ResetPasswordMutation,
    ResetPasswordMutationService,
} from '@app-graphql'
import { LocalStorageService } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    private authPayload: AuthPayload
    private initialized = false

    public authenticationInProgress = false
    public userWasRedirectedAsAlreadyLoggedIn = false // This is to prevent infinite loops after gql errors

    constructor(
        private readonly loginMutationService: LoginMutationService,
        private readonly logoutMutationService: LogoutMutationService,
        private readonly forgotPasswordMutationService: ForgotPasswordMutationService,
        private readonly refreshTokenMutationService: RefreshTokenMutationService,
        private readonly registerMutationService: RegisterMutationService,
        private readonly resetPasswordMutationService: ResetPasswordMutationService,
        private readonly router: Router,
        private readonly storageService: LocalStorageService,
    ) {
    }

    public async initialize(): Promise<void> {
        if (this.initialized) {
            return
        }

        this.initialized = true
        this.authenticationInProgress = true

        await this.authenticateFromPersistedAuthPayload()

        this.authenticationInProgress = false
    }

    public async isAuthenticated(): Promise<boolean> {
        await this.initialize()
        return !! this.authPayload
    }

    public getAuthPayload(): AuthPayload {
        return this.authPayload
    }

    public async login(input: LoginInput): Promise<LoginMutation> {
        const response = await firstValueFrom(
            this.loginMutationService.mutate({ input }),
        )
        await this.handleAuthPayloadAndGetUser(response.data.login)

        return response.data
    }

    public async register(input: RegisterInput): Promise<RegisterMutation> {
        const response = await firstValueFrom(
            this.registerMutationService.mutate({ input }),
        )

        if (response?.data?.register?.tokens) {
            await this.handleAuthPayloadAndGetUser(response.data.register.tokens as AuthPayload)
        }

        return response.data
    }

    public async forgotPassword(email: string): Promise<ForgotPasswordMutation> {
        const response = await firstValueFrom(
            this.forgotPasswordMutationService.mutate({ input: { email } }),
        )

        return response.data
    }

    public async resetPassword(input: NewPasswordWithCodeInput): Promise<ResetPasswordMutation> {
        const response = await firstValueFrom(
            this.resetPasswordMutationService.mutate({ input }),
        )

        return response.data
    }

    public async logout(returnUrl?: string): Promise<LogoutMutation | null> {
        let response: MutationResult<LogoutMutation> | null = null

        try {
            response = await firstValueFrom(this.logoutMutationService.mutate())
        } catch (e) {
        }

        this.authPayload = null
        this.storageService.remove('auth')
        this.storageService.remove('refreshToken')
        this.storageService.remove('tokenExpiresAt')

        this.userWasRedirectedAsAlreadyLoggedIn = false

        if (returnUrl) {
            await this.router.navigate([returnUrl], { replaceUrl: true })
        }

        return response?.data;
    }

    public async refreshToken(refreshToken: string): Promise<RefreshTokenMutation> {
        try {
            const response = await firstValueFrom(
                this.refreshTokenMutationService.mutate({ input: { refreshToken } }),
            );
            await this.handleAuthPayloadAndGetUser(response.data.refreshToken);
            return response.data;
        } catch (e: any) {
            await this.logout('/auth/login');
            throw e;
        }
    }

    private async handleAuthPayloadAndGetUser(authPayload: AuthPayload): Promise<void> {
        this.authPayload = authPayload
        await this.persistAuthPayload()
    }

    private async persistAuthPayload(): Promise<void> {
        const tokenExpiresAt: number = new Date().getTime() + (this.authPayload.expiresIn * 1000)

        this.storageService.set({ name: 'auth', value: this.authPayload })
        this.storageService.set({ name: 'tokenExpiresAt', value: tokenExpiresAt })
        this.storageService.set({ name: 'refreshToken', value: this.authPayload.refreshToken })
    }

    private async authenticateFromPersistedAuthPayload(): Promise<void> {

        // Use stored token if it hasn't expired
        const persistedAuthPayload: AuthPayload | null = this.storageService.getOnLocalStorage('auth') || null
        if (persistedAuthPayload) {
            const tokenExpiresAt: number = this.storageService.getOnLocalStorage('tokenExpiresAt') || 0
            const now: number = new Date().getTime()
            if (tokenExpiresAt - (60 * 60) > now) {
                this.authPayload = persistedAuthPayload
                return
            }
        }

        // No (valid) login data present. Check if we've got a saved refreshToken we can use instead
        const refreshToken = await this.storageService.getOnLocalStorage('refreshToken')
        if (refreshToken) {
            await this.refreshToken(refreshToken)
        }
    }

}
