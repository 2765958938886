<div class="delivery-form-card"
     [formGroup]="form"
     *ngIf="form"
>

    <div formGroupName="customer">
        <div class="delivery-form-card__title">
            <h2>{{ 'customerData.title' | translate }}</h2>
        </div>
        <p class="sub-title">{{ 'customerData.subTitle' | translate }}</p>
        <div class="grid">
            <div class="col-12 md:col-6">
                <label for="firstName">{{ 'general.firstName' | translate }}</label>
                <input pInputText
                       formControlName="firstName"
                       id="firstName"
                       class="delivery-form-card__address__details__input"
                       type="text"
                       placeholder="Jane"
                />
            </div>
            <div class="col-12 md:col-6">
                <label for="lastName">{{ 'general.lastName' | translate }}</label>
                <input pInputText
                       formControlName="surName"
                       id="lastName"
                       class="delivery-form-card__address__details__input"
                       type="text"
                       placeholder="Smith"
                />
            </div>
            <div class="col-12 md:col-6" [hidden]="needsRegistrationToRent">
                <label for="email">{{ 'general.email' | translate }}</label>
                <input pInputText
                       formControlName="email"
                       id="email"
                       class="delivery-form-card__address__details__input"
                       type="email"
                       placeholder="jane.smith@mail.com"
                />
            </div>
            <div class="col-12 md:col-6">
                <label for="telephone">{{ 'general.telephone' | translate }}</label>
                <input pInputText
                       formControlName="phone"
                       id="telephone"
                       class="delivery-form-card__address__details__input"
                       type="tel"
                       placeholder="0623675678"
                />
            </div>
        </div>

        <ng-container *ngIf="needsRegistrationToRent">

            <input type="hidden" formControlName="birthdate" />

            <div class="formgrid grid">
                <div class="field col-12 md:col-6">
                    <label for="streetName">Straatnaam</label>
                    <div>
                        <input pInputText
                               type="text"
                               id="streetName"
                               formControlName="streetName"
                               aria-describedby="streetName-help"
                        />
                        <small id="streetName-help"
                               class="p-error"
                               [hidden]="form.get('customer.streetName').valid || ! form.get('customer.streetName').touched"
                        >
                            Straatnaam is verplicht.
                        </small>
                    </div>
                </div>

                <div class="field col-12 md:col-3">
                    <label for="streetNumber">Huisnummer</label>
                    <div>
                        <input pInputText
                               type="number"
                               id="streetNumber"
                               formControlName="streetNumber"
                               aria-describedby="streetNumber-help"
                        />
                        <small id="streetNumber-help"
                               class="p-error"
                               [hidden]="form.get('customer.streetNumber').valid || ! form.get('customer.streetNumber').touched"
                        >
                            Huisnummer is verplicht.
                        </small>
                    </div>
                </div>

                <div class="field col-12 md:col-3">
                    <label for="streetNumberSuffix">Toevoeging</label>
                    <div>
                        <input pInputText
                               type="text"
                               id="streetNumberSuffix"
                               formControlName="streetNumberSuffix"
                        />
                    </div>
                </div>
            </div>

            <div class="formgrid grid">
                <div class="field col-12 md:col-4 lg:col-3">
                    <label for="postalCode">Postcode</label>
                    <div>
                        <input pInputText
                               #postalCode
                               type="text"
                               id="postalCode"
                               formControlName="postalCode"
                               aria-describedby="postalCode-help"
                               class="uppercase"
                               (change)="postalCode.value = (postalCode.value || '').toUpperCase()"
                        />
                        <small id="postalCode-help"
                               class="p-error"
                               [hidden]="form.get('customer.postalCode').valid || ! form.get('customer.postalCode').touched"
                        >
                            Postcode is verplicht.
                        </small>
                    </div>
                </div>

                <div class="field col-12 md:col-8 lg:col-5">
                    <label for="city">Plaatsnaam</label>
                    <div>
                        <input pInputText
                               type="text"
                               id="city"
                               formControlName="city"
                               aria-describedby="city-help"
                        />
                        <small id="city-help"
                               class="p-error"
                               [hidden]="form.get('customer.city').valid || ! form.get('customer.city').touched"
                        >
                            Plaatsnaam is verplicht.
                        </small>
                    </div>
                </div>

                <div class="field col-12 lg:col-4">
                    <label for="country">Land</label>
                    <div>
                        <input pInputText
                               type="text"
                               id="country"
                               formControlName="country"
                               aria-describedby="country-help"
                        />
                        <small id="country-help"
                               class="p-error"
                               [hidden]="form.get('customer.country').valid || ! form.get('customer.country').touched"
                        >
                            Land is verplicht.
                        </small>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="grid">
            <div class="col-12"
                 [hidden]="(('customerType' | serviceProvider) === CustomerTypeEnum.B2B) && otherPersonIsDriver.checked"
            >
                <div class="my-2">
                    <div class="checkbox__container">
                        <input type="checkbox" class="checkbox" formControlName="haslicense" id="hasLicence">
                        <label for="hasLicence">{{ 'general.hasLicence' | translate }}</label>
                    </div>
                </div>
            </div>
        </div>

        <div [hidden]="('customerType' | serviceProvider) === CustomerTypeEnum.B2C"
             class="grid"
        >
            <div class="col-12">
                <div class="my-2">
                    <div class="checkbox__container">
                        <input #otherPersonIsDriver
                               type="checkbox"
                               class="checkbox"
                               formControlName="otherPersonIsDriver"
                               id="otherPersonIsDriver"
                               (change)="setDriverEnabled(otherPersonIsDriver.checked)"
                        >
                        <label for="otherPersonIsDriver">
                            {{ 'customerData.otherPersonIsDriverCheckbox' | translate }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div formGroupName="driver"
         [hidden]="! otherPersonIsDriver.checked"
    >
        <div class="delivery-form-card__title">
            <h3>{{ 'customerData.otherPersonIsDriver.title' | translate }}</h3>
        </div>
        <p class="sub-title">{{ 'customerData.otherPersonIsDriver.subTitle' | translate }}</p>
        <div class="grid">
            <div class="col-12 md:col-6">
                <label for="driverFirstName">{{ 'general.firstName' | translate }}</label>
                <input pInputText
                       formControlName="firstName"
                       id="driverFirstName"
                       class="delivery-form-card__address__details__input"
                       type="text"
                       placeholder="John"
                >
            </div>
            <div class="col-12 md:col-6">
                <label for="driverLastName">{{ 'general.lastName' | translate }}</label>
                <input pInputText
                       formControlName="lastName"
                       id="driverLastName"
                       class="delivery-form-card__address__details__input"
                       type="text"
                       placeholder="Doe"
                >
            </div>
            <div class="col-12 md:col-6">
                <label for="driverEmail">{{ 'general.email' | translate }}</label>
                <input pInputText
                       formControlName="email"
                       id="driverEmail"
                       class="delivery-form-card__address__details__input"
                       type="email"
                       placeholder="john.doe@mail.com"
                >
            </div>
            <div class="col-12 md:col-6">
                <label for="driverTelephone">{{ 'general.telephone' | translate }}</label>
                <input pInputText
                       formControlName="phone"
                       id="driverTelephone"
                       class="delivery-form-card__address__details__input"
                       type="tel"
                       placeholder="0623675678"
                >
            </div>
            <div class="col-12">
                <label for="driverInvoiceReference">
                    {{ 'customerData.otherPersonIsDriver.invoiceReference' | translate }}
                </label>
                <input pInputText
                       formControlName="invoiceReference"
                       id="driverInvoiceReference"
                       class="delivery-form-card__address__details__input"
                       type="text"
                       placeholder="Factuur 123"
                >
            </div>
            <div class="col-12">
                <div class="my-2">
                    <div class="checkbox__container">
                        <input type="checkbox"
                               class="checkbox"
                               formControlName="haslicense"
                               id="driverHasLicence"
                        >
                        <label for="driverHasLicence">
                            {{ 'customerData.otherPersonIsDriver.hasLicence' | translate }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <p-button type="button"
              [disabled]="! form.valid"
              (click)="handleFormSubmit(form.valid)"
              [label]="'general.nextStep' | translate"
              styleClass="p-button-rounded p-button-primary p-button--full-width">
    </p-button>

</div>
