<div class="grid">

    <div class="col-12 lg:col-6 sm:pr-4">
        <h5 class="mb-4">{{ "filterPage.filter.location" | translate }}</h5>
        <div class="location-card">
            <div class="grid">

                <div class="sm:col-6 col-12"
                     *ngIf="partnerLocation.location"
                >
                    <app-map class="car-detail-map"
                             style="height: 100%; min-height: 230px"
                             [options]="{ center: $any(partnerLocation.location), zoom: 14 }"
                             (mapReady)="mapReady($event)"
                    />
                </div>

                <div class="location-card--content sm:col-6 col-12"
                     *ngIf="partnerLocation"
                >
                    <h5>{{ partnerLocation.location.name }}</h5>
                    <p class="mt-4">
                        {{ partnerLocation.location.streetName }}
                        {{ partnerLocation.location.streetNumber }}
                        {{ partnerLocation.location.streetNumberSuffix }}
                    </p>
                    <p>
                        {{ partnerLocation.location.postalCode }}
                        {{ partnerLocation.location.city }}
                    </p>
                    <p class="mt-4">
                        <a [href]="'tel:'+partnerLocation.phone" class="link phone-link">
                            <i class="pi pi-phone"></i>
                            Tel: {{ partnerLocation.phone }}
                        </a>
                    </p>
                </div>
            </div>
        </div>
        <div class="detail-attribute mt-5 mb-3">
            <div>
                <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                        <path d="M0 0h32v32H0z" />
                        <path
                            d="M16 7c3.314 0 6 2.75 6 6.144a6.25 6.25 0 0 1-.5 2.462h.003l-.046.096c-.05.113-.105.225-.162.334l-4.02 8.273a1.2 1.2 0 0 1-2.071.152l-.068-.112-4.587-8.604h.014a6.244 6.244 0 0 1-.563-2.6C10 9.75 12.686 7 16 7Zm0 3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                            fill="#1D1C2D"
                        />
                    </g>
                </svg>
            </div>
            <p class="detail-attribute__text">
                <b>{{ partnerLocation?.distance | number: '1.1-1' }} km</b>
                {{ 'detailPage.distanceToLocation' | translate }}
            </p>
        </div>
    </div>

    <div class="hours col-12 lg:col-6 pl-4">
        <h5 class="table-title mb-2">{{ "detailPage.openingHours" | translate }}</h5>
        <p-tabView>
            <p-tabPanel header="{{ 'detailPage.regular' | translate }}">
                <table class="opening-table">
                    <tr *ngFor="let hour of businessHours | async">
                        <td *ngIf="hour.dayOfWeek" class="day">
                            {{ getDayOfTheWeek(hour.dayOfWeek) }}
                        </td>
                        <td>
                            {{ hour.openTime }} - {{ hour.closeTime }}
                        </td>
                    </tr>
                </table>
            </p-tabPanel>

            <p-tabPanel *ngIf="(specialHours | async)?.length"
                        header="{{ 'detailPage.specialDates' | translate }}"
            >
                <table class="opening-table">
                    <tr *ngFor="let hour of specialHours | async">
                        <td *ngIf="hour.specialDayDate" class="day">
                            {{ hour.specialDayDate }}
                        </td>
                        <td>
                            {{ hour.openTime }} - {{ hour.closeTime }}
                        </td>
                    </tr>
                </table>
            </p-tabPanel>
        </p-tabView>
    </div>

</div>
