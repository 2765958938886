import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'inputError',
})
export class InputErrorPipe implements PipeTransform {

    public transform(value: any): string {
        if (value !== null) {
            if (value.invalid === true) {
                return 'ERROR.FORM_NOT_VALID';
            }
            if (value.required === true) {
                return 'ERROR.REQUIRED';
            }
            if (value.email === true) {
                return 'ERROR.EMAIL';
            }
            if (value.pattern === true) {
                return 'ERROR.PATTERN';
            }
        }
        return '';
    }

}
