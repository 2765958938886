import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog'
import { Subscription } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

import { TermsModuleComponent } from '@app-components'
import { PartnerVehicle } from '@app-graphql'
import { CheckoutService, ContentService, ListingService } from '@app-services'

@Component({
    selector: 'app-car-detail-form',
    templateUrl: './car-detail-form.component.html',
    styleUrls: ['./car-detail-form.component.scss'],
    providers: [DialogService],
})
export class CarDetailFormComponent implements OnInit, OnDestroy {

    @Input()
    public car: Partial<PartnerVehicle>

    public carId: string
    public partnerLocationId: string

    public vatIncluded = false
    public price: string
    public kmPrice: string
    public termAndConditionFile: string
    public leadTime: string
    public ref: DynamicDialogRef
    public subscription: Subscription

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private checkoutService: CheckoutService,
        private translate: TranslateService,
        private listingService: ListingService,
        private contentService: ContentService,
        public dialogService: DialogService,
    ) {
    }

    public ngOnInit(): void {
        this.subscription = this.listingService.state.selectedVehicle.pipe(
            map((selectedVehicle) => selectedVehicle.data.partnerVehicleById?.rentalPartner?.id as string),
            mergeMap((id) => this.contentService.getTermAndCondition('rental_partner_terms_and_conditions', id)),
        ).subscribe((res) => {
            this.termAndConditionFile = res.data.TermsAndConditions?.file as string
        })
        this.vatIncluded = this.listingService.state.showIncVat.getValue()

        this.activatedRoute.params.subscribe((params) => {
            this.carId = params.id
            this.partnerLocationId = params.partnerLocationId
        })

        this.price = this.car.priceOnInterval?.toFixed(2) as string
        this.kmPrice = this.car.priceExtraKmPerDay?.toFixed(2) as string
        this.leadTime = this.car.leadtime as string
    }

    public ngOnDestroy(): void {
        this.ref?.close()
        this.subscription?.unsubscribe()
    }

    public show(): void {
        this.ref = this.dialogService.open(TermsModuleComponent, {
            data: {},
            header: this.translate.instant('conditions.title'),
            width: '70%',
            contentStyle: { height: '70vh', overflow: 'auto' },
            baseZIndex: 10000,
        })
    }

    public setVatIncluded(showIncVat: boolean): void {
        this.listingService.state.showIncVat.next(showIncVat)
    }

    public async handleSubmit(): Promise<void> {
        this.checkoutService.setRentalValue({ name: 'partnerVehicleId', value: this.carId })
        this.checkoutService.setRentalValue({ name: 'partnerLocationId', value: this.partnerLocationId })

        // Driving animation (desktop only)
        if (window.innerWidth > 990) {
            const carImage = document.querySelector('.car-detail-header__car-delivery-image')
            carImage.classList.add('drive')
            setTimeout(async () => this.router.navigate(['/checkout/extras']), 2000)
            setTimeout(() => carImage.classList.remove('drive'), 3000)

        } else {
            await this.router.navigate(['/checkout/extras'])
        }

    }

}
