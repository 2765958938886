import { Component, Input } from '@angular/core';

import { HowDoesItWorkPage } from '@app-graphql';

@Component({
    selector: 'app-about-card',
    templateUrl: './about-card.component.html',
    styleUrls: ['./about-card.component.scss'],
})
export class AboutCardComponent {

    @Input()
    public page: HowDoesItWorkPage;

}
