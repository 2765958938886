import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { AuthService } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {

    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
    ) {
    }

    public async canActivate(): Promise<boolean> {
        const isAuthenticated = await this.authService.isAuthenticated()
        if (! isAuthenticated && ! this.authService.authenticationInProgress) {
            await this.router.navigateByUrl('/auth/login', { replaceUrl: true })
            return false
        }

        return true
    }

}
