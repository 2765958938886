import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-checkout-order-processed',
    templateUrl: './checkout-order-processed.component.html',
    styleUrls: ['./checkout-order-processed.component.scss'],
})
export class CheckoutOrderProcessedComponent {

    constructor(
        private router: Router,
    ) {
        // Reset filters
        window.sessionStorage.removeItem('local_FilterService_filterParams');
    }

    public async backToHomepage(): Promise<void> {
        await this.router.navigate(['/']);
    }

}
