import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import moment from 'moment'

import { CarVehiclePackageSelectionComponent } from '@app-components/car'
import { FilterOption, HomePage } from '@app-graphql'
import { CheckoutService, FilterGroups, FilterService, GeolocationService } from '@app-services'

const CAR_IMAGE_COUNT = 4 // Number of car images inside `assets/img/cars/`

@Component({
    selector: 'app-home-filter-header',
    templateUrl: './home-filter-header.component.html',
    styleUrls: ['./home-filter-header.component.scss'],
})
export class HomeFilterHeaderComponent implements AfterViewInit, OnInit {

    @Input()
    public page: HomePage

    @ViewChild('vehiclePackageSelectionComponent')
    public vehiclePackageSelectionComponent: CarVehiclePackageSelectionComponent

    @ViewChild('selectedVehiclePackagesDummyElement', { read: ElementRef })
    public selectedVehiclePackagesDummyElement: ElementRef<HTMLInputElement>

    public filterStoreParams = this.filterService.getFilter().getValue()
    public geoLocationIsLoading = this.geolocationService.state.loading
    public filterGroups: FilterGroups
    public formGroup: FormGroup
    public minDateTimeFrom: Date
    public minDateTimeTo: Date
    public randomCarImage = `/assets/img/cars/car${Math.ceil(Math.random() * CAR_IMAGE_COUNT)}.png?v=2`

    public isLoading = false

    constructor(
        private router: Router,
        private filterService: FilterService,
        private geolocationService: GeolocationService,
        private checkoutService: CheckoutService,
        private formBuilder: FormBuilder,
    ) {
    }

    public ngOnInit(): void {
        this.isLoading = true

        const momentObj = moment()
        const minDateTimeFrom = momentObj.add(2, 'hours').toDate()
        minDateTimeFrom.setMinutes(minDateTimeFrom.getMinutes() + 5)
        this.minDateTimeFrom = minDateTimeFrom

        const minDateTimeTo = moment(minDateTimeFrom).add(1, 'hours').toDate()
        this.minDateTimeTo = minDateTimeTo

        this.filterService.getFilterGroups()

        this.filterService.state.filterGroups.subscribe((res) => {
            this.filterGroups = res

            // Check if the object is not empty
            if (Object.keys(this.filterGroups).length > 0) {
                this.isLoading = false
            }

            setTimeout(() => this.updateVehicleTypeFromMultiSelect(), 100)
        })

        this.formGroup = this.formBuilder.group({
            dateTimeFrom: [minDateTimeFrom, Validators.required],
            dateTimeTo: [minDateTimeTo, Validators.required],
            vehicleType: [this.filterStoreParams.vehicleType, Validators.required],
            search: ['', Validators.required],
        })

        this.formGroup.valueChanges.subscribe((formObject) => {
            for (const key in formObject) {
                if ({}.hasOwnProperty.call(formObject, key)) {
                    this.filterService.setFilterValue({ name: key, value: formObject[key] })
                    if (key === 'dateTimeFrom' || key === 'dateTimeTo') {
                        this.checkoutService.setRentalValue({ name: key, value: formObject[key] })
                    }
                }
            }

            this.updateMinDateTimeTo()
        })
    }

    public ngAfterViewInit(): void {
        this.updateVehicleTypeValidityState()
    }

    public getCurrentLocation(): void {
        this.geolocationService.getCurrentLocationByNavigator()
        this.geolocationService.state.address.subscribe((res) => {
            this.formGroup.controls.search.setValue(res)
        })
    }

    public async handleFilterSubmit(): Promise<void> {
        if (! this.formGroup.valid) {
            document.querySelector<HTMLInputElement>('.ng-invalid')?.reportValidity()
            return
        }

        const coords = await this.geolocationService.getGeoCodeSync({
            address: this.formGroup.controls.search.value,
            countryCode: 'nl',
        })
        if (coords.longitude) {
            await this.router.navigate(['filter'])
        }
    }

    public updateVehicleTypeFilterOptions(vehicleTypes: Partial<FilterOption>[]): void {
        this.formGroup.controls.vehicleType
            .setValue(vehicleTypes.map((f: Partial<FilterOption> & { __typename: string }) => ({
                __typename: f.__typename,
                id: f.id,
                label: f.label,
            })))

        this.updateVehicleTypeValidityState()
    }

    public updateVehicleTypeFromMultiSelect(): void {
        if (! this.formGroup) {
            return
        }

        this.vehiclePackageSelectionComponent
            ?.updateVehiclePackageStateByFilterOptions(this.formGroup.controls.vehicleType.value)
    }

    public updateMinDateTimeTo(): void {
        const dateTimeFrom = this.formGroup.controls.dateTimeFrom.value

        if (! dateTimeFrom) {
            return
        }

        const minDateTimeTo = moment(dateTimeFrom).add(1, 'hours').toDate()
        this.minDateTimeTo = minDateTimeTo
        const currentDateTimeTo = this.formGroup.controls.dateTimeTo.value

        if (moment(currentDateTimeTo).isBefore(minDateTimeTo)) {
            this.formGroup.controls.dateTimeTo.setValue(minDateTimeTo)
        }
    }

    public updateVehicleTypeValidityState(): void {
        const element = this.selectedVehiclePackagesDummyElement.nativeElement
        if (this.formGroup.get('vehicleType').value?.length) {
            element.checked = true
            element.setCustomValidity('')
        } else {
            element.checked = false
            element.setCustomValidity(element.dataset.invalidMessage)
        }
    }

}
