<div class="customer-service-form"
     *ngIf="$subjects | async as subjects"
     [formGroup]="formState">

    <label for="subject" class="mb-2">
        {{ 'customerService.formHeader' | translate }}
    </label>

    <p-dropdown formControlName="subject"
                inputId="subject"
                [options]="subjects.data.questions"
                [placeholder]="'customerService.formSubject' | translate"
                optionLabel="text"
                [showClear]="true"></p-dropdown>

    <label for="message" class="mt-3 mb-2">
        {{ 'general.email' | translate }}
    </label>

    <input
        pInputText
        formControlName="email"
    >

    <label for="message" class="mt-3 mb-2">
        {{ 'customerService.formMessage' | translate }}
    </label>

    <textarea
        formControlName="message"
        id="message"
        class="customer-service-form__message"
        pInputTextarea
        [autoResize]="true"
        rows="5"
    ></textarea>

    <div class="text-center">
        <p-button
            *ngIf="!submitting"
            styleClass="p-button-rounded p-button--full-width mt-6"
            [label]="'general.send' | translate"
            [disabled]="!formState.valid"
            (click)="submitForm(formState.valid)"
        ></p-button>

        <div *ngIf="submitting" class="mt-6">
            <p-progressSpinner [style]="{width: '40px', height: '40px'}">
            </p-progressSpinner>
        </div>

        <p *ngIf="responseMessage.length">
            <b>{{ responseMessage }}</b>
        </p>
    </div>

</div>
