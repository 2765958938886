import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';

import { CustomerTypeEnum, FilterOption } from '@app-graphql'
import {
    CheckoutService,
    ListingService,
    FilterGroups,
    FilterParams,
    FilterService,
    GeolocationService,
} from '@app-services';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    providers: [DialogService, ConfirmationService, MessageService],
})
export class FilterComponent implements OnInit, OnDestroy {

    @Output()
    public filterSubmitted = new EventEmitter<void>();

    public filterStoreParams: FilterParams = this.filterService.getFilter().getValue();
    public formState: FormGroup;
    public filterGroups: FilterGroups;
    public minDateTimeFrom: Date;
    public minDateTimeTo: Date;
    public ref: DynamicDialogRef;
    public subscription: Subscription;

    public hasOtherDropOff = false;
    public currentLocation: string;

    public readonly CustomerTypeEnum = CustomerTypeEnum

    constructor(
        public confirmationService: ConfirmationService,
        private fb: FormBuilder,
        private filterService: FilterService,
        private checkoutService: CheckoutService,
        private listingService: ListingService,
        private geolocationService: GeolocationService,
    ) {}

    public ngOnInit(): void {
        const momentObj = moment();
        const minDateTimeFrom = momentObj.add(2, 'hours').toDate();
        minDateTimeFrom.setMinutes(minDateTimeFrom.getMinutes() + 5)
        this.minDateTimeFrom = minDateTimeFrom;

        const minDateTimeTo = moment(minDateTimeFrom).add(1, 'hours').toDate();
        this.minDateTimeTo = minDateTimeTo;

        this.filterService.getFilterGroups();
        this.initFilterGroups();
    }

    public ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    public initFilterGroups(): void {
        this.subscription = this.filterService.state.filterGroups.subscribe((res) => {
            this.filterGroups = res;
            this.initFormState(res);
        });

        this.listingService.state.showIncVat.subscribe((showIncVat) => {
            this.formState?.controls?.vatIncluded?.setValue(showIncVat);
        });
    }

    public initFormState(filterGroups: { Type: FilterOption[]; Aanbieder: FilterOption[]; }): void {
        if (filterGroups.Type) {
            this.currentLocation = this.filterStoreParams.search;
            this.formState = this.fb.group({
                search: [this.filterStoreParams.search, [Validators.required]],
                dropOffOtherLocation: new FormControl(false),
                extraKms: [this.filterStoreParams.extraKms],
                dateTimeFrom: [moment(this.filterStoreParams.dateTimeFrom).toDate(), [Validators.required]],
                dateTimeTo: [moment(this.filterStoreParams.dateTimeTo).toDate(), [Validators.required]],
                vehicleType: [this.filterStoreParams.vehicleType],
                maxPrice: [this.filterStoreParams.maxPrice],
                deliveryMethod: [this.filterStoreParams.deliveryMethod || 'fetch'],
                supplier: [this.filterStoreParams.supplier],
                fuelType: [this.filterStoreParams.fuelType],
                model: [this.filterStoreParams.model],
                transmission: [this.filterStoreParams.transmission],
                vatIncluded: [this.listingService.state.showIncVat.getValue()],
            });

            this.formState.valueChanges.subscribe((formObject) => {
                for (const key in formObject) {
                    if ({}.hasOwnProperty.call(formObject, key)) {
                        this.filterService.setFilterValue({ name: key, value: formObject[key] });
                        if (key === 'dateTimeFrom' || key === 'dateTimeTo') {
                            this.checkoutService.setRentalValue({ name: key, value: formObject[key] });

                            if (key === 'dateTimeFrom') {
                                this.updateMinDateTimeTo(formObject);
                            }

                            if (key === 'dateTimeTo') {
                                this.handleOverlappingDates(formObject);
                            }
                        }
                        if (key === 'dropOffOtherLocation') {
                            this.hasOtherDropOff = formObject[key];
                        }
                    }
                }
                this.filterStoreParams = this.filterService.getFilter().getValue();
            });
        }

        if (this.formState) {
            this.formState.get('dropOffOtherLocation').valueChanges.subscribe((value) => {
                if (value) {
                    this.confirmDropOffLocation();
                }
            });
        }
    }

    public handleOverlappingDates(formObject: any): void {
        const dateTimeFrom = moment(formObject.dateTimeFrom);
        const dateTimeTo = moment(formObject.dateTimeTo);

        if (dateTimeFrom.isSameOrAfter(dateTimeTo)) {
            const newDate = dateTimeFrom.add(1, 'hours').toDate();
            this.checkoutService.setRentalValue({ name: 'dateTimeTo', value: newDate });
            this.filterService.setFilterValue({ name: 'dateTimeTo', value: newDate });
            this.filterStoreParams = this.filterService.getFilter().getValue();
            this.initFilterGroups();
        }
    }

    public updateMinDateTimeTo(formObject: any): void {
        const dateTimeFrom = moment(formObject.dateTimeFrom);
        const minDateTimeTo = dateTimeFrom.add(2, 'hours').toDate();
        this.minDateTimeTo = minDateTimeTo;
        const currentDateTimeTo = moment(this.formState.controls.dateTimeTo.value);

        if (currentDateTimeTo.isBefore(minDateTimeTo)) {
            this.formState.controls.dateTimeTo.setValue(minDateTimeTo);
        }
    }

    public setVatIncluded(showIncVat: boolean): void {
        this.listingService.state.showIncVat.next(showIncVat);
    }

    public resetFilter(): void {
        this.filterService.clearFilterParams();
        this.ngOnInit();
        this.submitFilter();
    }

    public async submitFilter(): Promise<void> {
        if (this.formState.value.search !== this.currentLocation) {
            // console.log('need to geocode because new location');
            this.geolocationService.getGeoCode({
                address: this.filterStoreParams.search,
                countryCode: 'NL',
            });

            this.geolocationService.state.loading.subscribe(async (res) => {
                if (! res) {
                    await this.listingService.getFilteredListing();
                }
            });
        } else {
            // console.log('same location');
            await this.listingService.getFilteredListing();
        }

        this.filterSubmitted.emit();
    }

    public confirmDropOffLocation(): void {
        this.confirmationService.confirm({
            message: 'U wilt een auto inleveren bij een andere verhuurlocatie? Neemt u alstublieft contact met ons op',
            reject: () => {
                this.formState.get('dropOffOtherLocation').setValue(false);
            },
        });
    }
}
