import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, from, Observable, of, tap, throwError } from 'rxjs'
import { catchError, filter, switchMap, take } from 'rxjs/operators'

import { AuthPayload } from '@app-graphql'
import { AuthService } from '@app-services/api'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private refreshTokenInProgress = false
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)

    private static excludedOperationNames: string[]

    constructor(
        private readonly authService: AuthService,
    ) {
    }

    public static withExcludedOperationNames(excludedOperationNames: string[]): typeof AuthInterceptor {
        AuthInterceptor.excludedOperationNames = excludedOperationNames

        return AuthInterceptor
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Check if we should ignore this request
        const operationName = request.body?.operationName
        if (
            ! request.url.startsWith('http')
            || ! operationName
            || AuthInterceptor.excludedOperationNames.includes(operationName)
        ) {
            return next.handle(request)
        }

        // Load persisted login data (token) if it hasn't been loaded yet
        const auth$ = this.authService.getAuthPayload() ? of(null) : from(this.authService.initialize())

        return auth$.pipe(switchMap(() => this.handleRequest(request, next))) as Observable<HttpEvent<any>>
    }

    private handleRequest(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any> | Observable<never>> {

        let interceptedRequest = request

        // Add access token to request if available
        const authPayload = this.authService.getAuthPayload()
        if (authPayload?.accessToken) {
            interceptedRequest = this.setTokenHeader(request, authPayload.accessToken)
        }

        return next.handle(interceptedRequest).pipe(
            tap((response: any | object) => {
                if (
                    response?.body?.errors?.length
                    && response?.body?.errors[0]?.message?.startsWith('Unauthenticated')
                ) {
                    return this.handleInvalidToken(request, next)
                }
            }),
        )
    }

    private setTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } })
    }

    private handleInvalidToken(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any> | Observable<never>> {

        let authPayload: AuthPayload = this.authService.getAuthPayload()

        // A token refresh is already in progress. Don't start another refresh
        if (! this.refreshTokenInProgress) {
            this.refreshTokenInProgress = true
            this.refreshTokenSubject.next(null)

            // Refresh the access token and persist the new login data
            const refreshPromise = this.authService.refreshToken(authPayload.refreshToken)
            return from(refreshPromise).pipe(
                switchMap(() => {
                    authPayload = this.authService.getAuthPayload()
                    this.refreshTokenInProgress = false
                    this.refreshTokenSubject.next(authPayload.accessToken)
                    return next.handle(this.setTokenHeader(request, authPayload.accessToken))
                }),
                catchError(async (error) => {
                    // Log out the user in case the refresh failed
                    await this.authService.logout('/auth/login')

                    return throwError(error)
                }),
            )
        }

        return this.refreshTokenSubject.pipe(
            filter((token) => token !== null),
            take(1),
            switchMap(() => next.handle(this.setTokenHeader(request, authPayload.accessToken))),
        )
    }

}
