<ng-container *ngIf="ready; else loader">
    <app-header></app-header>

    <main>
        <router-outlet *ngIf="serviceProvider; else serviceProviderNotFound"></router-outlet>

        <ng-template #serviceProviderNotFound>
            <div class="outer-container">
                <h2 class="mt-2">404 - Pagina niet gevonden.</h2>
                <p>Er is geen aanbieder gevonden onder de naam '{{ previousUrl ?? 'Onbekende aanbieder url' }}'</p>
                <a href="/">Ga naar onze algemene website</a>
            </div>
        </ng-template>

    </main>

    <app-footer></app-footer>
</ng-container>

<ng-template #loader>
    <div class="loader">
        <p-progressSpinner></p-progressSpinner>
    </div>
</ng-template>
