import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { FilterService } from '@app-services';

@Injectable({
    providedIn: 'root',
})
export class FilterGuard implements CanActivate {

    constructor(
        private filterService: FilterService,
        private router: Router,
    ) {

    }

    canActivate(
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const filter = this.filterService.getFilter().getValue();
        if (
            filter.dateTimeTo
            && filter.dateTimeFrom
        ) {
            return true;
        }
        this.router.navigate(['/']);
        return false;

    }

}
