import { NgModule } from '@angular/core';

import { WINDOW_PROVIDER } from '@app-services/window/window.provider';
import { LocalStorageService } from '@app-services/localstorage/localstorage.service';
import { WindowService } from '@app-services/window/window.service';

@NgModule({
    providers: [
        WINDOW_PROVIDER,
        LocalStorageService,
        WindowService,
    ],
})
export class ServicesModule {}
