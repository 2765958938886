<div class="confirmation-card">
    <div class="confirmation-card__title">
        <h1>{{ "orderConfirmation.somethingWrong" | translate }}</h1>
    </div>
    <p class="sub-title mb-6">{{ "orderConfirmation.preferences" | translate }}</p>

    <p-button
        (click)="retryRentalProcess()"
        type="button"
        [label]="'orderConfirmation.tryAgain' | translate"
        styleClass="p-button-rounded p-button-primary p-button--full-width mb-6" />
    <p class="sub-title">{{ "orderConfirmation.contactUs" | translate }}.</p>
    <a href="tel:0854897755" class="contact-details__content__attribute">
        <i class="pi pi-phone"></i>
        <p class="contact-details__content__attribute__content">088 - 934 34 26</p>
    </a>
</div>
