<div class="order-info-card" *ngIf="! isLoading; else loading">

    <ng-container *ngIf="hasData; else empty">
        <div class="order-info-card__title">
            <h5 class="mb-0">{{ title | translate }}</h5>
        </div>

        <div class="order-info-card__image__container">
            <img class="order-info-card__image"
                 [src]="car.vehicleAttribute?.image"
                 alt=""
            />
        </div>

        <!-- Car information -->
        <p class="order-info-card__attribute">
            <span class="order-info-card__attribute__icon">
                <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                        <path d="M0 0h32v32H0z" />
                        <path d="M19.04 9a2 2 0 0 1 1.746 1.027L23 14h2v1.5h-1.026a2 2 0 0 1 .026.323V20a1 1 0 0 1-1 1v.5a1.5 1.5 0 0 1-3 0V21h-7v.5a1.5 1.5 0 0 1-3 0V21a1 1 0 0 1-1-1v-4.187a2 2 0 0 1 .025-.313H8V14h2l2.214-3.973A2 2 0 0 1 13.96 9h5.078ZM21 16a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm6.685-6h-4.37a1.76 1.76 0 0 0-1.426.73l-.085.128L10.93 14H22.07l-1.875-3.142a1.76 1.76 0 0 0-1.358-.851L18.685 10Z"
                              fill="#1D1C2D" />
                    </g>
                </svg>
            </span>
            <span>
                <b>{{ car.vehicleMake.name }} {{ car.vehicleModel.name }}</b><br>
                <span class="order-info-card__attribute__note">
                    {{ 'orderDelivery.orSimilar' | translate }}
                </span>
            </span>
        </p>

        <!-- Partner location -->
        <p class="order-info-card__attribute">
            <span class="order-info-card__attribute__icon">
                <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                        <path d="M0 0h32v32H0z" />
                        <path d="M16 7c3.314 0 6 2.75 6 6.144a6.25 6.25 0 0 1-.5 2.462h.003l-.046.096c-.05.113-.105.225-.162.334l-4.02 8.273a1.2 1.2 0 0 1-2.071.152l-.068-.112-4.587-8.604h.014a6.244 6.244 0 0 1-.563-2.6C10 9.75 12.686 7 16 7Zm0 3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                              fill="#1D1C2D" />
                    </g>
                </svg>
            </span>
            <span>
                <b>{{ partnerLocation?.location?.name }}</b>
                <br>
                {{ partnerLocation?.location?.streetName }} {{ partnerLocation?.location?.streetNumber }}
                <br>
                {{ partnerLocation?.location?.postalCode }} {{ partnerLocation?.location?.city }}
            </span>
        </p>

        <p class="order-info-card__attribute">
            <span class="order-info-card__attribute__icon">
                <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                        <path d="M0 0h32v32H0z" />
                        <path d="M19.674 11.147 9.205 15.003a1.2 1.2 0 0 0 .126 2.291l4.005.994a1.2 1.2 0 0 1 .876.876l.974 3.925a1.2 1.2 0 0 0 2.295.112l3.738-10.526a1.2 1.2 0 0 0-1.545-1.528Z"
                              fill="#1D1C2D" />
                    </g>
                </svg>
            </span>
            <span>
                <b>
                    {{ car.partnerLocation?.distance | number : '1.2-2' }} km
                </b> {{ 'carInfo.distanceToLocation' | translate }}
            </span>
        </p>

        <!-- Rental dropoff location -->
        <p class="order-info-card__attribute" *ngIf="rentalItem.streetName">
             <span class="order-info-card__attribute__icon">
                 <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                     <g fill="none" fill-rule="evenodd">
                         <path d="M0 0h32v32H0z" />
                         <path d="M16 7c3.314 0 6 2.75 6 6.144a6.25 6.25 0 0 1-.5 2.462h.003l-.046.096c-.05.113-.105.225-.162.334l-4.02 8.273a1.2 1.2 0 0 1-2.071.152l-.068-.112-4.587-8.604h.014a6.244 6.244 0 0 1-.563-2.6C10 9.75 12.686 7 16 7Zm0 3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                               fill="#1D1C2D" />
                     </g>
                 </svg>
            </span>
            {{ rentalItem.streetName }} {{ rentalItem.streetNumber }} {{ rentalItem.postalCode }} {{ rentalItem.city }}
            <span class="order-info-card__attribute__note">{{ 'orderDelivery.delivered' | translate }}</span>
        </p>

        <!-- Rental period -->
        <p class="order-info-card__attribute" *ngIf="rentalItem.dateTimeFrom && rentalItem.dateTimeTo">
            <span class="order-info-card__attribute__icon">
                <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                        <path d="M0 0h32v32H0z" />
                        <path d="M20.75 9a2.75 2.75 0 0 1 2.75 2.75v8a2.75 2.75 0 0 1-2.75 2.75h-9A2.75 2.75 0 0 1 9 19.75v-8A2.75 2.75 0 0 1 11.75 9ZM22 12.75H10.5v7c0 .647.492 1.18 1.122 1.244l.128.006h9c.69 0 1.25-.56 1.25-1.25v-7Z"
                              fill="#1D1C2D"
                              fill-rule="nonzero" />
                    </g>
                </svg>
            </span>
            <span>
                <span class="flex flex-wrap">
                    <span class="mb-2">
                        {{ rentalItem.dateTimeFrom | date : 'E d MMMM yyyy - HH:mm' : 'nl-BE' }}
                    </span>
                    &nbsp;-&nbsp;
                    <span>
                      {{ rentalItem.dateTimeTo | date : 'E d MMMM yyyy - HH:mm' }}
                    </span>
                </span>
                <span class="mt-2 block" *ngIf="rentalItem.dateTimeFrom && rentalItem.dateTimeTo">
                    Totaal aantal dagen
                    <b>{{ rentalItem.dateTimeFrom | calculateDurationDays : rentalItem.dateTimeTo }}</b>
                </span>
            </span>
        </p>

        <!-- Rental price -->
        <p class="order-info-card__attribute">
            <span class="order-info-card__attribute__icon">
                <img src="/assets/img/icons/check.png" alt="check-icon" />
            </span>
            <span>
                <b>
                    <ng-container *ngIf="! car.discountPercentage; else discountedPrice">
                        {{ car.priceOnInterval | vat | currency : 'EUR' }} +&nbsp;
                    </ng-container>
                    <ng-template #discountedPrice>
                        <span class="price-original">{{ car.originalPriceOnInterval | vat | currency : 'EUR' }}</span>
                        <div class="price-discount-badge">-{{ car.discountPercentage }}%</div>
                        <br>
                        <span class="price-discounted">{{ car.priceOnInterval | vat | currency : 'EUR' }}</span>
                        +
                    </ng-template>
                    {{ car.priceExtraKmPerDay | vat | currency : 'EUR' }}/km
                </b>
                <br>
                <span class="order-info-card__attribute__note">
                    incl. {{ car.freeKmsPerDay }} km
                    {{ 'general.perDay' | translate }}
                </span>
            </span>
        </p>

        <!-- Rental extra's -->
        <p class="order-info-card__attribute" *ngFor="let extra of extrasByName | async">
            <span class="order-info-card__attribute__icon">
                <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                        <path d="M0 0h32v32H0z" />
                        <path d="m17.33 12-.001 3.999L21 16v1.2l-3.671-.001V21H16v-3.801l-4 .001V16l4-.001V12h1.33Z"
                              fill="#1D1C2D" />
                    </g>
                </svg>
            </span>
            <span>
                {{ extra.name }}:
                <span *ngIf="extra.rates[0]!.oneTimeFee">
                    <br>
                    <b>{{ extra.rates[0]!.oneTimeFee | vat | currency : 'EUR' }}</b>
                    <small>
                        {{ 'extras.one_time_fee' | translate }}
                    </small>
                </span>
                <span *ngIf="extra.rates[0]!.pricePerDay">
                    <br>
                    <b>{{ extra.rates[0]!.pricePerDay | vat | currency : 'EUR' }}</b>
                    <small>
                        {{ 'extras.price_per_day' | translate }}
                    </small>
                    <span *ngIf="rentalItem.dateTimeFrom && rentalItem.dateTimeTo">
                        (<b>{{
                            (rentalItem.dateTimeFrom | calculateDurationDays: rentalItem.dateTimeTo)
                                * extra.rates[0]!.pricePerDay | vat | currency : 'EUR'
                        }}</b>
                        totaal)
                    </span>
                </span>
                <span *ngIf="! extra.rates[0]!.oneTimeFee && ! extra.rates[0]!.pricePerDay"><b>Inclusief</b></span>
            </span>
        </p>

        <!-- Total -->
        <h5 class="mt-4">
            Totaal: <b>{{ (price + extrasPerDayTotalPrice + extrasOneTimeTotalPrice) | vat | currency : 'EUR' }}</b>
        </h5>

        <div class="order-info-card__attribute__note">

            <p>
                {{ 'filterPage.VAT' | translate }}
            </p>
            <div class="mb-4">
                <p-inputSwitch (onChange)="setVatIncluded($event.checked)"
                               [(ngModel)]="showIncVat"
                ></p-inputSwitch>
            </div>
        </div>

        <ng-content></ng-content>
    </ng-container>
</div>

<ng-template #empty>
    <ng-content select="[slot='empty']"></ng-content>
</ng-template>

<ng-template #loading>
    <div class="flex align-items-center">
        <p-progressSpinner [style]="{ width: '40px', height: '40px' }"></p-progressSpinner>
        <p class="ml-2">{{ 'general.loading' | translate }}</p>
    </div>
</ng-template>
