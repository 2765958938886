import { Injectable } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'
import { filter, map } from 'rxjs/operators'

import { MobilityPartner as ServiceProvider } from '@app-graphql'
import { DomainService } from '@app-services/domain/domain.service'

@Injectable({
    providedIn: 'root',
})
export class TagManagerService {

    private initialPageViewTracked = false
    private serviceProvider: ServiceProvider

    public constructor(
        private readonly domainService: DomainService,
        private readonly router: Router,
    ) {
        this.domainService.getServiceProvider().then((serviceProvider) => {
            if (serviceProvider) {
                this.serviceProvider = serviceProvider
                this.initialize()
            }
        })
    }

    private initialize(): void {
        if (this.serviceProvider.tmHeadScript) {
            this.initializeContainerTag()
        }

        if (! this.serviceProvider.tmPageViewJs) {
            return
        }

        // Track pageviews
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event) => event as NavigationEnd),
            )
            .subscribe((e: NavigationEnd) => {
                if (this.serviceProvider && e.url) {
                    this.trackPageView(e.url.substring(1))
                }
            })

        // Track initial pageview
        if (! this.initialPageViewTracked) {
            this.trackPageView(this.router.url?.substring(1))
        }
    }

    public initializeContainerTag(): void {
        const dummyElement = document.createElement('div')
        dummyElement.innerHTML = this.serviceProvider.tmHeadScript

        // Get script tags and clone them into the document head
        dummyElement.querySelectorAll<HTMLScriptElement>('script').forEach((scriptElement) => {
            const clonedScriptElement = document.createElement('script')
            clonedScriptElement.type = scriptElement.type || 'text/javascript'
            if (scriptElement.src) {
                clonedScriptElement.src = scriptElement.src
            }
            if (scriptElement.text) {
                clonedScriptElement.text = scriptElement.text
            }
            document.head.appendChild(clonedScriptElement)
        })
    }

    public trackPageView(uri: string) {
        if (! this.initialPageViewTracked) {
            this.initialPageViewTracked = true
        }

        if (this.serviceProvider) {
            const parsedPageViewCommand = this.serviceProvider.tmPageViewJs
                .replace('{{url}}', window.location.href)
                .replace('{{route}}', uri || 'home')
                .replace('{{page}}', uri.split('/').pop() || 'home')
            eval(parsedPageViewCommand)
        }
    }

}
