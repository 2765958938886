<div class="car-info-card skeleton-card">
    <article class="grid">

        <div class="car-info-card__image__container col-12 sm:col-6">
            <div class="p-skeleton p-mb-2" style="width:100%;height:256px;">
                <div></div>
            </div>
        </div>

        <div class="col-12 sm:col-6">
            <div class="p-skeleton p-mb-2 mb-1" style="width:100%;height:32px;">
                <div></div>
            </div>
            <div class="p-skeleton mt-2 mb-4" style="width:80%;height:20px;">
                <div></div>
            </div>

            <div class="p-skeleton__attribute mt-2 mb-1">
                <div class="p-skeleton" style="width:10%;height:20px;">
                    <div></div>
                </div>
                <div class="p-skeleton" style="width:70%;height:20px;">
                    <div></div>
                </div>
            </div>
            <div class="p-skeleton__attribute mt-2 mb-1">
                <div class="p-skeleton" style="width:10%;height:20px;">
                    <div></div>
                </div>
                <div class="p-skeleton" style="width:70%;height:20px;">
                    <div></div>
                </div>
            </div>
            <div class="p-skeleton__attribute mt-2 mb-1">
                <div class="p-skeleton" style="width:10%;height:20px;">
                    <div></div>
                </div>
                <div class="p-skeleton" style="width:70%;height:20px;">
                    <div></div>
                </div>
            </div>
            <div class="p-skeleton__attribute mt-2 mb-4">
                <div class="p-skeleton" style="width:10%;height:20px;">
                    <div></div>
                </div>
                <div class="p-skeleton" style="width:70%;height:20px;">
                    <div></div>
                </div>
            </div>

            <div class="p-skeleton__buttons mt-2">
                <div class="p-skeleton" style="width:45%;height:30px;">
                    <div></div>
                </div>
                <div class="p-skeleton" style="width:45%;height:30px;">
                    <div></div>
                </div>
            </div>
        </div>

    </article>

</div>
