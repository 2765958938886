import localeNl from '@angular/common/locales/nl'
import { LOCALE_ID, NgModule } from '@angular/core'
import { registerLocaleData } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HttpClientModule, HttpClient } from '@angular/common/http'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import lottiePlayer from 'lottie-web'

import { TemplateComponentsModule } from '@app-components/template/template-components.module'
import { GraphQLModule } from '@app-modules/graphql.module'
import { ServicesModule } from '@app-services/services.module'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { LottieModule } from 'ngx-lottie'

registerLocaleData(localeNl, 'nl-NL')

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http)
}

export function lottiePlayerFactory() {
    return lottiePlayer
}

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        LottieModule.forRoot({ player: lottiePlayerFactory }),
        GraphQLModule,
        HttpClientModule,
        ProgressSpinnerModule,
        ServicesModule,
        TemplateComponentsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'nl',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'nl-NL' },
    ],
})
export class AppModule {
}
