<div class="order-car-cta" [formGroup]="formState">

    <h1 class="order-car-cta__title">{{ 'callToAction.title' | translate }}</h1>

    <div class="order-car-cta__search__container">

        <div class="order-car-cta__search mb-2">
            <span class="p-input-icon-left ">
                <i class="pi pi-search"></i>
                <input class="order-car-cta__search"
                       type="text"
                       formControlName="search"
                       pInputText
                       [placeholder]="'callToAction.placeholder' | translate"/>
            </span>
            <p-button
                (click)="filterBySearchLocation()"
                styleClass="p-button-small p-button-secondary p-button-no-border-radiusmb-2"
                [label]="'callToAction.searchBtn' | translate">
            </p-button>

        </div>

        <p-button icon="pi pi-map-marker"
                  (click)="filterByCurrentLocation()"
                  class="btn-myLocation"
                  styleClass="p-button-small p-button-secondary p-button-no-border-radius"
                  [label]="'callToAction.myLocation' | translate">
        </p-button>

    </div>

</div>
