import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

    public year: string;

    public ngOnInit(): void {
        this.year = String(new Date().getFullYear());
    }

    public goToLink(url: string) {
        window.open(url, '_blank');
    }

}
