import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'

import { CustomerTypeEnum, User } from '@app-graphql'
import { CheckoutService } from '@app-services'

@Component({
    selector: 'app-checkout-customer-data-card',
    templateUrl: './checkout-customer-data-card.component.html',
    styleUrls: ['./checkout-customer-data-card.component.scss'],
})
export class CheckoutCustomerDataCardComponent implements OnInit {

    @Input()
    public user: Partial<User>

    @Input()
    public needsRegistrationToRent: boolean

    public form: FormGroup

    public customerStateData = this.checkoutService.getStateCustomer().getValue()
    public driverStateData = this.checkoutService.getStateRental().getValue()?.driver

    protected readonly CustomerTypeEnum = CustomerTypeEnum

    constructor(
        private readonly checkoutService: CheckoutService,
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
    ) {
    }

    public ngOnInit(): void {
        if (this.user) {
            this.customerStateData = {
                firstName: this.user.userProfile.firstName,
                surName: this.user.userProfile.surName,
                email: this.user.email,
                phone: this.user.userProfile.phone,
                birthdate: this.user.userProfile.birthdate,
                streetName: this.user.userProfile.address?.streetname,
                streetNumber: this.user.userProfile.address?.streetnumber,
                streetNumberSuffix: this.user.userProfile.address?.streetnumberSuffix,
                postalCode: this.user.userProfile.address?.postalcode,
                city: this.user.userProfile.address?.city,
                country: this.user.userProfile.address?.country,
            }
        }

        this.initFormState()
        this.initFormStateListeners()
        this.checkoutService.state.stepIsAccessible.personalInformation.next(false)
    }

    public initFormState(): void {
        let customerControls: any = {
            firstName: new FormControl(this.customerStateData?.firstName, Validators.required),
            surName: new FormControl(this.customerStateData?.surName, Validators.required),
            email: new FormControl(this.customerStateData?.email, [Validators.required, Validators.email]),
            phone: new FormControl(this.customerStateData?.phone, [Validators.required, Validators.minLength(5)]),
            licenseNumber: new FormControl(''),
            haslicense: new FormControl(false, Validators.requiredTrue),
            otherPersonIsDriver: new FormControl(false),
        }

        if (this.needsRegistrationToRent) {
            customerControls = {
                ...customerControls,
                birthdate: new FormControl(this.customerStateData?.birthdate, [Validators.required]),
                streetName: new FormControl(this.customerStateData?.streetName, [Validators.required]),
                streetNumber: new FormControl(this.customerStateData?.streetNumber, [Validators.required]),
                streetNumberSuffix: new FormControl(this.customerStateData?.streetNumberSuffix),
                postalCode: new FormControl(this.customerStateData?.postalCode, [Validators.required]),
                city: new FormControl(this.customerStateData?.city, [Validators.required]),
                country: new FormControl(this.customerStateData?.country, [Validators.required]),
            }
        }

        this.form = this.formBuilder.group({
            customer: this.formBuilder.group(customerControls),
            driver: this.formBuilder.group({
                firstName: new FormControl({
                    value: this.driverStateData?.firstName,
                    disabled: true,
                }, Validators.required),
                lastName: new FormControl({
                    value: this.driverStateData?.lastName,
                    disabled: true,
                }, Validators.required),
                email: new FormControl({
                    value: this.driverStateData?.email,
                    disabled: true,
                }, [Validators.required, Validators.email]),
                phone: new FormControl({
                    value: this.driverStateData?.phone,
                    disabled: true,
                }, [Validators.required, Validators.minLength(5)]),
                haslicense: new FormControl({
                    value: false,
                    disabled: true,
                }, Validators.requiredTrue),
                invoiceReference: new FormControl({
                    value: this.driverStateData?.invoiceReference,
                    disabled: true,
                }),
            }),
        })
    }

    public setDriverEnabled(isEnabled: boolean): void {
        this.form.get(['customer', 'haslicense']).setValue(isEnabled)
        this.form.get(['driver', 'haslicense']).setValue(! isEnabled)

        const driverFormGroup = this.form.get('driver') as FormGroup
        Object.values(driverFormGroup.controls).forEach((control) => {
            if (isEnabled) {
                control.enable()
            } else {
                control.disable()
            }
        })
    }

    public initFormStateListeners(): void {
        this.form.valueChanges.subscribe((formState) => {
            for (const [key, value] of Object.entries(formState.customer)) {
                this.checkoutService.setCustomerValue({ name: key, value })
            }
            if (formState.customer.otherPersonIsDriver) {
                this.checkoutService.setDriver({
                    firstName: formState.driver?.firstName,
                    lastName: formState.driver?.lastName,
                    email: formState.driver?.email,
                    phone: formState.driver?.phone,
                    invoiceReference: formState.driver?.invoiceReference,
                })
            } else {
                this.checkoutService.setDriver(null)
            }
        })
    }

    public handleFormSubmit(formIsValid: boolean): void {
        if (! formIsValid) {
            document.querySelector<HTMLInputElement>('.ng-invalid')?.reportValidity()
            return
        }

        if (this.form.valid) {
            this.router.navigate(['/checkout/final-check'])
        }
    }
}
