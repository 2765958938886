import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ApolloQueryResult } from '@apollo/client/core'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { PartnerLocation, PartnerVehicle, PartnerVehicleByIdQuery } from '@app-graphql'
import { CheckoutService, FilterService, ListingService } from '@app-services'

interface ButtonOption {
    name: string,
    value: string
}

@Component({
    selector: 'app-checkout-delivery-form-card',
    templateUrl: './checkout-delivery-form-card.component.html',
    styleUrls: ['./checkout-delivery-form-card.component.scss'],
})
export class CheckoutDeliveryFormCardComponent implements OnInit {

    public formDelivery: FormGroup
    public formPickup: FormGroup
    public rentalStateData = this.checkoutService.getStateRental().getValue()
    public selectedVehicle: Observable<ApolloQueryResult<PartnerVehicleByIdQuery>>
    public car: Observable<Partial<PartnerVehicle>>
    public partnerLocation: PartnerLocation

    /* TODO: get delivery or pickup from filter options */
    public activeTab = 'delivery'

    public pickupTime = { time: '12:00' }

    public addressPrivate = true
    public isReceiver = true

    public minDate: any
    public maxDate: any

    public deliveryTimes: Array<string> = [
        '08:00 - 10:00',
        '10:00 - 12:00',
        '12:00 - 14:00',
        '14:00 - 16:00',
        '16:00 - 18:00',
    ]

    public addressTypes: Array<ButtonOption> = [
        { name: 'Privé adres', value: 'private' },
        { name: 'Zakelijk adres', value: 'bussiness' },
    ]

    public receivers: Array<ButtonOption> = [
        { name: 'Ik zelf', value: 'self' },
        { name: 'Iemand anders', value: 'other' },
    ]

    constructor(
        private fb: FormBuilder,
        private translate: TranslateService,
        private filterService: FilterService,
        private checkoutService: CheckoutService,
        private listingService: ListingService,
    ) {
    }

    public ngOnInit(): void {
        this.handleOpenedTab()
        this.addressTypes = [
            { name: this.translate.instant('delivery.privateAddress'), value: 'private' },
            { name: this.translate.instant('delivery.businessAddress'), value: 'bussiness' },
        ]
        this.receivers = [
            { name: this.translate.instant('delivery.pickupSelf'), value: 'self' },
            { name: this.translate.instant('delivery.pickupOther'), value: 'other' },
        ]

        const carId = this.checkoutService.state.rental.getValue().partnerVehicleId
        const locationId = this.checkoutService.state.rental.getValue().partnerLocationId
        this.car = this.listingService.getDetail(carId, locationId).pipe(
            map((res) => res.data.partnerVehicleById),
        ) as unknown as Observable<Partial<PartnerVehicle>>

        this.car.subscribe((res) => {
            const foundLocation = res.rentalPartner?.partnerLocations.filter((location) => location?.id === locationId)
            this.partnerLocation = foundLocation[0] as unknown as PartnerLocation
        })

        this.translate.get('general.addressPrivate').subscribe(() => {
            this.addressTypes[0].name = this.translate.instant('general.addressPrivate')
            this.addressTypes[1].name = this.translate.instant('general.addressBusiness')
            this.receivers[0].name = this.translate.instant('general.receiverSelf')
            this.receivers[1].name = this.translate.instant('general.receiverOther')
        })

        this.formDelivery = this.fb.group({
            addressType: ['private'],
            companyName: [''],
            streetName: [this.rentalStateData.streetName, Validators.required],
            streetNumber: [this.rentalStateData.streetNumber, Validators.required],
            postalCode: [this.rentalStateData.postalCode, Validators.required],
            city: [this.rentalStateData.city, Validators.required],
            receiver: ['self'],
            contactPerson: this.fb.group({
                function: [this.rentalStateData.contactPerson.function],
                firstName: [this.rentalStateData.contactPerson.firstName],
                surName: [this.rentalStateData.contactPerson.surName],
                email: [this.rentalStateData.contactPerson.email],
                phone: [this.rentalStateData.contactPerson.phone],
            }),
            deliveryTime: ['08:00 - 10:00'],
        })

        this.formDelivery.get('addressType')?.valueChanges.subscribe((ctrl) => {
            this.addressPrivate = ctrl === 'private'
            if (this.addressPrivate) {
                this.formDelivery.get('companyName')?.clearValidators()
            } else {
                this.formDelivery.get('companyName')?.addValidators(Validators.required)
            }
        })

        this.formDelivery.get('receiver')?.valueChanges.subscribe((ctrl) => {
            this.isReceiver = ctrl === 'self'
            if (this.isReceiver) {
                this.formDelivery.get('function')?.clearValidators()
                this.formDelivery.get('firstName')?.clearValidators()
                this.formDelivery.get('surName')?.clearValidators()
                this.formDelivery.get('email')?.clearValidators()
                this.formDelivery.get('phone')?.clearValidators()
            } else {
                this.formDelivery.get('function')?.addValidators(Validators.required)
                this.formDelivery.get('firstName')?.addValidators(Validators.required)
                this.formDelivery.get('surName')?.addValidators(Validators.required)
                this.formDelivery.get('email')?.addValidators([Validators.required, Validators.email])
                this.formDelivery.get('phone')?.addValidators(Validators.required)
            }
        })

        this.formPickup = this.fb.group({
            pickupTime: [''],
        })
        this.initFormDeliveryListeners()
    }

    public initFormDeliveryListeners(): void {
        this.formDelivery.valueChanges.subscribe((form) => {
            for (const [key, value] of Object.entries(form)) {
                const rentalValueInput = { name: key, value }
                // console.log('rentalValueInput', rentalValueInput);
                this.checkoutService.setRentalValue(rentalValueInput)
            }
        })
    }

    public handleOpenedTab(): void {
        // Check if user has set a deliveryMethod and open that corresponding tab,
        const filterParams = this.filterService.getFilter().getValue()
        if (filterParams.deliveryMethod) {
            this.activeTab = filterParams.deliveryMethod
        }
    }

}
