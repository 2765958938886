import { Component, Input } from '@angular/core'

import { PartnerVehicle } from '@app-graphql'

@Component({
    selector: 'app-car-detail-header',
    templateUrl: './car-detail-header.component.html',
    styleUrls: ['./car-detail-header.component.scss'],
})
export class CarDetailHeaderComponent {

    @Input()
    public car: Partial<PartnerVehicle>

}
