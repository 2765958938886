import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { PrimeNGConfig } from 'primeng/api'
import { firstValueFrom } from 'rxjs'
import { take } from 'rxjs/operators'

import { MobilityPartner as ServiceProvider } from '@app-graphql'
import { DomainService, LanguageService, TagManagerService, ThemeService } from '@app-services'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

    public ready = false
    public serviceProvider: ServiceProvider
    public previousUrl: string

    constructor(
        private readonly domainService: DomainService,
        private readonly languageService: LanguageService,
        private readonly primeNgConfig: PrimeNGConfig,
        private readonly tagManagerService: TagManagerService,
        private readonly themeService: ThemeService,
        private readonly translateService: TranslateService,
    ) {
    }

    public async ngOnInit(): Promise<void> {
        await this.setLocale()
        await this.initializeServiceProvider()
        this.ready = true
    }

    private async setLocale(): Promise<void> {
        localStorage.setItem('language', 'nl')
        this.languageService.setLanguage('nl')

        const primeNgTranslations = await firstValueFrom(this.translateService.get('primeng').pipe(take(1)))
        this.primeNgConfig.setTranslation(primeNgTranslations)
    }

    private async initializeServiceProvider(): Promise<void> {
        this.serviceProvider = await this.domainService.getServiceProvider()
        this.previousUrl = location.host.split('.')[0].toString()

        if (this.serviceProvider) {
            this.themeService.defineThemeColor({ name: 'primary', hexValue: this.serviceProvider.colorOne })
            this.themeService.defineThemeColor({ name: 'secondary', hexValue: this.serviceProvider.colorTwo })
        } else {
            this.themeService.defineThemeColor({ name: 'primary', hexValue: '#3274ED' })
            this.themeService.defineThemeColor({ name: 'secondary', hexValue: '#FD603A' })
            sessionStorage.removeItem('serviceProvider')
        }
    }

}
