<div class="car-detail-header" *ngIf="car">
    <a routerLink="/filter" class="car-detail-header__breadcrumb"><i class="pi pi-chevron-left"></i> {{ 'detailPage.detailHeader' | translate }}</a>
    <div class="grid">
        <div class="col-12 lg:col-6">
            <h1 class="car-detail-header__title">
                {{ car.vehicleMake.name }} {{ car.vehicleModel.name }} {{ 'detailPage.orSimilar' | translate }}
            </h1>
            <p class="car-detail-header__description mb-5">
                {{car.vehicleType?.name}} {{ 'detailPage.carFrom' | translate }}
                {{car.rentalPartner?.name}}.
            </p>
            <app-car-delivery-image class="car-image__hidden" />
            <app-car-detail-form [car]="car" />
        </div>

        <div class="col-12 lg:col-6">
            <app-car-delivery-image class="car-image__show" [car]="car" />
        </div>

    </div>
</div>
