import { Component, OnInit, OnDestroy, HostListener } from '@angular/core'
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators, FormArray } from '@angular/forms'
import { Subscription, timer } from 'rxjs'
import { ExitPollService } from '@app-services'

enum ExitPollOptions {
    BROWSING = 'Ik keek alleen even wat rond',
    NO_OFFER = 'Ik heb niet het juiste aanbod gevonden',
    TOO_EXPENSIVE = 'Ik vind het te duur',
    THINKING = 'Ik denk er nog even over na',
    OTHER = 'Anders, namelijk...',
}

@Component({
    selector: 'app-exit-poll',
    templateUrl: './exit-poll.component.html',
    styleUrls: ['./exit-poll.component.scss'],
})
export class ExitPollComponent implements OnInit, OnDestroy {
    
    public showPopup = false
    public options = Object.values(ExitPollOptions)
    public form: FormGroup

    private inactivityTimer: Subscription

    constructor(
        private readonly fb: FormBuilder,
        private readonly exitPollService: ExitPollService,
    ) {
        this.form = this.fb.group({
            selectedOptions: this.fb.array([], Validators.required),
            otherReason: [''],
        }, { validator: this.otherReasonValidator })
    }

    public ngOnInit(): void {
        if (! sessionStorage.getItem('exitPollShown')) {
            this.resetInactivityTimer()
            this.setupMouseLeaveListener()
        } else {
            this.showPopup = false
        }
    }

    public ngOnDestroy(): void {
        if (this.inactivityTimer) {
            this.inactivityTimer.unsubscribe()
        }
        this.removeMouseLeaveListener()
    }

    @HostListener('window:keydown')
    @HostListener('window:scroll')
    public resetInactivityTimer(): void {
        if (this.inactivityTimer) {
            this.inactivityTimer.unsubscribe()
        }
        this.inactivityTimer = timer(30000).subscribe(() => this.showPopup = true)
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardEvent(event: KeyboardEvent): void {
        if (event.key === 'Escape' && this.showPopup) {
            this.closePopup()
        }
    }

    private otherReasonValidator(control: AbstractControl): ValidationErrors | null {
        const selectedOptions = (control.get('selectedOptions') as FormArray)?.value
        const otherReason = control.get('otherReason')?.value

        if (selectedOptions.includes(ExitPollOptions.OTHER) && ! otherReason) {
            return { otherReasonRequired: true }
        }

        return null
    }

    private setupMouseLeaveListener(): void {
        document.addEventListener('mouseleave', this.mouseLeaveHandler.bind(this))
    }

    private removeMouseLeaveListener(): void {
        document.removeEventListener('mouseleave', this.mouseLeaveHandler.bind(this))
    }

    private mouseLeaveHandler(event: MouseEvent): void {
        if (event.clientY <= 0 && ! sessionStorage.getItem('exitPollShown')) {
            this.showPopup = true
        }
    }

    public onCheckboxChange(event: any, option: string): void {
        const selectedOptions = this.form.get('selectedOptions') as FormArray
        if (event.target.checked) {
            selectedOptions.push(this.fb.control(option))
        } else {
            const index = selectedOptions.controls.findIndex((x) => x.value === option)
            selectedOptions.removeAt(index)
        }
    }

    public closePopup(): void {
        this.showPopup = false
        sessionStorage.setItem('exitPollShown', 'true')

        if (this.inactivityTimer) {
            this.inactivityTimer.unsubscribe()
        }

        this.removeMouseLeaveListener()
    }

    public onSubmit(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched()
            return
        }

        const selectedOptions = (this.form.get('selectedOptions') as FormArray)?.value
        const otherReason = this.form.get('otherReason')?.value

        this.exitPollService.submitExitPoll({
            reason: selectedOptions,
            explanation: otherReason,
        })

        this.closePopup()
    }
}
