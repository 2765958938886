import { NgModule } from '@angular/core'

import {
    AuthenticatedPipe,
    CalculateDurationDaysPipe,
    FormatPhoneNumberPipe,
    InputErrorPipe,
    ServiceProviderPipe,
    VatPipe,
} from '@app-pipes'

const pipes = [
    AuthenticatedPipe,
    CalculateDurationDaysPipe,
    FormatPhoneNumberPipe,
    InputErrorPipe,
    ServiceProviderPipe,
    VatPipe,
]

@NgModule({
    declarations: [
        ...pipes,
    ],
    exports: [
        ...pipes,
    ],
    providers: [
        ...pipes,
    ],
})
export class PipesModule {
}
