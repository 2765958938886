import { Pipe, PipeTransform } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';

import { VatQuery } from '@app-graphql';
import { ContentService, ListingService } from '@app-services';

@Pipe({
    name: 'vat',
    pure: false,
})
export class VatPipe implements PipeTransform {

    private vatPercentage: number = 0;
    private shouldIncludeVatByDefault: boolean;

    constructor(
        private contentService: ContentService,
        private listingService: ListingService,
    ) {
        this.contentService.getVatPercentage().subscribe((response: ApolloQueryResult<VatQuery>) => {
            this.vatPercentage = response.data.vat.vatPercentage;
        });
        this.listingService.state.showIncVat.subscribe((showIncVat: boolean) => {
            this.shouldIncludeVatByDefault = showIncVat;
        });
    }

    public transform(value: number | string, includeVat: boolean | 'auto' = 'auto'): number | null {
        if (value === '' || value === undefined || value === null) {
            return null;
        }

        const shouldIncludeVat = includeVat === 'auto' ? this.shouldIncludeVatByDefault : includeVat;
        let vatMultiplier = 1;

        if (shouldIncludeVat) {
            vatMultiplier = +(`1.${this.vatPercentage}`);
        }

        return +value * vatMultiplier;
    }

}
