<div class="car-detail-form">
    <div class="car-detail-form__inner">
        <div class="car-detail-form__attribute">
            <i class="pi pi-check"></i>
            <p>{{ 'detailPage.deliveredWithin' | translate }} {{ leadTime }} </p>
        </div>

        <div class="car-detail-form__attribute">
            <i class="pi pi-check"></i>
            <p>{{ 'detailPage.mobilityGuaranty' | translate }}</p>
        </div>

        <div class="car-detail-form__attribute">
            <i class="pi pi-check"></i>
            <p>{{ 'detailPage.pickUpAndDelivery' | translate }}</p>
        </div>

        <div class="grid mt-4">
            <div class="col-6">
                <p class="car-detail-form__price">
                    {{ price | vat | currency : 'EUR' }}
                </p>
                <p class="car-detail-form__price car-detail-form__price--sub">
<!--                    {{ 'general.perDay' | translate }}-->
                    {{ kmPrice | vat | currency : 'EUR' }}/km
                </p>
            </div>
            <div class="col-6">
                <div class="car-detail-form__switch">
                    <span>{{ 'detailPage.VAT' | translate }}</span>
                    <p-inputSwitch (onChange)="setVatIncluded($event.checked)"
                                   [(ngModel)]="vatIncluded"
                    />
                </div>
                <p class="car-detail-form__sub-text">
                    incl. {{ car.freeKmsPerDay }} km
                    {{ 'general.perDay' | translate }}
                </p>
            </div>
        </div>

        <ng-container *ngIf="car.partnerLocation.paymentMethods.length === 1">
            <p class="car-detail-form__sub-text mt-2">
                <span [ngStyle]="{color: 'var(--color-warning)'}">Let op:</span>
                Deze locatie accepteert alleen
                <ng-container *ngFor="let paymentMethod of car.partnerLocation.paymentMethods">
                    {{ paymentMethod.name }}
                </ng-container>
            </p>
        </ng-container>

        <div class="mt-3">
            <p-button [label]="'general.orderNow' | translate"
                      (click)="handleSubmit()"
                      styleClass="p-button-rounded p-button--full-width"
            />
            <p class="car-detail-form__sub-text mt-2">
                {{ 'detailPage.chooseExtras' | translate }}
            </p>
        </div>
    </div>
</div>
