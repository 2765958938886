import { NgModule } from '@angular/core';

import { ComponentsSharedModule } from '@app-components/components-shared.module';
import { OrderCarCtaComponent, OrderInfoCardComponent } from '@app-components';

@NgModule({
    imports: [
        ComponentsSharedModule,
    ],
    exports: [
        OrderCarCtaComponent,
        OrderInfoCardComponent,
    ],
    declarations: [
        OrderCarCtaComponent,
        OrderInfoCardComponent,
    ],
})
export class OrderComponentsModule {}
