import { Injectable } from '@angular/core'
import { BehaviorSubject, firstValueFrom } from 'rxjs'

import { MobilityPartner as ServiceProvider, ServiceProviderQueryService } from '@app-graphql'
import { WindowService } from '@app-services/window/window.service'

@Injectable({
    providedIn: 'root',
})
export class DomainService {

    private dataSource = new BehaviorSubject<ServiceProvider | null>(null)

    public state = {
        serviceProvider: this.dataSource as BehaviorSubject<ServiceProvider | null>,
    }

    constructor(
        private serviceProviderQueryService: ServiceProviderQueryService,
        private windowService: WindowService,
    ) {
        //
    }

    public async getServiceProvider(): Promise<ServiceProvider | null> {
        const hostName = this.windowService.getHostname()

        try {
            const res = await firstValueFrom(this.serviceProviderQueryService.fetch({ slug: hostName }))
            const serviceProvider = res.data.mobilityPartner as ServiceProvider

            if (serviceProvider) {
                this.dataSource.next(serviceProvider)
                document.title = `TripManager - ${serviceProvider.name}`

                return serviceProvider
            }
        } catch (error) {
            //
        }
        return null
    }

}
