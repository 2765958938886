import { Injectable } from '@angular/core'
import { BehaviorSubject, firstValueFrom } from 'rxjs'
import moment from 'moment/moment'

import { FilterGroupsQueryService, FilterOption, FilterOptionsByValuesQueryService } from '@app-graphql'

export interface FilterGroups {
    Type: FilterOption[],
    Aanbieder: FilterOption[],
    'Auto model': FilterOption[],
    Bezorgopties: FilterOption[],
    Brandstoftype: FilterOption[],
    Transmissie: FilterOption[],
}

export interface FilterParams {
    search: any,
    dropOffOtherLocation: any,
    extraKms: any,
    dateTimeFrom: any,
    dateTimeTo: any,
    vehicleType: Array<string>,
    maxPrice: any,
    deliveryMethod: any,
    fuelType: any,
    model: any,
    supplier: any,
    transmission: any,
}

export enum RateType {
    OneToTwoDays = '1-2',
    ThreeToSixDays = '3-6',
    SevenToTwentyNineDays = '7-29',
    ThirtyDays = '30',
    NinetyDays = '90',
    OneHundredAndEightyDays = '180',
}

@Injectable({
    providedIn: 'root',
})
export class FilterService {

    public firstTimeRunningServiceForFilterParams = true

    public state = {
        filterParams: new BehaviorSubject({
            search: '',
            dropOffOtherLocation: false,
            extraKms: '',
            dateTimeFrom: moment(),
            dateTimeTo: moment().add(1, 'days'),
            vehicleType: [],
            maxPrice: 0,
            deliveryMethod: 'fetch',
            fuelType: {} as FilterOption,
            model: {} as FilterOption,
            supplier: {} as FilterOption,
            transmission: {} as FilterOption,
        }),
        filterGroups: new BehaviorSubject({}) as unknown as BehaviorSubject<FilterGroups>,
    }

    constructor(
        private readonly filterGroupsQueryService: FilterGroupsQueryService,
        private readonly filterOptionsByValuesQueryService: FilterOptionsByValuesQueryService,
    ) {
    }

    public clearFilterParams(): void {
        this.setFilterValue({ name: 'search', value: '' })
        this.setFilterValue({ name: 'dropOffOtherLocation', value: false })
        this.setFilterValue({ name: 'extraKms', value: 0 })
        this.setFilterValue({ name: 'amountOfKm', value: '' })
        this.setFilterValue({ name: 'vehicleType', value: [] })
        this.setFilterValue({ name: 'maxPrice', value: 0 })
        this.setFilterValue({ name: 'deliveryMethod', value: 'fetch' })
        this.setFilterValue({ name: 'fuelType', value: {} })
        this.setFilterValue({ name: 'model', value: {} })
        this.setFilterValue({ name: 'supplier', value: {} })
        this.setFilterValue({ name: 'transmission', value: {} })
    }

    public getFilter() {
        if (this.firstTimeRunningServiceForFilterParams) {
            this.firstTimeRunningServiceForFilterParams = false
            const localStorageData = JSON.parse(
                window.sessionStorage.getItem('local_FilterService_filterParams') || 'null',
            )
            if (localStorageData) {
                this.state.filterParams.next({ ...this.state.filterParams, ...localStorageData })
            }
        }
        return this.state.filterParams
    }

    public getFilterGroups(): Promise<FilterGroups> {
        return new Promise<FilterGroups>((resolve) => {
            this.filterGroupsQueryService.fetch().subscribe((res) => {
                const { filterGroups } = res.data;
                const object: any = {};
                filterGroups.forEach((subject) => {
                    if (subject) {
                        subject.filters.forEach((filter) => {
                            if (filter && filter.filterOptions.length > 0) {
                                if (subject.name) {
                                    object[subject.name] = filter.filterOptions;
                                }
                            }
                        });
                    }
                });
                this.state.filterGroups.next(object);
                resolve(object);
            });
        });
    }

    public async getFilterOptionsByValues(values: string[]): Promise<FilterOption[] | null> {
        try {
            const res = await firstValueFrom(this.filterOptionsByValuesQueryService.fetch({ values }))
            return res.data.filterOptionsByValues as FilterOption[]
        } catch (error) {
            //
        }
        return null
    }

    public setFilterValue(input: { name: string; value: any }): void {
        const currentFilterParams = this.state.filterParams.getValue()
        currentFilterParams[input.name] = input.value
        this.state.filterParams.next(currentFilterParams)
        window.sessionStorage.setItem('local_FilterService_filterParams', JSON.stringify(currentFilterParams))
    }

    public getRateType(): RateType {
        const dateTimeFrom: Date = new Date(this.state.filterParams.value.dateTimeFrom.toString())
        const dateTimeTo: Date = new Date(this.state.filterParams.value.dateTimeTo.toString())

        if (! dateTimeFrom || ! dateTimeTo) {
            return RateType.OneToTwoDays
        }

        const numDays = Math.ceil((dateTimeTo.getTime() - dateTimeFrom.getTime()) / 24 / 60 / 60 / 1000)

        if (! numDays || numDays < 3) {
            return RateType.OneToTwoDays
        }

        if (numDays < 7) {
            return RateType.ThreeToSixDays
        }

        if (numDays < 30) {
            return RateType.SevenToTwentyNineDays
        }

        if (numDays >= 180) {
            return RateType.OneHundredAndEightyDays
        }

        if (numDays >= 90) {
            return RateType.NinetyDays
        }

        return RateType.ThirtyDays
    }

}
