import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, FilterGuard, ProductionGuard } from '@app-guards'

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('@app-pages/home/home.page.module').then((m) => m.HomePageModule),
    },
    {
        path: 'about',
        loadChildren: () => import('@app-pages/about/about.page.module').then((m) => m.AboutPageModule),
    },
    {
        path: 'auth',
        loadChildren: () => import('@app-pages/auth/auth.page.module').then((m) => m.AuthPageModule),
    },
    {
        path: 'checkout',
        loadChildren: () => import('@app-pages/checkout/checkout.page.module').then((m) => m.CheckoutPageModule),
    },
    {
        path: 'customer-service',
        loadChildren: () => import('@app-pages/customer-service/customer-service.page.module')
            .then((m) => m.CustomerServicePageModule),
    },
    {
        path: 'detail/:id/:partnerLocationId',
        loadChildren: () => import('@app-pages/car-detail/car-detail.page.module').then((m) => m.CarDetailPageModule),
        canActivate: [FilterGuard],
    },
    {
        path: 'filter',
        loadChildren: () => import('@app-pages/filter/filter.page.module').then((m) => m.FilterPageModule),
        canActivate: [FilterGuard],
    },
    {
        path: 'locations',
        loadChildren: () => import('@app-pages/locations/locations.page.module').then((m) => m.LocationsPageModule),
    },
    {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () => import('@app-pages/profile/profile.page.module').then((m) => m.ProfilePageModule),
    },
    {
        path: 'ui-kit',
        loadChildren: () => import('@app-pages/uikit/uikit.page.module').then((m) => m.UikitPageModule),
        canActivate: [ProductionGuard],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
    })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
