<div class="delivery-form-card">
    <div class="delivery-form-card__title">
        <h2>{{ 'delivery.title' | translate }}</h2>
    </div>

    <p-tabView>
        <p-tabPanel [header]="'delivery.tabDelivery' | translate"
                    [formGroup]="formDelivery"
                    [disabled]="activeTab !== 'delivery'"
                    [selected]="activeTab === 'delivery'"
        >

            <h5>{{ 'delivery.address' | translate }}</h5>

            <p-selectButton [options]="addressTypes"
                            formControlName="addressType"
                            optionLabel="name"
                            optionValue="value"
                            styleClass="mb-3"></p-selectButton>

            <div class="grid delivery-form-card__container">
                <div *ngIf="!addressPrivate"
                     class="col-12">
                    <label for="companyName">
                        {{ 'general.companyName' | translate }}
                    </label>
                    <input pInputText
                           type="text"
                           formControlName="companyName"
                           id="companyName">
                    <app-input-error *ngIf="formDelivery.get('companyName')?.dirty &&
                              formDelivery.get('companyName')?.touched"
                              [error]="formDelivery.get('companyName')?.errors"></app-input-error>
                </div>

                <div class="col-12 md:col-6">
                    <label for="street">
                        {{ 'general.street' | translate }}
                    </label>
                    <input pInputText
                           type="text"
                           formControlName="streetName"
                           id="street">
                    <app-input-error *ngIf="formDelivery.get('street')?.dirty &&
                              formDelivery.get('street')?.touched"
                              [error]="formDelivery.get('street')?.errors"></app-input-error>
                </div>

                <div class="col-12 md:col-6">
                    <label for="number">
                        {{ 'general.streetNumber' | translate }}
                    </label>
                    <input pInputText
                           type="text"
                           formControlName="streetNumber"
                           id="number">
                    <app-input-error *ngIf="formDelivery.get('streetNumber')?.dirty &&
                              formDelivery.get('streetNumber')?.touched"
                              [error]="formDelivery.get('streetNumber')?.errors"></app-input-error>
                </div>

                <div class="col-12 md:col-6">
                    <label for="postalCode">
                        {{ 'general.postalCode' | translate }}
                    </label>
                    <input pInputText
                           type="text"
                           formControlName="postalCode"
                           id="postalCode">
                    <app-input-error *ngIf="formDelivery.get('postalCode')?.dirty &&
                              formDelivery.get('postalCode')?.touched"
                              [error]="formDelivery.get('postalCode')?.errors"></app-input-error>
                </div>

                <div class="col-12 md:col-6">
                    <label for="city">
                        {{ 'general.city' | translate }}
                    </label>
                    <input pInputText
                           type="text"
                           formControlName="city"
                           id="city">
                    <app-input-error *ngIf="formDelivery.get('city')?.dirty &&
                              formDelivery.get('city')?.touched"
                              [error]="formDelivery.get('city')?.errors"></app-input-error>
                </div>
            </div>

            <div class="grid mt-3">
                <div class="col-12">
                    <label for="deliveryTime">
                        {{ 'delivery.deliveryTime' | translate }}
                    </label>
                    <p-dropdown [options]="deliveryTimes"
                                [showClear]="true"
                                formControlName="deliveryTime"
                                id="deliveryTime"
                                inputId="deliveryTime"></p-dropdown>
                </div>

                <div class="col-12">
                    <div class="delivery-form-card__note_and_icon">
                        <span>
                            <svg width="32"
                             height="32"
                             xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fill-rule="evenodd">
                                    <path d="M0 0h32v32H0z"/>
                                    <g transform="translate(7 7)">
                                        <circle stroke="#1D1C2D"
                                             stroke-width="1.5"
                                             cx="9"
                                             cy="9"
                                             r="9"/>
                                        <path
                            d="M9.188 5.344c.312 0 .558-.084.738-.252a.847.847 0 0 0 .27-.648c0-.288-.088-.512-.264-.672-.176-.16-.424-.24-.744-.24s-.57.084-.75.252a.86.86 0 0 0-.27.66c0 .28.09.5.27.66.18.16.43.24.75.24ZM10.016 13V6.664H8.372V13h1.644Z"
                            fill="#1D1C2D"
                            fill-rule="nonzero"/></g></g></svg>
                        </span>
                        <p class="delivery-form-card__note1 mt-5"
                           [innerHTML]="'delivery.deliveryParking' | translate"></p>
                    </div>
                </div>
            </div>

            <div class="my-5">
                <p class="sub-header">
                    {{ 'delivery.deliveryReceiver' | translate }}
                </p>
                <p-selectButton [options]="receivers"
                                formControlName="receiver"
                                optionLabel="name"
                                optionValue="value"
                                styleClass="mb-3"></p-selectButton>

                <div *ngIf="!isReceiver"
                     class="grid"
                     formGroupName="contactPerson">

                    <div class="col-12">
                        <label for="function">{{ 'general.position' | translate }}</label>
                        <input pInputText
                               type="text"
                               formControlName="function"
                               id="function">
                        <app-input-error *ngIf="formDelivery.get('function')?.dirty &&
                              formDelivery.get('function')?.touched"
                              [error]="formDelivery.get('function')?.errors"></app-input-error>
                    </div>

                    <div class="col-12 md:col-6">
                        <label for="firstName">{{ 'general.firstName' | translate }}</label>
                        <input pInputText
                               type="text"
                               formControlName="firstName"
                               id="firstName">
                        <app-input-error *ngIf="formDelivery.get('firstName')?.dirty &&
                              formDelivery.get('firstName')?.touched"
                              [error]="formDelivery.get('firstName')?.errors"></app-input-error>
                    </div>

                    <div class="col-12 md:col-6">
                        <label for="surName">{{ 'general.lastName' | translate }}</label>
                        <input pInputText
                               type="text"
                               formControlName="surName"
                               id="surName">
                        <app-input-error *ngIf="formDelivery.get('surName')?.dirty &&
                              formDelivery.get('surName')?.touched"
                              [error]="formDelivery.get('surName')?.errors"></app-input-error>
                    </div>

                    <div class="col-12 md:col-6">
                        <label for="email">{{ 'general.email' | translate }}</label>
                        <input pInputText
                               type="text"
                               formControlName="email"
                               id="email">
                        <app-input-error *ngIf="formDelivery.get('email')?.dirty &&
                              formDelivery.get('email')?.touched"
                              [error]="formDelivery.get('email')?.errors"></app-input-error>
                    </div>

                    <div class="col-12 md:col-6">
                        <label for="phone">{{ 'general.telephone' | translate }}</label>
                        <input pInputText
                               type="text"
                               formControlName="phone"
                               id="phone">
                        <app-input-error *ngIf="formDelivery.get('phone')?.dirty &&
                              formDelivery.get('phone')?.touched"
                              [error]="formDelivery.get('phone')?.errors"></app-input-error>
                    </div>

                    <div class="col-12">
                        <div class="delivery-form-card__note_and_icon">
                           <span>
                            <svg width="32"
                                 height="32"
                                 xmlns="http://www.w3.org/2000/svg">
                              <g fill="none"
                                 fill-rule="evenodd">
                                <path d="M0 0h32v32H0z"/><g transform="translate(7 7)">
                                <circle stroke="#1D1C2D"
                                        stroke-width="1.5"
                                        cx="9"
                                        cy="9"
                                        r="9"/><path
                                  d="M9.188 5.344c.312 0 .558-.084.738-.252a.847.847 0 0 0 .27-.648c0-.288-.088-.512-.264-.672-.176-.16-.424-.24-.744-.24s-.57.084-.75.252a.86.86 0 0 0-.27.66c0 .28.09.5.27.66.18.16.43.24.75.24ZM10.016 13V6.664H8.372V13h1.644Z"
                                  fill="#1D1C2D"
                                  fill-rule="nonzero"/></g></g></svg>
                            </span>
                            <p class="delivery-form-card__note1">
                                {{ 'delivery.deliveryNote' | translate }}
                            </p>
                        </div>
                    </div>
                </div>

            </div>

            <p-button [label]="'general.nextStep' | translate"
                      [disabled]="!formDelivery.valid"
                      routerLink="/checkout/customer-data"
                      styleClass="p-button-rounded p-button-primary p-button--full-width">
            </p-button>
        </p-tabPanel>

        <p-tabPanel [header]="'delivery.tabPickUp' | translate"
                    [formGroup]="formPickup"
                    [disabled]="activeTab !== 'fetch'"
                    [selected]="activeTab === 'fetch'">

            <div class="pickup-location mb-3" *ngIf="partnerLocation">
                <h5 class="mb-4">
                    {{partnerLocation.location.name}}
                </h5>
                <p>
                    {{partnerLocation.location.streetName}} {{partnerLocation.location.streetNumber}} {{partnerLocation.location.streetNumberSuffix}}
                    <br>
                    {{partnerLocation.location.postalCode}} {{partnerLocation.location.city}}
                </p>
            </div>
<!-- TODO: find out what time should be shown here, hide for now --->
<!--            <div class="pickup-time mb-3">-->
<!--                <h5>{{ 'delivery.earliestPickupTime' | translate:pickupTime }}</h5>-->
<!--            </div>-->

            <p-button [label]="'general.nextStep' | translate"
                      [disabled]="!formPickup.valid"
                      routerLink="/checkout/customer-data"
                      styleClass="p-button-rounded p-button-primary p-button--full-width"></p-button>

        </p-tabPanel>
    </p-tabView>

</div>



