import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import moment, { Moment } from 'moment/moment'
import { equals, reject } from 'ramda'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter, switchMap, take } from 'rxjs/operators'

import {
    BusinessHour,
    BusinessHoursQueryService,
    CustomerTypeEnum,
    CustomerTypeWithBothEnum,
    MobilityPartner as ServiceProvider,
    PaginatorInfo,
    PartnerVehicle,
    PartnerVehicleByIdInput,
    PartnerVehicleByIdQuery,
    PartnerVehicleByIdQueryService,
    PartnerVehiclesInput,
    PartnerVehiclesQueryService,
    Scalars,
    SpecialHoursQueryService,
} from '@app-graphql'
import { GeolocationService } from '@app-services/geolocation/geolocation.service'
import { FilterService } from '@app-services/filter/filter.service'
import { WindowService } from '@app-services/window/window.service'
import { DomainService } from '@app-services/domain/domain.service'

type PartialServiceProvider = Partial<ServiceProvider>

interface State {
    partnerVehicles: BehaviorSubject<PartnerVehicle[]> | BehaviorSubject<any>,
    partnerVehiclesLoading: BehaviorSubject<any>,
    isShortLease: BehaviorSubject<boolean>,
    selectedVehicle: Observable<ApolloQueryResult<PartnerVehicleByIdQuery>>,
    partnerVehiclesPagination: BehaviorSubject<PaginatorInfo>,
    paginatorInfo: {
        first: number,
        page: number,
    },
    businessHours: BehaviorSubject<BusinessHour[]>,
    specialHours: BehaviorSubject<BusinessHour[]>,
    currentListingView: BehaviorSubject<string>,
    sortByPrice: BehaviorSubject<boolean>,
    showIncVat: BehaviorSubject<boolean>,
}

@Injectable({
    providedIn: 'root',
})
export class ListingService {

    public state: State = {
        partnerVehicles: new BehaviorSubject([]),
        partnerVehiclesLoading: new BehaviorSubject(true),
        isShortLease: new BehaviorSubject<boolean>(false),
        selectedVehicle: new Observable(),
        partnerVehiclesPagination: new BehaviorSubject({}) as unknown as BehaviorSubject<PaginatorInfo>,
        businessHours: new BehaviorSubject([]) as unknown as BehaviorSubject<BusinessHour[]>,
        specialHours: new BehaviorSubject([]) as unknown as BehaviorSubject<BusinessHour[]>,
        paginatorInfo: {
            first: 5,
            page: 1,
        },
        currentListingView: new BehaviorSubject('list'),
        sortByPrice: new BehaviorSubject<boolean>(false),
        showIncVat: new BehaviorSubject<boolean | null>(null),
    }

    public fetchList: any

    public customerType: CustomerTypeEnum | null = null
    public serviceProvider: ServiceProvider | null = null

    constructor(
        private readonly domainService: DomainService,
        private readonly partnerVehiclesQueryService: PartnerVehiclesQueryService,
        private readonly partnerVehicleByIdQueryService: PartnerVehicleByIdQueryService,
        private readonly geolocationService: GeolocationService,
        private readonly filterService: FilterService,
        private readonly windowService: WindowService,
        private readonly businessHoursQueryService: BusinessHoursQueryService,
        private readonly specialHoursQueryService: SpecialHoursQueryService,
    ) {
        this.initialize()
    }

    public async initialize(): Promise<void> {
        await this.domainService.getServiceProvider()
        this.domainService.state.serviceProvider.pipe(
            filter((serviceProvider) => !! serviceProvider),
            take(1),
        ).subscribe((serviceProvider) => {
            this.setupServiceProvider(serviceProvider)
        })
    }

    public setupServiceProvider(serviceProvider: PartialServiceProvider): void {
        this.customerType = serviceProvider.customerType === CustomerTypeWithBothEnum.B2B
            ? CustomerTypeEnum.B2B
            : CustomerTypeEnum.B2C

        const storedShowIncVat = localStorage.getItem('showIncVat')
        const defaultShowIncVat = storedShowIncVat === null
            ? (serviceProvider.customerType !== CustomerTypeWithBothEnum.B2B)
            : storedShowIncVat === 'true'

        this.state.showIncVat.next(defaultShowIncVat)
    }

    public getDetail(
        id: Scalars['ID']['output'],
        partnerLocationId: Scalars['ID']['output'],
    ): Observable<ApolloQueryResult<PartnerVehicleByIdQuery>> {
        return this.domainService.state.serviceProvider.pipe(
            filter((serviceProvider) => !! serviceProvider),
            take(1),
            switchMap((serviceProvider) => {
                this.customerType = serviceProvider.customerType === CustomerTypeWithBothEnum.B2B
                    ? CustomerTypeEnum.B2B
                    : CustomerTypeEnum.B2C

                const context = this.windowService.getHostname()
                const coords = this.geolocationService.getCurrentCoords().getValue()
                const getFilter = this.filterService.getFilter().getValue()

                const input: PartnerVehicleByIdInput = {
                    id,
                    partnerLocationId,
                    coordinates: {
                        latitude: Number(coords.latitude),
                        longitude: Number(coords.longitude),
                    },
                    customerType: this.customerType,
                    radius: 50,
                    extraKms: Number(getFilter.extraKms),
                    dateTimeFrom: moment(getFilter.dateTimeFrom).format('YYYY-MM-DD HH:mm:ss'),
                    dateTimeTo: moment(getFilter.dateTimeTo).format('YYYY-MM-DD HH:mm:ss'),
                }

                const query = this.partnerVehicleByIdQueryService.fetch({ input, onContext: context })
                this.state.selectedVehicle = query

                return query
            }),
        )
    }

    public isListingShortlease(dateTimeFrom: Date | Moment, dateTimeTo: Date | Moment): boolean {
        const daysBetweenFromTo = moment(dateTimeTo).diff(moment(dateTimeFrom), 'days')
        if (daysBetweenFromTo >= 30) {
            this.state.isShortLease.next(true)
            return true
        }
        this.state.isShortLease.next(false)
        return false
    }

    public async getFilteredListing(
        first?: number,
        page?: number,
        context: string = null,
    ): Promise<void> {
        if (! this.serviceProvider) {
            this.serviceProvider = await this.domainService.getServiceProvider()
        }

        this.customerType = this.serviceProvider.customerType === CustomerTypeWithBothEnum.B2B
            ? CustomerTypeEnum.B2B
            : CustomerTypeEnum.B2C

        this.state.partnerVehiclesLoading.next(true)
        let firstParam = first
        let pageParam = page || 0
        const coords = this.geolocationService.getCurrentCoords().getValue()
        const getFilter = this.filterService.getFilter().getValue()
        const filterGroups = await this.filterService.getFilterGroups()

        if (firstParam === undefined) {
            this.state.paginatorInfo.page = 1
            firstParam = this.state.paginatorInfo.first
            pageParam = this.state.paginatorInfo.page
        }

        // Fix date range if start date/time is less than 2,5 hours in the future
        //if (getFilter.dateTimeFrom && moment(getFilter.dateTimeFrom).isBefore(moment().add(2.5, 'hours'))) {
        //    getFilter.dateTimeFrom = moment(getFilter.dateTimeFrom).add(2.5, 'hours')
        //    getFilter.dateTimeTo = moment(getFilter.dateTimeFrom).add(1, 'days')
        //}

        const vehicleTypes = getFilter.vehicleType?.map((type: any) => type.id)
        const deliveryType = filterGroups?.Bezorgopties.find((d) => d.label?.toLowerCase() === getFilter.deliveryMethod)

        const input: PartnerVehiclesInput = {
            customerType: this.customerType,
            first: firstParam,
            page: pageParam,
            coordinates: {
                latitude: Number(coords.latitude),
                longitude: Number(coords.longitude),
            },
            radius: 50,
            showDistance: true, // viewMode === 'list',
            dateTimeFrom: moment(getFilter.dateTimeFrom).format('YYYY-MM-DD HH:mm:ss'),
            dateTimeTo: moment(getFilter.dateTimeTo).format('YYYY-MM-DD HH:mm:ss'),
            categoryId: getFilter.model?.id || '',
            vehicleType: { ids: vehicleTypes },
            rentalPartnerId: getFilter.supplier?.id || '',
            transmissionId: getFilter.transmission?.id || '',
            deliveryTypeId: deliveryType?.id || '',
            extraKms: Number(getFilter.extraKms),
            fuelTypeId: getFilter.fuelType?.id || '',
            maximumPrice: Number(getFilter.maxPrice),
            sortByPrice: this.state.sortByPrice.getValue(),
        }

        const onContext = context ?? this.windowService.getHostname()

        this.isListingShortlease(input.dateTimeFrom, input.dateTimeTo)

        this.fetchList?.unsubscribe()
        this.fetchList = this.partnerVehiclesQueryService.fetch({
            input: reject(equals(''))(input as any),
            onContext,
        }).subscribe((res) => {
            const response = res.data.partnerVehicles?.data as unknown as PartnerVehicle[]
            const pagination = res.data.partnerVehicles?.paginatorInfo as PaginatorInfo
            this.state.partnerVehiclesPagination.next(pagination)
            this.state.partnerVehicles.next(response)
            this.state.partnerVehiclesLoading.next(false)
        })
    }

    public getBusinessHours(id: { id: string }): void {
        this.businessHoursQueryService.fetch(id).subscribe((res) => {
            const response = res.data.businessHours as BusinessHour[]
            this.state.businessHours.next(response)
        })
    }

    public getSpecialHours(id: { id: string }): void {
        this.specialHoursQueryService.fetch(id).subscribe((res) => {
            const response = res.data.specialHours as BusinessHour[]
            this.state.specialHours.next(response)
        })
    }
}
