<form *ngIf="formState" [formGroup]="formState" class="form-container">
    <h1 class="form__title">
        {{ 'extras.title' | translate }}
    </h1>
    <p>
        {{ 'extras.description' | translate }}
    </p>

    <div class="mb-5">
        <ng-container *ngFor="let vehicleExtra of vehicleExtras; let i = index" formArrayName="extras">
            <a class="p-field-checkbox"
                [class.disabled]="! vehicleExtra.rates[0].pricePerDay && ! vehicleExtra.rates[0].oneTimeFee"
            >
                <label>
                    <input type="checkbox"
                           class="checkbox__icon"
                           [formControlName]="i"
                    >
                    <div class="vehicle-extras">
                        <span class="vehicle-extra__name">
                            {{vehicleExtra.name}}
                        </span>
                        <span class="vehicle-extra__price"
                              *ngIf="vehicleExtra.rates[0].oneTimeFee"
                        >
                            {{ vehicleExtra.rates[0].oneTimeFee | vat | currency : 'EUR' }}
                            <small>{{ 'extras.one_time_fee' | translate }}</small>
                        </span>
                        <span class="vehicle-extra__price"
                              *ngIf="vehicleExtra.rates[0].pricePerDay"
                        >
                            {{ vehicleExtra.rates[0].pricePerDay | vat | currency : 'EUR' }}
                            <small>{{ 'extras.price_per_day' | translate }}</small>
                        </span>
                        <span class="vehicle-extra__price"
                              *ngIf="! vehicleExtra.rates[0].oneTimeFee && ! vehicleExtra.rates[0].pricePerDay"
                        >
                            {{ 0 | currency : 'EUR' }}
                        </span>
                    </div>
                </label>
            </a>
        </ng-container>
    </div>

    <p-button
        [disabled]="!formState.valid"
        (click)="handleFormSubmit(formState.valid)"
        [label]="'general.nextStep' | translate"
        type="button"
        styleClass="p-button-rounded p-button-primary p-button--full-width">
    </p-button>
</form>
