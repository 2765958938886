<div class="grid about-card-container">

    <div class="md:col-6 col-12" *ngFor="let item of page?.contentBlocks">
        <article class="about-card">
            <div class="about-card__image-container">
                <img alt="" [src]="item?.image" class="about-card__image" />
            </div>
            <h1 class="about-card__title">{{ item?.title }}</h1>
            <p class="about-card__content" [innerHTML]="item?.description"></p>
        </article>
    </div>

</div>
