import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { MenuItem } from 'primeng/api'

import { CheckoutService, DomainService } from '@app-services'

const SUPPORT_MESSAGE_TIMEOUT = 1000 * 10 // 10 seconds (per step)

@Component({
    selector: 'app-checkout-reservations-navigator',
    templateUrl: './checkout-reservations-navigator.component.html',
    styleUrls: ['./checkout-reservations-navigator.component.scss'],
})
export class CheckoutReservationsNavigatorComponent implements OnInit {

    public items: MenuItem[]

    public prevDisabled = false
    public nextDisabled = false
    public currentStepIndex: number

    public logoUrl: string

    public supportMessageVisible = false
    public supportMessageTimer: number | any | null = null

    constructor(
        private router: Router,
        private translate: TranslateService,
        private checkoutService: CheckoutService,
        private domainService: DomainService,
    ) {
    }

    public ngOnInit(): void {
        this.domainService.state.serviceProvider.subscribe((mp) => {
            this.logoUrl = mp.logo
        })

        this.translate.get('orderSteps.step1').subscribe(() => {
            this.items = [
                {
                    label: this.translate.instant('orderSteps.step1'),
                    routerLink: '/checkout/extras',
                    disabled: this.checkoutService.state.stepIsAccessible.extras.getValue(),
                },
                {
                    label: this.translate.instant('orderSteps.step2'),
                    routerLink: '/checkout/delivery',
                    disabled: this.checkoutService.state.stepIsAccessible.delivery.getValue(),
                },
                {
                    label: this.translate.instant('orderSteps.step3'),
                    routerLink: '/checkout/customer-data',
                    disabled: this.checkoutService.state.stepIsAccessible.personalInformation.getValue(),
                },
                {
                    label: this.translate.instant('orderSteps.step4'),
                    routerLink: '/checkout/final-check',
                    disabled: this.checkoutService.state.stepIsAccessible.finalize.getValue(),
                },
            ]
            this.handleDisabled()
            this.initCompleteListener()
        })

        this.startSupportMessageTimer()
    }

    public initCompleteListener(): void {
        this.checkoutService.state.stepIsAccessible.extras.subscribe((res) => {
            this.items[0].disabled = res
        })
        this.checkoutService.state.stepIsAccessible.delivery.subscribe((res) => {
            this.items[1].disabled = res
        })
        this.checkoutService.state.stepIsAccessible.personalInformation.subscribe((res) => {
            this.items[2].disabled = res
        })
        this.checkoutService.state.stepIsAccessible.finalize.subscribe((res) => {
            this.items[3].disabled = res
        })
    }

    public findItemIndex(): number {
        this.currentStepIndex = this.items.findIndex((item) => item.routerLink === location.pathname)

        return this.currentStepIndex
    }

    public handleDisabled(): void {
        const index = this.findItemIndex()
        if (index <= 0) {
            this.prevDisabled = true
        } else if (index >= 3) {
            this.nextDisabled = true
        }

        const nextItem = this.items[index + 1]
        if (nextItem) {
            if (nextItem.disabled) {
                this.nextDisabled = true
            }
        }
    }

    public handleNextPage(): void {
        const index = this.findItemIndex()
        if (! this.nextDisabled) {

            // Start timer for support message, except when on final step
            if (index < this.items.length - 1) {
                this.startSupportMessageTimer()
            }

            this.router.navigate([this.items[index + 1].routerLink])
        }
    }

    public handlePrevPage(): void {
        const index = this.findItemIndex()
        if (! this.prevDisabled) {
            this.router.navigate([this.items[index - 1].routerLink])
        }
    }

    public startSupportMessageTimer(): void {
        clearTimeout(this.supportMessageTimer)

        if (sessionStorage.getItem('supportMessageDismissed')) {
            return
        }

        this.supportMessageTimer = setTimeout(() => this.supportMessageVisible = true, SUPPORT_MESSAGE_TIMEOUT)
    }

    public dismissSupportMessage(): void {
        clearTimeout(this.supportMessageTimer)

        this.supportMessageVisible = false
        sessionStorage.setItem('supportMessageDismissed', 'true')
    }
}
