<header class="header">
    <div class="outer-container">
        <div class="header-container">
            <img *ngIf="'logo' | serviceProvider"
                 [alt]="'name' | serviceProvider"
                 [src]="'logo' | serviceProvider"
                 routerLink="/filter"
                 class="service-provider-logo"
            />

            <p-steps class="steps-container" [model]="items" [readonly]="currentStepIndex === -1" />

            <div [hidden]="" class="button-container">
                <span class="chevron-left">
                    <p-button icon="pi pi-chevron-left"
                              [disabled]="prevDisabled || currentStepIndex === -1"
                              (click)="handlePrevPage()"
                    />
                </span>
                <span>
                    <p-button icon="pi pi-chevron-right"
                              [disabled]="nextDisabled || currentStepIndex === -1"
                              (click)="handleNextPage()"
                    />
                </span>
            </div>
        </div>
    </div>
</header>

<div class="support-message" [class.support-message--hidden]="! supportMessageVisible">
    <div class="support-message__header">
        <p-button styleClass="p-button-rounded"
                  data-action="dismiss"
                  title="Verberg melding"
                  (click)="dismissSupportMessage()"
        >
            <i class="pi pi-times"></i>
        </p-button>
        <p class="mb-0">Komt u er niet uit? Neem contact met ons op:</p>
    </div>
    <div class="support-message__buttons">
        <a href="tel:+31889343426"
           class="p-button p-component p-button-rounded p-button-secondary mt-2 mr-2"
        >
            <i class="pi pi-phone mr-2"></i>
            Bellen
        </a>
        <a href="mailto:team@tripmanager.nl"
           class="p-button p-component p-button-rounded p-button-secondary mt-2 mr-2"
        >
            <i class="pi pi-envelope mr-2"></i>
            Mailen
        </a>
        <a href="/customer-service"
           class="p-button p-component p-button-rounded p-button-secondary mt-2 mr-2"
        >
            <i class="pi pi-question-circle mr-2"></i>
            Stel een vraag
        </a>
    </div>
</div>
