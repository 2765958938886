import { NgModule } from '@angular/core';

import { PipesModule } from '@app-pipes/pipes.module';
import { AppSharedModule } from '@app-modules/app-shared.module';

@NgModule({
    exports: [
        AppSharedModule,
        PipesModule,
    ],
})
export class ComponentsSharedModule {}
