<div *ngIf="(listingIsLoading$ | async) === false; else loading">
    <div *ngIf="listing$ | async as listing;">

        <div *ngIf="listing.length > 0; else noResults">
            <div class="mb-3" *ngFor="let car of listing">
                <app-car-info-card [car]="car" />
            </div>

            <div class="paginator">
                <p-paginator
                        *ngIf="paginator$ | async as paginator"
                        [first]="paginator.firstItem || 1"
                        [rows]="paginator.perPage"
                        [totalRecords]="paginator.total"
                        (onPageChange)="onPageChange($event)"
                        [showJumpToPageDropdown]="true"
                        [showPageLinks]="false" />
            </div>

            <div class="my-3">
                <p-card header="Niet gevonden wat u zocht?">
                    <p class="my-0">
                        Zoekt u een andere auto of komt u er om een andere reden niet uit?<br />
                        Neem dan contact met ons op:
                    </p>
                    <ng-template pTemplate="footer">
                        <a href="tel:+31889343426"
                           class="p-button p-component p-button-rounded p-button-secondary mr-2"
                        >
                            <i class="pi pi-phone mr-2"></i>
                            Bellen
                        </a>
                        <a href="mailto:team@tripmanager.nl"
                           class="p-button p-component p-button-rounded p-button-secondary mr-2"
                        >
                            <i class="pi pi-envelope mr-2"></i>
                            Mailen
                        </a>
                        <a href="/customer-service"
                           class="p-button p-component p-button-rounded p-button-secondary mr-2"
                        >
                            <i class="pi pi-question-circle mr-2"></i>
                            Stel een vraag
                        </a>
                    </ng-template>
                </p-card>
            </div>
        </div>

    </div>
</div>

<ng-template #loading>
    <p-dialog [visible]="true"
              [modal]="true"
              [closable]="false"
              [showHeader]="false"
              [blockScroll]="true"
              class="loading-dialog-cars"
    >
        <div class="p-5">
            <ng-lottie
                    [options]="{ path: '/assets/animations/Tripmanager_loading_car_blue.json' }"
                    height="300px"
                    width="100%"
            />
            <p class="text-center -mt-5">Wij zijn op zoek naar de auto die het beste bij u past</p>
        </div>
    </p-dialog>
    <app-car-info-card-skeleton
            class="mb-3 block"
            *ngFor="let i of [1,2,3,4,5]"
    />
</ng-template>

<ng-template #noResults>
    <div class="flex justify-content-center flex-column">
        <h1>{{ 'filterPage.noResults' | translate }}</h1>
        <p>{{ 'filterPage.alterSearch' | translate }}</p>
    </div>
</ng-template>
