import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

import {
    AboutPageQueryService,
    CustomerServicePage,
    CustomerServicePageQueryService,
    HomePage,
    HomePageQueryService,
    HowDoesItWorkPage,
    TermsAndConditionsQuery,
    TermsAndConditionsQueryService,
    VatQueryService,
} from '@app-graphql'
import { WindowService } from '@app-services/window/window.service'

@Injectable({
    providedIn: 'root',
})
export class ContentService {

    constructor(
        private aboutPageQueryService: AboutPageQueryService,
        private customerServicePageQueryService: CustomerServicePageQueryService,
        private homePageQueryService: HomePageQueryService,
        private termsAndConditionsQueryService: TermsAndConditionsQueryService,
        private vatQueryService: VatQueryService,
        private windowService: WindowService,
    ) {
    }

    public getVatPercentage() {
        return this.vatQueryService.fetch()
    }

    public getHomePage(): Observable<HomePage> {
        const context = this.windowService.getHostname()
        return this.homePageQueryService.fetch({ onContext: context }).pipe(
            map((data) => data.data.homePage),
        ) as unknown as Observable<HomePage>
    }

    public getAboutPage(): Observable<HowDoesItWorkPage> {
        const context = this.windowService.getHostname()
        return this.aboutPageQueryService.fetch({ onContext: context }).pipe(
            map((data) => data.data.howDoesItWorkPage),
        ) as unknown as Observable<HowDoesItWorkPage>
    }

    public getCustomerServicePage(): Observable<CustomerServicePage> {
        const context = this.windowService.getHostname()
        return this.customerServicePageQueryService.fetch({ onContext: context }).pipe(
            map((data) => data.data.customerServicePage),
        ) as unknown as Observable<CustomerServicePage>
    }

    public getTermAndCondition(
        type: string,
        rentalPartnerId?: string,
    ): Observable<ApolloQueryResult<TermsAndConditionsQuery>> {
        if (rentalPartnerId) {
            return this.termsAndConditionsQueryService.fetch({ rentalPartnerId, type })
        }

        return this.termsAndConditionsQueryService.fetch({ type })
    }

}
