import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog'
import { forkJoin, firstValueFrom } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators'

import { TermsModuleComponent } from '@app-components'
import { ReisbalansCustomer } from '@app-graphql'
import { FilterService, CheckoutService, ContentService, ListingService } from '@app-services'

@Component({
    selector: 'app-checkout-complete-order-card',
    templateUrl: './checkout-complete-order-card.component.html',
    styleUrls: ['./checkout-complete-order-card.component.scss'],
    providers: [DialogService],
})
export class CheckoutCompleteOrderCardComponent implements OnInit, OnDestroy {

    public countries: Array<{ name: string, code: string }>
    public formState: FormGroup
    public rentalPartnerTermsUrl: string
    public tripManagerTermsUrl: string
    public loading = true
    public formIsSubmitting = false
    public ref: DynamicDialogRef

    constructor(
        private fb: FormBuilder,
        private translate: TranslateService,
        private checkoutService: CheckoutService,
        private router: Router,
        private listingService: ListingService,
        private contentService: ContentService,
        private filterService: FilterService,
        public dialogService: DialogService,
    ) {
    }

    public ngOnInit(): void {
        const partnerVehicleId = this.checkoutService.state.rental.getValue().partnerVehicleId
        const partnerLocationId = this.checkoutService.state.rental.getValue().partnerLocationId

        // Ensuring the vehicle details are fetched first
        this.listingService.getDetail(partnerVehicleId, partnerLocationId).pipe(
            switchMap((vehicle) => {
                const rentalPartnerId = vehicle.data.partnerVehicleById?.rentalPartner?.id
                // Using forkJoin to wait for both terms to be fetched
                return forkJoin({
                    rentalTerms: this.contentService.getTermAndCondition(
                        'rental_partner_terms_and_conditions',
                        rentalPartnerId,
                    ),
                    generalTerms: this.contentService.getTermAndCondition(
                        'tripmanager_general_terms_and_conditions',
                    ),
                });
            }),
            tap((results) => {
                this.rentalPartnerTermsUrl = results.rentalTerms.data.TermsAndConditions?.file
                this.tripManagerTermsUrl = results.generalTerms.data.TermsAndConditions?.file
            }),
            catchError((error) => {
                console.error('Error loading terms', error)
                return []
            }),
        ).subscribe({
            next: () => this.loading = false,
            error: (error) => {
                console.error('Failed to load data', error)
                this.loading = false
            },
        });

        this.countries = [
            // { name: this.translate.instant('complete.destinationCountry'), code: '' },
            { name: this.translate.instant('general.countries.netherlands'), code: 'NL' },
            { name: this.translate.instant('general.countries.belgium'), code: 'BE' },
            { name: this.translate.instant('general.countries.luxembourg'), code: 'LU' },
            { name: this.translate.instant('general.countries.denmark'), code: 'DK' },
            { name: this.translate.instant('general.countries.germany'), code: 'DE' },
            { name: this.translate.instant('general.countries.finland'), code: 'FI' },
            { name: this.translate.instant('general.countries.france'), code: 'FR' },
            { name: this.translate.instant('general.countries.italy'), code: 'IT' },
            { name: this.translate.instant('general.countries.norway'), code: 'NO' },
            { name: this.translate.instant('general.countries.austria'), code: 'AU' },
            { name: this.translate.instant('general.countries.spain'), code: 'ES' },
            { name: this.translate.instant('general.countries.sweden'), code: 'SE' },
            { name: this.translate.instant('general.countries.switzerland'), code: 'CH' },
        ]

        this.initFormState()
        this.initFormStateListeners()
        this.checkoutService.state.stepIsAccessible.personalInformation.next(false)
        this.checkoutService.state.stepIsAccessible.finalize.next(false)
    }

    public ngOnDestroy(): void {
        if (this.ref) {
            this.ref.close();
        }
    }

    public showTermsAndConditions(): void {
        this.ref = this.dialogService.open(TermsModuleComponent, {
            data: {},
            header: this.translate.instant('conditions.title'),
            width: '70%',
            contentStyle: { height: '70vh', overflow: 'auto' },
            baseZIndex: 10000,
        })
    }

    public initFormState(): void {
        // TODO: when employeeId is available add this to the referralCode
        const {
            organizationUuid,
            employeeUuid,
        } = this.checkoutService.state.reisBalansCustomer.getValue() as ReisbalansCustomer
        const referralCode = organizationUuid && organizationUuid.length && employeeUuid && employeeUuid.length
            ? `${organizationUuid}_${employeeUuid}`
            : ''
        this.formState = this.fb.group({
            referralCode: [referralCode],
            remarks: [''],
            destinationCountry: ['', Validators.required],
            acceptConditions: ['', Validators.required],
        })
    }

    public initFormStateListeners(): void {
        this.formState.valueChanges.subscribe((formState) => {
            for (const [key, val] of Object.entries(formState)) {
                let value = val
                if (key === 'destinationCountry' && Array.isArray(val)) {
                    value = val.map((v) => v.code).join(', ')
                }
                this.checkoutService.setRentalValue({ name: key, value })
            }
        })
    }

    public async submitRental(formIsValid: boolean): Promise<void> {
        if (! formIsValid) {
            document.querySelector<HTMLInputElement>('ng-invalid')?.reportValidity()
            return
        }

        this.formIsSubmitting = true
        const rental = this.checkoutService.runCheckoutProcess()

        firstValueFrom(await rental).then((res) => {
            const dataObject = res.data?.createRental?.id as string

            if (dataObject === 'error') {
                location.href = '/checkout/confirmation/error'
            } else {
                location.href = '/checkout/confirmation/completed'
            }

            this.filterService.clearFilterParams()
            this.checkoutService.clearRentalValues()
            this.checkoutService.state.cartClearedState.next(true)
            this.formIsSubmitting = false
        })
    }

}
