import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';

import { GeolocationService, FilterService } from '@app-services';

@Component({
    selector: 'app-order-car-cta',
    templateUrl: './order-car-cta.component.html',
    styleUrls: ['./order-car-cta.component.scss'],
})
export class OrderCarCtaComponent implements OnInit {

    public formState: FormGroup;
    public filterStoreParams = this.filterService.getFilter().getValue();

    constructor(
        private geolocationService: GeolocationService,
        private fb: FormBuilder,
        private filterService: FilterService,
        private router: Router,
    ) {}

    public ngOnInit(): void {
        this.formState = this.fb.group({
            search: [this.filterStoreParams.search, Validators.required],
        });
    }

    public filterBySearchLocation(): void {
        this.filterService.setFilterValue({ name: 'search', value: this.formState.controls.search.value });
        this.filterService.setFilterValue({ name: 'dateTimeFrom', value: moment().add(7, 'days') });
        this.filterService.setFilterValue({ name: 'dateTimeTo', value: moment().add(8, 'days') });
        this.geolocationService.getGeoCode({ address: this.formState.controls.search.value, countryCode: 'nl' });
        this.geolocationService.state.currentCoords.subscribe((res) => {
            if (res.longitude) {
                console.log(res);
                this.router.navigate(['filter']);
            }
        });
    }

    public filterByCurrentLocation(): void {
        this.geolocationService.getCurrentLocationByNavigator();
        this.geolocationService.state.address.subscribe((res) => {
            console.log(res);
            this.formState.controls.search.setValue(res);
        });
    }

}
