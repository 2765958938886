import { Injectable } from '@angular/core';

export interface ThemeColor {
    name: string;
    hexValue: string;
}

@Injectable({
    providedIn: 'root',
})
export class ThemeService {

    public defineThemeColor(color: ThemeColor): void {
        const rgb = this.hexToRGB(color.hexValue);
        const contrastRatio = (rgb.r + rgb.g + rgb.b) / (255 * 3);

        document.documentElement.style.setProperty(`--color-${color.name}`, color.hexValue);
        document.documentElement.style.setProperty(
            `--color-${color.name}-rgb`,
            `${rgb.r},${rgb.g},${rgb.b}`,
        );
        document.documentElement.style.setProperty(`--color-${color.name}-tint`, this.changeTint(color.hexValue, 36));
        document.documentElement.style.setProperty(`--color-${color.name}-shade`, this.changeTint(color.hexValue, -36));
        document.documentElement.style.setProperty(
            `--color-${color.name}-contrast`,
            contrastRatio > 0.55 ? '#000000' : '#FFFFFF',
        );
    }

    private hexToRGB(hex: string): { r: number, g: number, b: number } {
        return {
            r: parseInt(hex.slice(1, 3), 16),
            g: parseInt(hex.slice(3, 5), 16),
            b: parseInt(hex.slice(5, 7), 16),
        };
    }

    private changeTint(hex: string, amount: number): string {
        const clamp = (val) => Math.min(Math.max(val, 0), 0xFF);
        const fill = (str) => (`00${str}`).slice(-2);

        const num = parseInt(hex.substring(1), 16);
        const red = clamp((num >> 16) + amount);
        const green = clamp(((num >> 8) & 0x00FF) + amount);
        const blue = clamp((num & 0x0000FF) + amount);

        return `#${fill(red.toString(16))}${fill(green.toString(16))}${fill(blue.toString(16))}`;
    }

}
