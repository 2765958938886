import { Component, OnInit } from '@angular/core'
import { map, mergeMap, tap } from 'rxjs/operators'

import { PartnerVehicle, ProductRule } from '@app-graphql'
import { ContentService, ListingService } from '@app-services'

@Component({
    selector: 'app-terms-module',
    templateUrl: './terms-module.component.html',
    styleUrls: ['./terms-module.component.scss'],
})
export class TermsModuleComponent implements OnInit {

    public termAndConditionFile: any
    public productRules: ProductRule[]
    public selectedVehicle: Partial<PartnerVehicle>

    constructor(
        private listingService: ListingService,
        private contentService: ContentService,
    ) {
    }

    public ngOnInit(): void {
        this.listingService.state.selectedVehicle.pipe(
            tap((res) => {
                this.selectedVehicle = res.data.partnerVehicleById as unknown as Partial<PartnerVehicle>
            }),
            tap((res) => {
                this.productRules = res.data.partnerVehicleById?.productRules
            }),
            map((selectedVehicle) => {
                const id = selectedVehicle.data.partnerVehicleById?.rentalPartner?.id as string
                return id
            }),
            mergeMap((id) => this.contentService.getTermAndCondition('rental_partner_terms_and_conditions', id)),
        ).subscribe((res) => {
            this.termAndConditionFile = res.data.TermsAndConditions?.file
        })
    }

}
