import { Injectable } from '@angular/core'
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs'

import {
    Geocode,
    GeocodeInput,
    GeoCodeMutationService,
    ReverseGeocodeInput,
    ReverseGeocodeMutationService,
} from '@app-graphql'
import { FilterService } from '@app-services/filter/filter.service'
import { LocalStorageService } from '@app-services/localstorage/localstorage.service'

export interface Coords {
    latitude: string;
    longitude: string;
}

@Injectable({
    providedIn: 'root',
})
export class GeolocationService {

    public firstTimeRunningServiceForCurrentCoords = true

    public state = {
        loading: new BehaviorSubject(false),
        address: new BehaviorSubject(''),
        currentCoords: new BehaviorSubject<Coords>({ latitude: '', longitude: '' }),
    }

    constructor(
        private geoCodeMutationService: GeoCodeMutationService,
        private reverseGeocodeMutationService: ReverseGeocodeMutationService,
        private filterService: FilterService,
        private localStorageService: LocalStorageService,
    ) {
    }

    public getCurrentCoords(): BehaviorSubject<Coords> {
        if (this.firstTimeRunningServiceForCurrentCoords) {
            this.firstTimeRunningServiceForCurrentCoords = false
            const localStorageData = this.localStorageService.getOnLocalStorage('local_GeolocationService_coords')
            if (localStorageData) {
                this.state.currentCoords.next(localStorageData)
            }
        }
        return this.state.currentCoords
    }

    public getGeoCode(address: GeocodeInput): Subscription {
        this.state.loading.next(true)
        return this.geoCodeMutationService.mutate({ input: address }).subscribe((res) => {
            const response = res.data?.geocode as Geocode
            const coords: Coords = { latitude: String(response.latitude), longitude: String(response.longitude) }
            if (response.latitude === null) {
                coords.latitude = '0'
                coords.longitude = '0'
            }
            this.state.currentCoords.next(coords)
            this.localStorageService.storeOnLocalStorage({ name: 'local_GeolocationService_coords', value: coords })
            this.state.loading.next(false)
        })
    }

    public getReverseGeoCode(coords: ReverseGeocodeInput): void {
        this.state.loading.next(true)
        this.reverseGeocodeMutationService.mutate({ input: coords }).subscribe((res) => {
            const response = res.data?.reverseGeocode.address as string
            console.log(response)
            this.state.address.next(response)
            this.localStorageService.storeOnLocalStorage(
                {
                    name: 'local_GeolocationService_address',
                    value: { address: response },
                },
            )
            this.filterService.setFilterValue({ name: 'search', value: response })
            this.state.loading.next(false)
        })
    }

    public getCurrentLocationByNavigator(): void {
        this.state.loading.next(true)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((location) => {
                const coords: Coords = {
                    latitude: String(location.coords.latitude),
                    longitude: String(location.coords.longitude),
                }
                if (location.coords.latitude === null) {
                    coords.latitude = '0'
                    coords.longitude = '0'
                }
                this.state.currentCoords.next(coords)
                this.getReverseGeoCode(coords)
                this.state.loading.next(false)
            })
        } else {
            /* TODO: change to toast or dialog */
            console.log('Couldn\'t find your location')
        }
    }

    public async getGeoCodeSync(address: GeocodeInput): Promise<Coords | null> {
        this.state.loading.next(true)

        const res = await firstValueFrom(this.geoCodeMutationService.mutate({ input: address }))

        const response = res.data?.geocode as Geocode
        const coords: Coords = {
            latitude: String(response.latitude),
            longitude: String(response.longitude),
        }
        if (response.latitude === null) {
            coords.latitude = '0'
            coords.longitude = '0'
        }

        this.state.currentCoords.next(coords)
        this.localStorageService.storeOnLocalStorage({ name: 'local_GeolocationService_coords', value: coords })

        this.state.loading.next(false)

        return coords
    }
}
