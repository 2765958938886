import { NgModule } from '@angular/core';

import { ComponentsSharedModule } from '@app-components/components-shared.module';
import { FormComponentsModule } from '@app-components/form/form-components.module';
import { OrderComponentsModule } from '@app-components/order/order-components.module';
import {
    CheckoutCompleteOrderCardComponent,
    CheckoutCustomerAuthCardComponent,
    CheckoutCustomerDataCardComponent,
    CheckoutDeliveryFormCardComponent,
    CheckoutExtrasCardComponent,
    CheckoutOrderProcessedComponent,
    CheckoutOrderUnprocessedComponent,
    CheckoutReservationsNavigatorComponent,
} from '@app-components'

const checkoutComponents = [
    CheckoutCompleteOrderCardComponent,
    CheckoutCustomerAuthCardComponent,
    CheckoutCustomerDataCardComponent,
    CheckoutDeliveryFormCardComponent,
    CheckoutExtrasCardComponent,
    CheckoutOrderProcessedComponent,
    CheckoutOrderUnprocessedComponent,
    CheckoutReservationsNavigatorComponent,
];

@NgModule({
    imports: [
        ComponentsSharedModule,
        FormComponentsModule,
        OrderComponentsModule,
    ],
    exports: [
        ...checkoutComponents,
    ],
    declarations: [
        ...checkoutComponents,
    ],
})
export class CheckoutComponentsModule {}
