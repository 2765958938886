import { Injectable } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms'

@Injectable({
    providedIn: 'root',
})
export class FormHelperService {

    public confirmPasswordValidator(control: AbstractControl): ValidatorFn {
        const passwordControl = control.get('password')
        const confirmPasswordControl = control.get('confirmPassword') || control.get('password_confirmation')

        const currentErrors = confirmPasswordControl.errors

        if (passwordControl.value !== confirmPasswordControl.value) {
            confirmPasswordControl.setErrors({ ...currentErrors, notMatching: true })
            return () => ({ notMatching: true })
        }

        return () => null
    }

    public reportFormErrors(formGroup: FormGroup | AbstractControl[]): void {
        const controls = formGroup instanceof FormGroup ? Object.values(formGroup.controls) : formGroup
        controls.forEach((control: FormControl | FormGroup) => {
            if (control instanceof FormGroup) {
                this.reportFormErrors(control)
            } else {
                control.markAsDirty()
            }
        })
    }

}
