<ng-container *ngIf="isAuthenticated === true && user?.id">
    <h3>Account</h3>
    <p>
        U bent ingelogd als <strong>{{ user.email }}</strong>
        (<a href="javascript:void(0)" class="link" (click)="logout()">uitloggen</a>)
    </p>

    <app-checkout-customer-data-card [user]="user" [needsRegistrationToRent]="true" />

</ng-container>

<ng-container *ngIf="isAuthenticated === false">

    <ng-container>
        <h3>Account</h3>
        <p>Heeft u al een account of wilt u een nieuwe aanmaken?</p>

        <p-button styleClass="p-button-rounded p-button-primary mr-2"
                  routerLink="/auth/login"
                  [queryParams]="{ returnUrl: '/checkout/customer-data' }"
        >
            Inloggen
        </p-button>
        <p-button styleClass="p-button-rounded p-button-primary"
                  routerLink="/auth/register"
                  [queryParams]="{ returnUrl: '/checkout/customer-data' }"
        >
            Registreren
        </p-button>
    </ng-container>

</ng-container>
