import { NgModule } from '@angular/core';

import { ComponentsSharedModule } from '@app-components/components-shared.module';
import { CustomerServiceFormComponent, InputErrorComponent } from '@app-components';

@NgModule({
    imports: [
        ComponentsSharedModule,
    ],
    exports: [
        CustomerServiceFormComponent,
        InputErrorComponent,
    ],
    declarations: [
        CustomerServiceFormComponent,
        InputErrorComponent,
    ],
})
export class FormComponentsModule {}
