<div class="confirmation-card">
    <div class="confirmation-card__title">
        <h1>{{ "orderConfirmation.thankYou" | translate }}</h1>
    </div>
    <p class="sub-title mb-6">{{ "orderConfirmation.confirmation" | translate }}</p>

    <p-button
        (click)="backToHomepage()"
        type="button"
        [label]="'orderConfirmation.backTo' | translate"
         styleClass="p-button-rounded p-button-primary p-button--full-width mb-6" />

</div>
