import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

import { PartnerVehicle } from '@app-graphql'

@Component({
    selector: 'app-car-delivery-image',
    templateUrl: './car-delivery-image.component.html',
    styleUrls: ['./car-delivery-image.component.scss'],
})
export class CarDeliveryImageComponent implements OnInit {

    @Input()
    public car: Partial<PartnerVehicle>

    public image: any

    constructor(private sanitizer: DomSanitizer) {
        //
    }

    public ngOnInit(): void {
        if (this.car) {
            this.image = this.car.vehicleAttribute.image
                ? this.sanitizer.bypassSecurityTrustResourceUrl(this.car.vehicleAttribute.image)
                : ''
        }
    }

    public openProductRulesTab(): void {
        const productRulesTabButtonElement = document.querySelector('.car-detail-tabs [data-pc-index="1"]')

        productRulesTabButtonElement?.dispatchEvent(new Event('click'))
        productRulesTabButtonElement?.scrollIntoView({ behavior: 'smooth' })
    }
}
