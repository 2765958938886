import { Pipe, PipeTransform } from '@angular/core'
import { path } from 'ramda'

import { MobilityPartner as ServiceProvider } from '@app-graphql'
import { DomainService } from '@app-services'

@Pipe({
    name: 'serviceProvider',
    pure: true,
})
export class ServiceProviderPipe implements PipeTransform {

    private serviceProvider: ServiceProvider

    constructor(
        private domainService: DomainService,
    ) {
        this.domainService.state.serviceProvider.subscribe((serviceProvider) => {
            this.serviceProvider = serviceProvider
        })
    }

    public transform(propertyPath: keyof ServiceProvider | string): keyof ServiceProvider | any | undefined {
        return path(propertyPath.split('.'), this.serviceProvider)
    }

}
