<div class="header-margin"></div>

<header class="header">
    <div class="outer-container">
        <div class="header-container">

            <div class="header__logo__container">
                <img *ngIf="'logo' | serviceProvider"
                     [alt]="'name' | serviceProvider"
                     [routerLink]="['/']"
                     [src]="'logo' | serviceProvider"
                     class="header__logo"
                />

                <div class="header__logo__powered-by">
                    <div>Powered by</div>
                    <img src="/assets/img/tripmanager-logo.svg" alt="" />
                </div>
            </div>

            <div class="desktop-menu">
                <a class="header__link mr-4"
                   [routerLink]="['/about']"
                   routerLinkActive="active"
                   [routerLinkActiveOptions]="{ exact: true }"
                >
                    {{ 'navigation.howDoesItWork' | translate }}
                </a>
                <a class="header__link mr-4"
                   [routerLink]="['/customer-service']"
                   routerLinkActive="active"
                   [routerLinkActiveOptions]="{ exact: true }"
                >
                    {{ 'navigation.customerService' | translate }}
                </a>
                <a *ngIf="'showLocationsPage' | serviceProvider"
                   class="header__link mr-4"
                   [routerLink]="['/locations']"
                   routerLinkActive="active"
                   [routerLinkActiveOptions]="{ exact: true }"
                >
                    {{ 'navigation.locations' | translate }}
                </a>
                <span class="header__seperator"></span>
                <button pButton
                        class="p-button p-button-small p-button-rounded ml-4"
                        [routerLink]="['/']"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                >
                    {{ 'navigation.findACar' | translate }}
                </button>

                <p-button *ngIf="'needsRegistrationToRent' | serviceProvider"
                          icon="pi pi-user"
                          styleClass="p-button-secondary p-button-rounded ml-2"
                          [routerLink]="['/profile/index']"
                          routerLinkActive="active"
                          [routerLinkActiveOptions]="{ exact: true }"
                />
            </div>

            <div class="mobile-nav">
                <p-button *ngIf="'needsRegistrationToRent' | serviceProvider"
                          icon="pi pi-user"
                          styleClass="p-button-secondary p-button-rounded ml-2 mr-4"
                          [routerLink]="['/profile/index']"
                          routerLinkActive="active"
                          [routerLinkActiveOptions]="{ exact: true }"
                />

                <div class="hamburger" (click)="menuOpen = ! menuOpen">
                    <div class="line line1"></div>
                    <div class="line line2"></div>
                    <div class="line line3"></div>
                </div>
            </div>

        </div>
    </div>

    <p-sidebar class="mobile-menu"
               [modal]="false"
               [(visible)]="menuOpen"
               position="right"
               [baseZIndex]="20000"
    >
        <a class="header__link mr-4"
           (click)="menuOpen = false"
           [routerLink]="['/about']"
           [routerLinkActiveOptions]="{ exact: true }"
           routerLinkActive="active"
        >
            {{ 'navigation.howDoesItWork' | translate }}
        </a>

        <a class="header__link mr-4"
           (click)="menuOpen = false"
           [routerLink]="['/customer-service']"
           [routerLinkActiveOptions]="{exact: true}"
           routerLinkActive="active"
        >
            {{ 'navigation.customerService' | translate }}
        </a>

        <a class="header__link mr-4"
           (click)="menuOpen = false"
           [routerLink]="['/locations']"
           [routerLinkActiveOptions]="{exact: true}"
           routerLinkActive="active"
        >
            {{ 'navigation.locations' | translate }}
        </a>

        <span class="header__seperator"></span>

        <a class="header__link header__link--black mr-4"
           (click)="menuOpen = false"
           [routerLink]="['/']"
           [routerLinkActiveOptions]="{ exact: true }"
           routerLinkActive="active"
        >
            {{ 'navigation.findACar' | translate }}
        </a>

    </p-sidebar>

    <div class="sidebar-backdrop"
         [class.visible]="menuOpen"
         (click)="menuOpen = false"
    ></div>

</header>
