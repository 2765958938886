<section>
    <div class="grid">
        <ng-container *ngFor="let productRule of productRules">
            <div class="col-12 lg:col-4">
                <div class="px-2 mb-4">
                    <div class="flex flex-row align-items-center gap-3">
                        <img [src]="productRule.icon" alt="" />
                        <h3 class="font-bold">{{ productRule.title }}</h3>
                    </div>
                    <p>{{ productRule.description }}</p>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="flex gap-3 justify-content-center mb-4">
        <a class="btn"
           *ngIf="selectedVehicle"
           [href]="termAndConditionFile" target="_blank">
            {{ 'complete.conditions' | translate }} {{ 'general.from' | translate }} {{ selectedVehicle!.rentalPartner?.name }}
        </a>
        <a class="btn"
           href="/assets/pdf/tripmanager-privacy.pdf" target="_blank">
            {{ 'complete.conditions' | translate }} {{ 'complete.tripmanager' | translate }}
        </a>
    </div>
</section>
