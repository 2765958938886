<p-confirmDialog [style]="{width: '75vw'}" [baseZIndex]="10000"
                 rejectButtonStyleClass="p-button-text" />
<p-toast />

<ng-container *ngIf="rentals.length; else noRentals">
    <ng-container *ngFor="let rental of rentals;">
        <p-card class="order-history-card"
                [header]="
                    rental.partnerVehicle.vehicleMake.name
                    + ' ' + rental.partnerVehicle.vehicleModel.name
                "
                styleClass="mb-5"
        >
            <p class="mt-0">{{ 'detailPage.orSimilar' | translate }}</p>

            <ng-container [ngSwitch]="rental.rentalStatus">
                <p-tag *ngSwitchCase="RentalStatusEnum.PENDING"
                       severity="warning"
                       [value]="('rental.status.' + rental.rentalStatus) | translate"
                />
                <p-tag *ngSwitchCase="RentalStatusEnum.ACCEPTED"
                       severity="success"
                       [value]="('rental.status.' + rental.rentalStatus) | translate"
                />
                <p-tag *ngSwitchCase="RentalStatusEnum.CANCELLED"
                       severity="danger"
                       [value]="('rental.status.' + rental.rentalStatus) | translate"
                />
                <p-tag *ngSwitchCase="RentalStatusEnum.DECLINED"
                       severity="info"
                       [value]="('rental.status.' + rental.rentalStatus) | translate"
                />
                <p-tag *ngSwitchCase="RentalStatusEnum.FINALIZED"
                       severity="success"
                       [value]="('rental.status.' + rental.rentalStatus) | translate"
                />
            </ng-container>

            <p class="my-0">Ordernummer:
                <span [ngStyle]="{color: 'var(--color-primary)'}">
                    {{ rental.bookingNumber }}
                </span>
            </p>
            <p class="my-0">Datum:
                <span [ngStyle]="{color: 'var(--color-primary)'}">
                    {{ rental.createdAt | date : 'E d MMMM yyyy - HH:mm' }}
                </span>
            </p>

            <p class="mt-2 mb-0 subtitle">
                <span class="pi pi-car"></span> Voertuig
            </p>
            <div class="grid">
                <div class="col-8">
                    <div class="flex flex-wrap gap-2">
                        <div class="flex align-items-center gap-2">
                            <div class="flex align-items-center gap-2">
                                <p class="order-history-card__title">
                                    {{ rental.partnerVehicle.vehicleType.name }}
                                </p>
                            </div>
                        </div>
                        <div class="flex align-items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="16" height="16">
                                <path fill="#495057"
                                      d="M24 8L26 8 26 27 6 27 6 8 8 8 8 6C8 4.9 8.9 4 10 4L22 4C23.1 4 24 4.9 24 6L24 8ZM22.1 8L22.1 7C22.1 6.4 21.7 6 21.1 6L10.9 6C10.3 6 9.9 6.4 9.9 7L9.9 8 22.1 8ZM28 8L30 8C31.1 8 32 8.9 32 10L32 25C32 26.1 31.1 27 30 27L28 27 28 8ZM4 8L4 27 2 27C0.9 27 0 26.1 0 25L0 10C0 8.9 0.9 8 2 8L4 8Z"></path>
                            </svg>
                            {{ rental.partnerVehicle.vehicleAttribute.numOfSuitcases ?? 'Onbekend' }}
                        </div>
                        <div class="flex align-items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32">
                                <path fill="#495057"
                                      d="M14.1 0L24.4 0C25.8 0 27 1.1 27 2.6L27 29.4C27 30.9 25.8 32 24.4 32L7.6 32C6.2 32 5 30.9 5 29.4L5 14 11.5 2.6C12 1.6 12.6 0 14.1 0ZM10 14L23 14 23 4 15 4 10 14ZM17 19L23 19 23 17 17 17 17 19Z"></path>
                            </svg>
                            {{ rental.partnerVehicle.vehicleAttribute.doors }}
                        </div>
                        <div class="flex align-items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                <path fill="#495057"
                                      d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z" />
                            </svg>
                            {{ rental.partnerVehicle.vehicleAttribute.numOfPersons ?? 'Onbekend' }}
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <a [routerLink]="'/detail/' + rental.partnerVehicle.id + '/' + rental.partnerLocation.id">
                        <img [src]="rental.partnerVehicle.vehicleAttribute.image" alt="Tripmanager auto image" />
                    </a>
                </div>
            </div>
            <p class="mt-3 mb-0 subtitle"><span class="pi pi-map-marker"></span> Verhuurlocatie</p>
            <p class="my-0">{{ rental.partnerLocationAddress }}</p>

            <p class="mt-3 mb-0 subtitle"><span class="pi pi-calendar"></span> Datum</p>
            <p class="my-0">
                {{ rental.dateTimeFrom | date : 'E d MMMM yyyy - HH:mm' }}
                -
                {{ rental.dateTimeTo | date : 'E d MMMM yyyy - HH:mm' }}
            </p>

            <p class="mt-3 mb-0 subtitle">
                <span class="pi pi-money-bill"></span> Prijs
            </p>

            <ng-container *ngIf="rental.partnerVehicle.discountPercentage > 0">
                <div class="flex justify-content-between">
                    <p class="my-0">Prijs verhuurperiode excl korting</p>
                    <p class="my-0">
                        {{ (rental.priceExVat * +('1.' + (rental.partnerVehicle.discountPercentage ?? 0))) | currency : 'EUR' }}
                    </p>
                </div>

                <div class="flex justify-content-between">
                    <p class="my-0">Korting</p>
                    <p class="my-0">{{ rental.partnerVehicle.discountPercentage }}%</p>
                </div>

                <div class="flex justify-content-between">
                    <p class="my-0">Verhuurperiode incl korting</p>
                    <p class="my-0">{{ rental.priceExVat | currency : 'EUR' }}</p>
                </div>
            </ng-container>

            <div class="flex justify-content-between">
                <p class="my-0">Prijs verhuurperiode excl</p>
                <p class="my-0">{{ rental.rentalPrice.priceOnInterval | currency : 'EUR' }}</p>
            </div>

            <div class="flex justify-content-between">
                <p class="my-0">Btw bedrag ({{ rental.vat }}%)</p>
                <p class="my-0">{{ (rental.totalPrice - rental.priceExVat) | currency : 'EUR' }}</p>
            </div>

            <p class="my-0">Extra's:</p>
            <div class="flex justify-content-between">
                <ul style="width: 100%">
                    <li *ngFor="let extra of rental.extras">
                        <div class="flex justify-content-between">
                            <p class="my-0">{{ extra.name }}</p>
                            <p class="my-0" *ngFor="let rate of extra.rates">
                                <span>{{ rate.pricePerDay | currency : 'EUR' }}&nbsp;per&nbsp;dag</span>
                            </p>
                        </div>
                    </li>
                </ul>
            </div>

            <hr />

            <div class="flex justify-content-between">
                <p class="my-0 car-info-card__title text-lg">Totaal</p>
                <p class="my-0 car-info-card__title text-lg">{{ rental.totalPrice | currency : 'EUR' }}</p>
            </div>

            <ng-container *ngIf="rental.rentalStatus !== RentalStatusEnum.CANCELLED">
                <div class="flex align-items-center justify-content-between flex-row">
                    <!--                <button pButton class="p-button p-button-primary p-button-text mt-3 block">-->
                    <!--                    Opnieuw huren?-->
                    <!--                </button>-->

                    <button pButton class="p-button p-button-danger p-button-text mt-3 block"
                            (click)="confirmCancelRental(rental)"
                    >
                        Annuleren?
                    </button>
                </div>
            </ng-container>
        </p-card>
    </ng-container>
</ng-container>

<ng-template #noRentals>
    <p>Er zijn nog geen voertuigen gehuurd</p>
</ng-template>
