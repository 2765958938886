import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable()
export class LocalStorageService {

    constructor(
        @Inject(LOCAL_STORAGE) private storage: StorageService,
    ) {
    }

    public set(input: { name: string, value: any }): void {
        this.storage.set(input.name, input.value);
    }

    public storeOnLocalStorage(input: { name: string, value: any }): void {
        let currentValue = this.storage.get(input.name);
        currentValue = {
            ...input.value,
        };
        this.storage.set(input.name, currentValue);
    }

    public getOnLocalStorage(name: string) {
        return this.storage.get(name);
    }

    public remove(name: string): void {
        this.storage.remove(name);
    }

}
