import { NgModule } from '@angular/core';

import { ComponentsSharedModule } from '@app-components/components-shared.module';
import { FooterComponent, HeaderComponent } from '@app-components';
import { CheckoutComponentsModule } from '@app-components/checkout/checkout-components.module';

@NgModule({
    imports: [
        ComponentsSharedModule,
        CheckoutComponentsModule,
    ],
    exports: [
        FooterComponent,
        HeaderComponent,
    ],
    declarations: [
        FooterComponent,
        HeaderComponent,
    ],
})
export class TemplateComponentsModule {}
