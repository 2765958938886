import { HTTP_INTERCEPTORS, HttpHeaders } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core'
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'

import { environment } from '@app-environment'
import { AuthInterceptor } from '@app-services'

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {

    const uri = environment.graphQLEndpoint

    return {
        link: httpLink.create({
            uri,
            headers: new HttpHeaders({
                'Accept-Language': 'nl',
                'X-Client-Id': environment.clientId,
            }),
        }),
        cache: new InMemoryCache(),
    }
}

@NgModule({
    providers: [
        Apollo,
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor.withExcludedOperationNames([
                'forgotPassword',
                'login',
                'register',
                'resendEmailVerification',
                'resetPassword',
                'verifyEmail',
            ]),
            multi: true,
        },
        //{
        //    provide: HTTP_INTERCEPTORS,
        //    useClass: LocaleInterceptor,
        //    multi: true,
        //},
    ],
})
export class GraphQLModule {
}
