<section class="filter-card">

    <form *ngIf="formState" [formGroup]="formState">
        <div class="filter-card-content">

            <h1 class="page-title">
                {{ "filterPage.title" | translate }}
            </h1>

            <h2 class="filter-card__title mb-2">
                {{ "filterPage.filter.location" | translate }}
            </h2>

            <div class="mb-4">
                <div class="p-input-icon-left mr-3">
                    <i class="pi pi-search"></i>
                    <input formControlName="search"
                           type="text"
                           pInputText
                           [placeholder]="'filterPage.search' | translate"
                    />
                </div>
                <div class="p-field-checkbox mt-3">
                    <input type="checkbox"
                           formControlName="dropOffOtherLocation"
                           value="true"
                           id="inleveren"
                    />
                    <label for="inleveren">
                        {{ 'filterPage.filter.locationDeliverSomewhereElse' | translate }}
                    </label>
                </div>
                <!--                <ng-container *ngIf="hasOtherDropOff">-->
                <!--                    <p class="mt-1" style="padding-left: 38px;line-height: 1rem">-->
                <!--                        <a href="/customer-service">-->
                <!--                            {{ 'filterPage.contactUs' | translate }}-->
                <!--                        </a>-->
                <!--                    </p>-->
                <!--                </ng-container>-->

                <p-confirmDialog class="dropOffDialog" #cd>
                    <ng-template pTemplate="header">
                        <h3>Auto inleveren bij andere verhuurlocatie?</h3>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <button type="button"
                                pButton
                                icon="pi pi-times"
                                label="Annuleren"
                                class="p-button-secondary"
                                (click)="cd.reject()"
                        ></button>
                        <button type="button"
                                pButton
                                icon="pi pi-check"
                                label="Contact opnemen"
                                routerLink="/customer-service"
                        ></button>
                    </ng-template>
                </p-confirmDialog>
            </div>

            <h2 class="filter-card__title mb-2">
                {{ "filterPage.filter.moment" | translate }}
            </h2>

            <div class="mb-2">
                <label class="mb-2">
                    {{ "filterPage.filter.startDate" | translate }}
                </label>
                <p-calendar formControlName="dateTimeFrom"
                            class="home-header__inputs"
                            dateFormat="D dd MM"
                            [minDate]="minDateTimeFrom"
                            [showIcon]="true"
                            [showTime]="true"
                            inputId="icon"
                />
            </div>
            <div class="mb-3">
                <label class="mb-2">
                    {{ "filterPage.filter.endDate" | translate }}
                </label>
                <p-calendar formControlName="dateTimeTo"
                            class="home-header__inputs"
                            dateFormat="D dd MM"
                            [minDate]="minDateTimeTo"
                            [showIcon]="true"
                            [showTime]="true"
                            inputId="icon"
                />
            </div>

            <h2 class="filter-card__title" [class.hidden]="'hasCustomVehicleTypeSelection' | serviceProvider">
                {{ "filterPage.filter.typeHeading" | translate }}
            </h2>
            <div class="grid mb-3 pt-3" [class.hidden]="'hasCustomVehicleTypeSelection' | serviceProvider">
                <div class="col-12">
                    <p-multiSelect [options]="filterGroups.Type" formControlName="vehicleType" />
                </div>
            </div>

            <!--            <h2 class="filter-card__title mb-2">-->
            <!--                {{ "filterPage.filter.maximumPrice" | translate }}-->
            <!--            </h2>-->
            <!--            <div class="mb-3">-->
            <!--                <input formControlName="maxPrice"-->
            <!--                       type="text"-->
            <!--                       pInputText-->
            <!--                       placeholder="0"-->
            <!--                       id="maximumPrice"-->
            <!--                />-->
            <!--            </div>-->

            <!--            <div class="mb-3">-->
            <!--                <h2 class="filter-card__title mb-2">-->
            <!--                    {{ "filterPage.kilometersCTA" | translate }}-->
            <!--                </h2>-->
            <!--                <p-inputNumber formControlName="extraKms"-->
            <!--                               suffix=" km"-->
            <!--                               placeholder="100 Km"-->
            <!--                />-->
            <!--            </div>-->

            <ng-container *ngIf="('customerType' | serviceProvider) !== CustomerTypeEnum.B2C">
                <h2 class="filter-card__title mb-2">
                    {{ "filterPage.filter.deliveryHeading" | translate }}
                </h2>

                <div class="mb-4">
                    <div class="p-field-radiobutton mb-3">
                        <p-radioButton formControlName="deliveryMethod"
                                       name="deliveryMethod"
                                       value="fetch"
                                       inputId="city1"
                                       checked="true"
                        />
                        <label for="city1">
                            {{ "filterPage.filter.delivery.supplier" | translate }}
                        </label>
                    </div>
                    <div class="p-field-radiobutton">
                        <p-radioButton formControlName="deliveryMethod"
                                       name="deliveryMethod"
                                       value="deliver"
                                       inputId="city2"
                        />
                        <label for="city2">
                            {{ "filterPage.filter.delivery.shipping" | translate }}
                        </label>
                    </div>
                </div>
            </ng-container>

            <div [class.hidden]="('name' | serviceProvider) === 'Centraal Beheer'">
                <h2 class="filter-card__title mb-2">
                    {{ "filterPage.filter.supplier" | translate }}
                </h2>
                <div class="mb-4">
                    <p-dropdown formControlName="supplier"
                                [options]="filterGroups.Aanbieder"
                                [placeholder]="'filterPage.allProviders' | translate"
                                optionLabel="label"
                                [showClear]="true"
                    />
                </div>
            </div>

            <div [class.hidden]="('name' | serviceProvider) === 'Centraal Beheer'">
                <div *ngIf="filterGroups?.Brandstoftype?.length > 1">
                    <h2 class="filter-card__title mb-2">
                        {{ "filterPage.filter.fuelType" | translate }}
                    </h2>
                    <div class="mb-4">
                        <p-dropdown formControlName="fuelType"
                                    [options]="filterGroups.Brandstoftype"
                                    [placeholder]="'filterPage.fuelType' | translate"
                                    optionLabel="label"
                                    [showClear]="true"
                        />
                    </div>
                </div>
            </div>

            <h2 class="filter-card__title mb-2">
                {{ "filterPage.filter.carModel" | translate }}
            </h2>

            <div class="mb-4">
                <p-dropdown formControlName="model"
                            [options]="filterGroups['Auto model']"
                            [placeholder]="'filterPage.carModel' | translate"
                            optionLabel="label"
                            [showClear]="true"
                />
            </div>

            <div *ngIf="filterGroups?.Transmissie?.length > 1">
                <h2 class="filter-card__title mb-2">
                    {{ "filterPage.filter.transmission" | translate }}
                </h2>
                <div class="mb-4">
                    <p-dropdown formControlName="transmission"
                                [options]="filterGroups.Transmissie"
                                [placeholder]="'filterPage.transmission' | translate"
                                optionLabel="label"
                                [showClear]="true"
                    />
                </div>
            </div>

            <h2 class="filter-card__title mb-2">
                {{ 'filterPage.VAT' | translate }}
            </h2>
            <div class="mb-4">
                <p-inputSwitch (onChange)="setVatIncluded($event.checked)"
                               formControlName="vatIncluded"
                />
            </div>
        </div>

        <div class="filter-sticky-footer">
            <button pButton
                    (click)="resetFilter()"
                    class="p-button p-button-text"
                    [label]="'filterPage.btnClearFilters' | translate"
            >
            </button>

            <button pButton
                    class="p-button p-button-rounded p-button--full-width"
                    [label]="'filterPage.btnSearch' | translate"
                    (click)="submitFilter()"
            >
            </button>
        </div>
    </form>
</section>
