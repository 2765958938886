import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { ListingService, CheckoutService, FilterService } from '@app-services';
import { VehicleExtra } from '@app-graphql';

@Component({
    selector: 'app-checkout-extras-card',
    templateUrl: './checkout-extras-card.component.html',
    styleUrls: ['./checkout-extras-card.component.scss'],
})
export class CheckoutExtrasCardComponent implements OnInit, OnDestroy {

    public formState: FormGroup;
    public vehicleId: string;
    public formIsValid = true;
    public vehicleExtras: VehicleExtra[];
    public subscription: Subscription;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private checkoutService: CheckoutService,
        private filterService: FilterService,
        private listingService: ListingService,
    ) {
    }

    public ngOnInit(): void {
        this.checkoutService.setRentalValue({ name: 'extras', value: [] });
        this.vehicleId = this.checkoutService.state.rental.getValue().partnerVehicleId;
        const locationId = this.checkoutService.state.rental.getValue().partnerLocationId;

        this.subscription = this.listingService.getDetail(this.vehicleId, locationId).subscribe((res) => {
            this.vehicleExtras = this.filterIrrelevantExtras(
                res.data.partnerVehicleById?.vehicleExtras as unknown as VehicleExtra[],
            );
            this.initFormState();
            this.initFormListeners();
        });
    }

    public ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    public initFormState(): void {
        const arr = new Array(this.vehicleExtras?.length || 0).fill(false);
        const checkedExtras = []

        // Enable free extras by default
        arr.forEach((_, i) => {
            const extra = this.vehicleExtras[i]
            if (! extra.rates[0].pricePerDay && ! extra.rates[0].oneTimeFee) {
                arr[i] = true;
                checkedExtras.push(extra.id)
            }
        })

        this.formState = this.fb.group({
            extras: this.fb.array(arr),
        });

        this.checkoutService.setRentalValue({ name: 'extras', value: checkedExtras })

    }

    public initFormListeners(): void {
        const vehicleExtras = (this.formState.controls.extras as FormArray);

        vehicleExtras.valueChanges.subscribe(() => {
            vehicleExtras.setValue(
                vehicleExtras.value.map((value: string, i: number) => (value ? this.vehicleExtras[i].id : false)),
                { emitEvent: false },
            );
        });

        this.formState.valueChanges.subscribe((formState) => {
            for (const [key, value] of Object.entries(formState)) {
                const extras = value as [];
                const clearExtras = extras.filter(Boolean);
                this.checkoutService.setRentalValue({ name: key, value: clearExtras });
            }
        });
    }

    public handleFormSubmit(formIsValid: boolean): void {
        if (! formIsValid) {
            document.querySelector<HTMLInputElement>('.ng-invalid')?.reportValidity();
            return;
        }

        if (this.formIsValid) {
            this.router.navigate(['/checkout/delivery']);
        }
    }

    private filterIrrelevantExtras(extras: VehicleExtra[]): VehicleExtra[] {
        const { deliveryMethod } = this.filterService.state.filterParams.value;

        // Hide deliver/pickup extras in case 'pick up at dealer' was selected
        if (deliveryMethod === 'fetch') {
            return extras.filter((extra) => ! (/aflever|delivery|ophaal|pickup/i).test(extra.name));
        }

        return extras;
    }

}
