<ng-template #loader>
    <div class="loader">
        <p-progressSpinner [style]="{ width: '30px', height: '30px' }"></p-progressSpinner>
    </div>
</ng-template>

<div [formGroup]="formState" class="form-container">

    <h1 class="form__title">{{ 'complete.title' | translate }}</h1>
    <p>{{ 'complete.description' | translate }}</p>

    <ng-container *ngIf="! loading; else loader">

        <div class="inputs-container">
            <input pInputText
                   formControlName="referralCode"
                   id="referralCode"
                   type="hidden">

            <label for="remark">{{ 'complete.remark' | translate }}</label>
            <textarea pInputTextarea
                      formControlName="remarks"
                      id="remark"
                      class="mb-3"
                      [placeholder]="'complete.remarkPlaceHolder' | translate"></textarea>

            <label for="destinationCountry">{{ 'complete.destinationCountry' | translate }} *</label>
            <p-multiSelect [options]="countries"
                           formControlName="destinationCountry"
                           [placeholder]="'complete.destinationCountry' | translate"
                           optionLabel="name"
                           id="destinationCountry"
                           display="chip"
            >
            </p-multiSelect>
            <app-input-error
                    *ngIf="! formState.get('destinationCountry')?.valid"
                    [error]="formState.get('destinationCountry')?.errors"
            />

            <div class="form-card__note_and_icon">
                <span>
                    <svg width="32"
                         height="32"
                         xmlns="http://www.w3.org/2000/svg">
                      <g fill="none"
                         fill-rule="evenodd">
                        <path d="M0 0h32v32H0z" /><g transform="translate(7 7)">
                        <circle stroke="#1D1C2D" stroke-width="1.5" cx="9" cy="9" r="9" />
                        <path
                                d="M9.188 5.344c.312 0 .558-.084.738-.252a.847.847 0 0 0 .27-.648c0-.288-.088-.512-.264-.672-.176-.16-.424-.24-.744-.24s-.57.084-.75.252a.86.86 0 0 0-.27.66c0 .28.09.5.27.66.18.16.43.24.75.24ZM10.016 13V6.664H8.372V13h1.644Z"
                                fill="#1D1C2D" fill-rule="nonzero" /></g></g></svg>
                </span>
                <p class="form-card__note mt-0">
                    {{ 'complete.destinationCountryWarning' | translate }}
                </p>
            </div>

        </div>

        <div class="terms-and-conditions mb-5 mt-5">
            <p>{{ 'complete.checkOrderDetails' | translate }}</p>
            <p>{{ 'complete.byAccepting' | translate }}
            <ul>
                <li>
                    <p>
                        {{ 'complete.the' | translate }}
                        <a class="link"
                           target="_blank"
                           [href]="rentalPartnerTermsUrl"
                        >
                            {{ 'complete.conditions' | translate }}
                        </a>
                        {{ 'complete.rentalPartner' | translate }}
                    </p>
                </li>

                <li>
                    <p>
                        {{ 'complete.the' | translate }}
                        <a class="link"
                           href="javascript:void(0)"
                           (click)="showTermsAndConditions()"
                        >
                            {{ 'complete.productRules' | translate }}
                        </a>
                        {{ 'complete.CorrespondingRental' | translate }}
                    </p>
                </li>

                <li>
                    <p>
                        {{ 'complete.the' | translate }}
                        <a class="link"
                           target="_blank"
                           [href]="tripManagerTermsUrl"
                        >
                            {{ 'complete.conditions' | translate }}
                        </a>
                        {{ 'complete.tripmanager' | translate }}
                    </p>
                </li>
            </ul>
            <a>
                <label class="accept-terms">
                    <input type="checkbox" class="checkbox__icon" formControlName="acceptConditions">
                    <span>{{ 'complete.readAndAccept' | translate }}</span>
                </label>
            </a>
        </div>
        <div class="button">
            <div *ngIf="formIsSubmitting" class="isSubmitting">
                <p-progressSpinner [style]="{width: '30px', height: '30px'}">
                </p-progressSpinner>
                <p>
                    {{ 'complete.isSubmitting' | translate }}
                </p>
            </div>
            <p-button type="submit"
                      *ngIf="! formIsSubmitting"
                      [disabled]="! formState.valid"
                      [label]="'complete.orderBtn' | translate"
                      (click)="submitRental(formState.valid)"
                      styleClass="p-button-rounded p-button-primary p-button--full-width">
            </p-button>
        </div>

    </ng-container>

</div>
