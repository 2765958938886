import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { firstValueFrom, Observable } from 'rxjs'

import {
    LocationsQuery,
    LocationsQueryService,
    PartnerLocation,
    PartnerLocationsInput,
    PartnerLocationsQuery,
    PartnerLocationsQueryService,
} from '@app-graphql'

@Injectable({
    providedIn: 'root',
})
export class LocationService {

    constructor(
        private locationsQueryService: LocationsQueryService,
        private partnerLocationsQueryService: PartnerLocationsQueryService,
    ) {
    }

    public getLocations(): Observable<ApolloQueryResult<LocationsQuery>> {
        return this.locationsQueryService.fetch()
    }

    public getPartnerLocations(): Observable<ApolloQueryResult<PartnerLocationsQuery>> {
        return this.partnerLocationsQueryService.fetch()
    }

    public async getPartnerLocationsAsync(input?: PartnerLocationsInput): Promise<PartnerLocation[]> {
        const result: ApolloQueryResult<PartnerLocationsQuery> = await firstValueFrom(
            this.partnerLocationsQueryService.fetch({ input, first: 10000 }),
        )
        return result.data.partnerLocations.data as PartnerLocation[]
    }

}
